import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export const months = [
  {
    id: null,
    label: "Все",
    value: null,
  },
  ...new Array(12).fill(null).map((_, id) => ({
    id,
    label: moment().add(id, "M").format("MMMM"),
    value: moment().add(id, "M"),
  })),
];
