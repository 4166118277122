import React, { useContext } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { PasswordInput } from "../../Inputs/PasswordInput";
import { Controller, useForm } from "react-hook-form";
import { makeValidationSchema } from "./makeValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { DefaultButton } from "../../DefaultButton";

export const PasswordForm = ({
  getPasswordMessage,
  setNewPassword,
  getPasswordError,
}) => {
  const validateForm = makeValidationSchema();

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({ resolver: yupResolver(validateForm), shouldFocusError: false });

  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  const onSubmit = (data) => setNewPassword(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box flex direction={"column"}>
          <Box
            flex
            direction={isMobileWidth ? "column" : "row"}
            gap="2rem"
            justify="between"
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  error={errors.password?.message}
                  onFocus={() => clearErrors("password")}
                  name="settings-current-password"
                  id="current-password"
                  label="Введите текущий пароль"
                  placeholder="Your current password"
                  onChange={(e) => setValue("password", e.target.value)}
                  iconSrc="rect"
                  iconHeight="40px"
                  iconLeft="20px"
                  iconTop="8px"
                />
              )}
            />
            <Controller
              name="new_password"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  error={errors.new_password?.message}
                  onFocus={() => clearErrors("new_password")}
                  name="settings-new-password"
                  id="new-password"
                  label="Придумайте новый пароль"
                  placeholder="Your new password"
                  onChange={(e) => setValue("new_password", e.target.value)}
                  iconSrc="rect"
                  iconHeight="40px"
                  iconLeft="20px"
                  iconTop="8px"
                />
              )}
            />
          </Box>
          <Box flex direction={isMobileWidth ? "column" : "row"} gap="2rem">
            <Box
              flex
              align="center"
              width={{ max: "406px" }}
              margin={{ top: "2rem" }}
            >
              <DefaultButton
                style={{
                  padding: "10px",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "8px",
                  height: "56px",
                }}
                type="submit"
              >
                Изменить пароль
              </DefaultButton>
            </Box>

            {getPasswordMessage ? (
              <Box pad={{ top: "2rem" }}>
                <Text size="small" color="grey">
                  Пароль успешно изменен
                </Text>
              </Box>
            ) : null}

            {getPasswordError ? (
              <Box pad={{ top: "2rem" }}>
                <Text size="small" color="red">
                  {getPasswordError}
                </Text>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </form>
  );
};
