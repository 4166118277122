import styled from "styled-components";
import InputMask from "react-input-mask";
import { IconSvg } from "../../IconSvg";

export const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 56px;
  border-radius: 5px;
  padding: 14px 10px 14px 41px;
  background-color: transparent;
  border: 1px solid rgba(50, 50, 50, 1);
  line-height: 22px;
  font-size: 16px;
  font-style: italic;
  color: black;
  opacity: ${(props) => props.disabled && "0.3"};
  border-color: ${(props) => props.error && "#CA3C3C"};
`;

export const StyledFormField = styled.label`
  position: relative;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
  opacity: ${(props) => props.disabled && "0.5"};
  color: ${(props) => props.error && "#CA3C3C"};
`;

export const StyledIconSvg = styled(IconSvg)`
  position: absolute;
  left: ${(props) => props.iconLeft && props.iconLeft};
  top: ${(props) => props.iconTop && props.iconTop};
  color: ${(props) => props.error && "#CA3C3C"};
  opacity: ${(props) => props.disabled && "0.5"};
`;

export const StyledBox = styled.div`
  position: relative;
`;
