import { Link } from "react-router-dom";
import { DefaultButton } from "../../components/DefaultButton";
import { routes } from "../../const/routes";
import styles from "./Message.module.scss";

export const Message = ({ message }) => {
  return (
    <div className={styles.messagePage}>
      <p className={styles.message}>{message}</p>
      <DefaultButton
        style={{
          padding: "10px",
          width: "100%",
          textAlign: "center",
          borderRadius: "8px",
          height: "44px",
          marginTop: "20px",
        }}
        as={Link}
        to={routes.main.path}
        className={styles.btn}
      >
        На главную
      </DefaultButton>
    </div>
  );
};
