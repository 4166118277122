import { Article } from "..";
import { observer } from "mobx-react";
import { useDataStore } from "../../../hooks/useDataStore";
import { useEffect } from "react";
import { Paragraph } from "grommet";
import { useParams } from "react-router-dom";
import renderHTML from "react-render-html";

export const Pages = observer(() => {
  const { id } = useParams();

  const { loadPageContent, pageContent } = useDataStore();

  useEffect(() => {
    loadPageContent(id);
  }, []);

  return (
    <Article
      title={pageContent?.headline}
      desc={""}
      top="50px"
      coverBack={
        pageContent?.banners?.main ||
        pageContent?.banners?.preview ||
        pageContent?.banners?.small
      }
      minMobilHeight="180px"
      height="500px"
      maxHeight="740px"
    >
      {pageContent?.text && (
        <Paragraph
          fill
          style={{
            lineHeight: "1.5",
            whiteSpace: "pre-line",
          }}
        >
          {renderHTML(pageContent?.text)}
        </Paragraph>
      )}
    </Article>
  );
});
