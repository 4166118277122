import { Anchor, Box, Heading, Paragraph, Text } from "grommet";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as PSB } from "../../images/partnerLogos/psb_logo.svg";
import styles from "../Partners/partners.module.scss";
import { ReklamaPopup } from "../Popups/ReklamaPopup";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export function DescriptionTab() {
  const [isClickText, setIsClickText] = useState(false);

  const handleClickText = (e) => {
    e.preventDefault();
    setIsClickText(true);
  };

  return (
    <>
      <Box gap="medium">
        <Box>
          <Box flex direction="row" justify="between">
            <Heading
              level={"3"}
              margin={{ top: "18px", bottom: "18px" }}
              style={{ textTransform: "uppercase" }}
            >
              Описание проекта
            </Heading>
          </Box>
          <CustomParagraph>
            Детский триатлон — это комплексный и разнообразный подход к занятию
            спортом у детей. Сочетание бега, плавания и езды на велосипеде даст
            старт качественной и полезной проработке выносливости, скорости и
            упорства с юного возраста. Занятия триатлоном также развивают
            координацию, силу мышц и дисциплину.
          </CustomParagraph>
          <CustomParagraph>
            <Text style={{ fontWeight: "bolder" }}>
              Польза от занятий триатлоном:
            </Text>
          </CustomParagraph>
          <P>• Гармоничное развитие тела, силы мышц </P>
          <P>• Умение грамотно рассчитывать свои силы</P>{" "}
          <P>• Повышение общей выносливости организма</P>{" "}
          <P>• Развитие концентрации и внимания</P>{" "}
          <P>• Хорошая физическая форма и настроение</P>
          <CustomParagraph>
            Подготовка к триатлону может начинаться уже с 7-8 лет как у
            мальчиков, так и у девочек. С этого возраста дети не только
            тренируются, но и начинают готовиться к первым стартам и участвовать
            в соревнованиях. Отличаются такие старты от взрослых лишь длиной
            дистанции, которую предстоит преодолеть. Детские старты обычно
            включают в себя плавание — около 100 метров, велосипед — 3-4
            километра, бег — около 1 километра.
          </CustomParagraph>
          <CustomParagraph>
            Обучение детей триатлону, как правило, включает в себя: правильную
            технику бега, плавание в закрытых или открытых бассейнах, езду на
            велосипеде и лыжный спорт в зимнее время. На начальных этапах
            занятия, в основном, проходят в игровой форме. При этом, основной
            акцент идет на правильную технику выполнения элементов и развитие
            чувства плавания. Специалисты признают, что учиться держаться на
            воде легче всего именно в детском возрасте. Плавание формирует
            осанку, дисциплинирует и учит детей быть внимательными, слушая
            команды тренера.
          </CustomParagraph>
          <CustomParagraph>
            Первые соревнования IronKids для детей от 6-15 лет были учреждены
            Международной организацией триатлона в 1985 году. Минимальная
            дистанция тогда включала в себя 45,7 м плавание, 3,2 км велосипедная
            гонка и 0,5 км бег. Впоследствии популярность детских стартов
            ежегодно возрастала. К 2016-м году в серии IronKids Mont-Tremblant
            Fun Run приняли участие порядка 15 000 человек. Теперь забеги
            IronKids проходят по всему миру в рамках основных стартов IronMan.
            На данный момент крупнейшую серию детского триатлона в мире проводит
            американская компания «Kids Triathlon, Inc». Особенность детских
            соревнований — специально проложенная безопасная трасса вдали от
            автомобильных дорог. Такие забеги проходят в парках и скверах или же
            на улицах, где движение строго ограниченно.
          </CustomParagraph>
          <CustomParagraph>
            В России занимаются триатлоном более 3000 детей-любителей. В разных
            регионах открыты более 50 школ, обучающих этому виду спорта детей от
            7 до 13 лет. Каждый год для будущих чемпионов проводится около 100
            стартов по всей стране. Лидирующую позицию по инфраструктуре и
            условиям для тренировок занимает Казань. Также в топе городов Нижний
            Новгород, Ярославль, Саратов, Курск.
          </CustomParagraph>
          <CustomParagraph>
            Детские любительские забеги в России проводят организаторы
            соревнований по триатлону «A1 Triathlon», «Лига Триатлона», «Titan»,
            «IronStar» в рамках ежегодных взрослых стартов.
          </CustomParagraph>
          <CustomParagraph>
            В 2016 году в Екатеринбурге Федерация триатлона Урала провела первый
            в мире любительский зимний триатлон для детей. В нем приняли участие
            юные спортсмены от 3 до 7 лет. Они пробежали по снегу 100 метров,
            проехали 300 метров на беговеле и прошли 100 метров на лыжах.
          </CustomParagraph>
          <CustomParagraph>
            Миссия Федерации Триатлона России — привить детям любовь к активному
            образу жизни, создать качественную базу для будущих спортивных
            достижений. В планах Федерации на ближайшие годы — оказать
            комплексную поддержку развитию детского триатлона в стране,
            способствовать открытию новых школ, привлекать квалифицированных
            специалистов, проводить детские любительские соревнования на
            федеральном и региональном уровнях.
          </CustomParagraph>
        </Box>

        <Box dir="column" gap="small" width={{ max: "180px" }}>
          <div style={{ position: "relative" }}>
            <Anchor
              href="https://www.psbank.ru/"
              target={"_blank"}
              rel={"noopener noreferrer"}
              color={"black"}
              icon={
                <Box height="40px" align="center">
                  <PSB />
                </Box>
              }
            />
            {!isClickText && (
              <span
                onClick={(e) => handleClickText(e)}
                className={styles.textMain}
                style={{ top: "10px", right: "24px" }}
              >
                Реклама
              </span>
            )}
          </div>

          <Box alignSelf="center">
            <Text textAlign="center" size="small" weight="bold" color="black">
              Генеральный партнер проекта "Лига&nbsp;Триатлона. Дети"
            </Text>
          </Box>
        </Box>
      </Box>

      {isClickText && (
        <ReklamaPopup
          closeHandler={() => setIsClickText(false)}
          item={{
            reklama: {
              name: "ПАО Промсвязьбанк",
              inn: 7704123065,
              gov_id: "Kra23cM3T",
            },
          }}
          styles={styles}
        />
      )}
    </>
  );
}
