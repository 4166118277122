import { Anchor } from "grommet";
import styles from "./ResultCard.module.scss";

const ResultCard = ({ region, index }) => {
  return (
    <article className={`${styles.Card} ${styles.ResultCard}`}>
      <div className={styles.column}>
        <div>
          <h6 className={styles.title}>№</h6>
          <p className={styles.text}>
            <span>{index + 1}</span>
          </p>
        </div>

        <div>
          <h6 className={styles.title}>Субъект РФ</h6>
          <p className={styles.text}>{region.name}</p>
        </div>

        <div>
          <h6 className={styles.title}>ФИО руководителя</h6>
          <p className={styles.text}>{region.person}</p>
        </div>

        <div className={styles.textWrap}>
          <h6 className={styles.title}>
            Контактные данные
            <br />
            (e-mail, телефон)
          </h6>
          <p className={styles.text}>
            {" "}
            <p style={{ paddingBottom: "0.675rem" }}>
              <Anchor
                href={`tel:${region.phone}`}
                style={{ fontWeight: "normal" }}
                color={"#313131"}
              >
                {region.phone}
              </Anchor>
            </p>
            <p>
              <Anchor
                href={`mailto:${region.email}`}
                style={{ fontWeight: "normal" }}
                color={"#313131"}
              >
                {region.email}
              </Anchor>
            </p>
          </p>
        </div>

        <div className={styles.textWrap}>
          <h6 className={styles.title}>Интернет-сайт, социальные сети</h6>
          <p className={styles.text}>
            <Anchor
              href={region.link}
              label={region.link}
              target={"_blank"}
              rel={"noopener noreferrer"}
            />
          </p>
        </div>
      </div>
    </article>
  );
};

export default ResultCard;
