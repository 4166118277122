import styled from "styled-components";
import { Box, Button } from "grommet";
import iconClose from "../../../images/svg/icon-close.svg";

const PopupTop = styled(Box)`
  padding-top: 23px;
  padding-right: 22px;
  padding-bottom: 17px;
  padding-left: 18px;

  font-size: 20px;
  color: #ffffff;
  font-weight: 700;

  background-color: #db4c4c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const PopupBody = styled(Box)`
  padding-top: 23px;
  padding-right: 22px;
  padding-bottom: 17px;
  padding-left: 18px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-top-right-radius: 12px;
  background-color: #db4c4c;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -51%);
    display: block;
    width: 26px;
    height: 26px;
    background-image: url("${iconClose}");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }
`;

const DescWrap = styled(Box)`
  margin-bottom: 16px;
  padding-top: 62px;
  padding-right: 49px;
  padding-bottom: 90px;
  padding-left: 36px;

  background-color: #eff2f4;
  border-radius: 5px;
`;

const PopupFooter = styled(Box)`
  padding-top: 24px;
  padding-right: 22px;
  padding-bottom: 24px;
  padding-left: 18px;
`;

const CustomButton = styled(Button)`
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

export {
  PopupTop,
  CloseButton,
  PopupBody,
  DescWrap,
  PopupFooter,
  CustomButton,
};
