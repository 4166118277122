export const setDeliveryOptions = (arr, value = "id", name = "name_ru") => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el[value], label: el[name], id: el.id };
    });
  }
  return [];
};

// функция установки региона доставки через location
export const searchDeliveryRegion = (arr, id, option) => {
  if (arr.length) {
    switch (option) {
      case "region":
        return setDeliveryOptions(arr).find(
          (el) => Number(el.id) === Number(id)
        );
      case "city":
        return setDeliveryOptions(arr).find((el) => el.id === id);
      default:
        break;
    }
  }
};
