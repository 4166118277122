import styled from "styled-components";

export const Svg = styled("svg")`
  flex-shrink: 0;
  vertical-align: middle;
  fill: currentColor;
  width: ${(props) => props.width || "25px"};
  height: ${(props) => props.height || "25px"};
  color: ${(props) => props.color || "inherit"};
  opacity: ${(props) => props.opacity || "inherit"};
`;
