export const useSubmit = ({ sendPartnersData, setIsChecked }) => {
  return {
    onSubmit: async (e) => {
      e.preventDefault();
      const name = e.target.name.value;
      const email = e.target.email.value;
      const phone = e.target.phone.value;
      const text = e.target.text.value;
      const support_type = "partners";

      sendPartnersData({ name, email, phone, text, support_type });

      e.target.name.value = "";
      e.target.email.value = "";
      e.target.phone.value = "";
      e.target.text.value = "";
      setIsChecked(false);
    },
  };
};
