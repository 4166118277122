import { Box, Card, Grid, Heading, Image, Text } from "grommet";
import { useState } from "react";
import { AthletesMorePopup } from "../Popups/AthletesMore/AthletesMorePopup";
import { checkAndSetImgUrl } from "../../utils/utils";
import { DefaultButton } from "../DefaultButton";
import { observer } from "mobx-react";
import { toJS } from "mobx";

export const AthleteCards = observer(({ athletesArr }) => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [personItem, setPersonItem] = useState(null);

  const handleClickBtnMore = (person) => {
    setPersonItem(person);
    setOpenPopup(true);
  };

  return (
    <Grid
      margin={{ bottom: "96px" }}
      columns={{ count: "fit", size: "280px" }}
      justify={"center"}
      gap={"medium"}
    >
      {athletesArr.map((item) => (
        <Card
          width={{ max: "330px" }}
          background={"#F8F8F8"}
          elevation={"none"}
          key={item.public_id}
          style={{ width: "100%", height: "auto" }}
        >
          <Box height={{ min: "310px", max: "320px" }}>
            {item?.picture && (
              <Image
                src={`${process.env.REACT_APP_API}${item.picture}`}
                onError={checkAndSetImgUrl}
                fit={"cover"}
              />
            )}
          </Box>
          <Box
            height={"100%"}
            direction={"column"}
            justify={"between"}
            pad={{ top: "32px", right: "21px", bottom: "40px", left: "24px" }}
          >
            <Text
              margin={{ bottom: "58px" }}
              style={{
                textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              Спортивное звание: <Text weight={"bold"}>{item.title}</Text>
            </Text>
            <Box>
              <Heading
                level={3}
                margin={{ bottom: "32px" }}
                style={{ textTransform: "uppercase", lineHeight: "1.2" }}
              >
                {item.name}
              </Heading>
              <DefaultButton
                label={"Подробнее"}
                onClick={() => {
                  handleClickBtnMore(item);
                }}
                primary
              />
            </Box>
          </Box>
        </Card>
      ))}

      {isOpenPopup && (
        <AthletesMorePopup
          statePopupHandler={setOpenPopup}
          personItem={personItem}
        />
      )}
    </Grid>
  );
});
