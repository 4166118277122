import { Box, Heading, ResponsiveContext, Text } from "grommet";
import React, { useContext } from "react";
import styled from "styled-components";
import { times } from "../../../../utils/date";
import {
  capitalizeFirstLetter,
  isMobileTabletResponsive,
} from "../../../../utils/utils";

const StyledText = styled(Text)`
  font-family: "Gotham Pro";
  font-size: 14px;
  line-height: 22px;

  color: #000000;
`;
const StyledImg = styled.img`
  width: 100%;
`;
const StyledFigure = styled.figure`
  width: 250px;
  height: 200px;
  margin: 0;
`;

export const LicenseInfoCard = ({ license }) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  return (
    <Box margin={{ bottom: "3rem" }}>
      <Box flex direction={isMobileWidth ? "column" : "row"} gap="4rem">
        <StyledFigure>
          <StyledImg src={license.info?.picture} alt="фото" width="200px" />
        </StyledFigure>
        <Box flex direction="column">
          <Heading level={5} margin={{ bottom: "1rem" }} color="black">
            {license.info?.insurance?.name}
          </Heading>

          <Heading level={6} margin={{ bottom: "0.5rem" }} color="black">
            Номер:
          </Heading>

          <Heading level={3} margin={{ bottom: "1rem" }} color="black">
            {license.info?.number}
          </Heading>

          <StyledText>
            Начало действия: {times.getFullDate(license.created)}
          </StyledText>
          <StyledText>
            Окончание действия: {times.getFullDate(license.info?.validity_date)}
          </StyledText>
          <StyledText>
            Дата покупки: {times.getFullDate(license.created)}
          </StyledText>

          <Box margin={{ top: "1rem" }}>
            <StyledText>
              {capitalizeFirstLetter(license.info?.last_name)}{" "}
              {capitalizeFirstLetter(license.info?.first_name)}{" "}
              {license.info?.second_name
                ? capitalizeFirstLetter(license.info.second_name)
                : ""}
            </StyledText>
            <StyledText>
              {capitalizeFirstLetter(license.info?.last_name_en)}{" "}
              {capitalizeFirstLetter(license.info?.first_name_en)}
            </StyledText>
            <StyledText>
              Дата рождения: {times.getFullDate(license.info?.birth_date)}
            </StyledText>
            <StyledText>Город проживания: {license.info?.city}</StyledText>
            <StyledText>Телефон: +{license.info?.phone}</StyledText>
            <StyledText>Email: {license.info?.email}</StyledText>
          </Box>

          <Heading level={6} margin={{ top: "1rem" }} color="black">
            Паспорт
          </Heading>
          <StyledText>
            Серия и номер: {license.info?.passport_serial}{" "}
            {license.info?.passport_number}
          </StyledText>
          <StyledText>Кем выдан: {license.info?.passport_source}</StyledText>
          <StyledText>
            Дата выдачи: {times.getFullDate(license.info?.passport_date)}
          </StyledText>
          <StyledText>
            Место рождения: {license.info?.passport_birth_place}
          </StyledText>
          <StyledText>
            Код подразделения: {license.info?.passport_code}
          </StyledText>
          <StyledText>
            Адрес по прописке: {license.info?.passport_address}
          </StyledText>

          <Heading level={6} margin={{ top: "1rem" }} color="black">
            Экстренная связь:
          </Heading>
          <StyledText>Имя: {license.info?.relative_name}</StyledText>
          <StyledText>Телефон: +{license.info?.relative_phone}</StyledText>
        </Box>
      </Box>
    </Box>
  );
};
