import { makeAutoObservable, observable } from "mobx";
import { returnAuthConfig } from "../utils/utils";
import { backendLogger } from "../utils/logger";

export class TicketsStore {
  ticketsData = [];
  transferTicketData = [];
  pastTicketData = null;
  regMessageData = "";
  isCancelReg = {
    popup: false,
    message: ``,
  };
  isCancelBuy = {
    popup: false,
    isTicketReturned: false,
    message: ``,
    sum: "",
  };
  transferTicketMessageData = "";
  returnTransferTicketMessageData = "";

  cancelRegPopup = (isCancel) => {
    this.isCancelReg = { ...this.isCancelReg, popup: isCancel };
  };
  cancelBuyPopup = (isCancel) => {
    this.isCancelBuy = { ...this.isCancelBuy, popup: isCancel };
  };
  cancelRegMessage = (message) => {
    this.isCancelReg = { ...this.isCancelReg, message };
  };
  cancelBuyTicketMessage = (message) => {
    this.isCancelBuy = { ...this.isCancelBuy, message };
  };

  setTickets = (tickets) => {
    this.ticketsData = tickets;
  };

  setPastTicket = (tickets) => {
    this.ticketsData = [...this.ticketsData, ...tickets];
    this.pastTicketData = tickets;
  };

  setTransferTicketMessage = (message) => {
    this.transferTicketMessageData = message;
  };

  setReturnTransferMessage = (message) => {
    this.returnTransferTicketMessageData = message;
  };

  registrationMessage = (regMessage) => {
    this.regMessageData = regMessage;
  };

  setTransferTicket = (tickets) => {
    this.ticketsData = [...this.ticketsData, ...tickets];
    this.transferTicketData = tickets;
  };
  setCancelBuyTicket = (payload) => {
    this.isCancelBuy = { ...this.isCancelBuy, isTicketReturned: payload };
  };

  getTicketById = (id) => {
    return this.ticketsData.find((t) => t.public_id === id);
  };

  loadTickets = async () => {
    const config = returnAuthConfig();
    const response = await this.api.get("/api/user/tickets/my", config);

    if (response.status === 200) {
      this.setTickets(response.data.values);
    }
  };

  getSum = (sum) => {
    this.isCancelBuy = { ...this.isCancelBuy, sum };
  };

  cancelRegistrationTicket = async (info) => {
    const config = returnAuthConfig();
    const response = await this.api.put(
      "/api/user/ticket/disable",
      info,
      config
    );

    if (response.status === 200) {
      this.cancelRegPopup(false);
      this.cancelRegMessage(response.data.message);
    } else if (response.status >= 400) {
      this.cancelRegMessage(response.data.message);
    }
  };

  cancelBuyTicket = async (ticket) => {
    const config = returnAuthConfig();
    const response = await this.api.post("/api/payment/cancel", ticket, {
      timeout: 30000,
      ...config,
    });

    if (response.status === 200) {
      this.getSum(response.data.sum);
      this.cancelBuyPopup(false);
      this.setCancelBuyTicket(true);
    } else if (response.status >= 400)
      this.cancelBuyTicketMessage(response.data.message);
  };

  registrationTicket = async (ticket) => {
    const config = returnAuthConfig();
    const response = await this.api.put("/api/user/ticket", ticket, config);
    if (response.status === 200) {
      this.registrationMessage(response.data.message);
    }
    if (response.status >= 400) {
      this.registrationMessage(response.data.message);
    }
  };

  sendTicket = async (data) => {
    const config = returnAuthConfig();

    const response = await this.api.put(
      "/api/user/ticket/transfer",
      data,
      config
    );

    if (response.status === 200) {
      this.setTransferTicketMessage(response.data.message);
    } else if (response.status >= 400) {
      this.setTransferTicketMessage(response.data.message);
    }
  };

  getTransferTicket = async () => {
    const config = returnAuthConfig();
    const response = await this.api.get("/api/user/tickets/transfered", config);

    if (response.status === 200) {
      this.setTransferTicket(response.data.values);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  getPastTicket = async () => {
    const config = returnAuthConfig();
    const response = await this.api.get("/api/user/tickets/old", config);

    if (response.status === 200) {
      this.setPastTicket(response.data.values);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  returnTransferTicket = async (public_id) => {
    const config = returnAuthConfig();

    const response = await this.api.put(
      "/api/user/ticket/return",
      public_id,
      config
    );

    if (response.status === 200) {
      this.setReturnTransferMessage(response.data.message);
    } else if (response.status >= 400) {
      this.setReturnTransferMessage(response.data.message);
    }
  };

  get tickets() {
    return this.ticketsData;
  }

  get isCancelRegPopup() {
    return this.isCancelReg.popup;
  }

  get transferTicketMessage() {
    return this.transferTicketMessageData;
  }

  get transferTicket() {
    return this.transferTicketData;
  }
  get pastTicket() {
    return this.pastTicketData;
  }

  get isCancelRegMessage() {
    return this.isCancelReg.message;
  }
  get isCancelBuyPopup() {
    return this.isCancelBuy.popup;
  }
  get isCancelBuyMessage() {
    return this.isCancelBuy.message;
  }
  get returnTransferTicketMessage() {
    return this.returnTransferTicketMessageData;
  }

  get regMessage() {
    return this.regMessageData;
  }

  get isTicketCanceled() {
    return this.isCancelBuy.isTicketReturned;
  }
  get refundSum() {
    return this.isCancelBuy.sum;
  }
  constructor(api) {
    this.api = api;
    makeAutoObservable(this, { api: observable.ref });
  }
}
