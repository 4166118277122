import { makeAutoObservable, observable } from "mobx";
import { backendLogger } from "../utils/logger";

export class SubscribeStore {
  statusData = false;

  setNewStatus = (status) => {
    this.statusData = status;
  };

  setNewSubscribe = async (email) => {
    const response = await this.api.put("/api/user/subscribe/news", { email });

    if (response.status === 200) {
      this.setNewStatus(response.data.message);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  get status() {
    return this.statusData;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref });
  }
}
