import {
  Box,
  List,
  Paragraph,
  ResponsiveContext,
  Tab,
  Tabs,
  Text,
} from "grommet";
import { DocumentItem } from "../DocumentItem";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useDataStore } from "../../hooks/useDataStore";

const yearTabs = [
  {
    label: 2024,
  },
  {
    label: 2023,
  },
  {
    label: 2022,
  },
  {
    label: 2021,
  },
  {
    label: 2020,
  },
  {
    label: 2019,
  },
  // {
  //   label: 2018,
  // },
  // {
  //   label: 2017,
  // },
  // {
  //   label: 2016,
  // },
  // {
  //   label: 2015,
  // },
];

export const Protocols = observer(() => {
  const [activeTab, setActiveTab] = useState(0);
  const { protocols, getAllProtocols } = useDataStore();
  useEffect(() => {
    getAllProtocols();
    // eslint-disable-next-line
  }, []);

  const activeYear = yearTabs[activeTab].label;

  const activeProtocols = protocols?.length
    ? protocols?.filter(
        (p) => new Date(p.document_date).getFullYear() === activeYear
      )
    : [];

  return (
    <div>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box>
            <Box
              style={{
                width: "max-content",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Paragraph>Архивы протоколов:</Paragraph>
            </Box>
            <br />
            <Box
              direction={"row"}
              style={{
                width: "max-content",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Tabs onActive={setActiveTab}>
                {yearTabs.map((year) => (
                  <Tab
                    title={<Text size="large">{year.label}</Text>}
                    key={year.label}
                  ></Tab>
                ))}
              </Tabs>
            </Box>
            <br />
            <List
              data={activeProtocols}
              border={false}
              margin={{ top: "20px" }}
            >
              {(protocol) => (
                <DocumentItem
                  title={protocol.title}
                  date={protocol.document_date}
                  link={protocol.link}
                />
              )}
            </List>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
});
