import { CheckBox, Text } from "grommet";
import React from "react";
import { Field } from "react-final-form";

const validateRequired = (value) => (value ? undefined : "Обязательное");
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export function Checkbox({ name, label, required, validate }) {
  const validators = [];
  if (required) {
    validators.push(validateRequired);
  }
  if (validate) {
    validators.push(validate);
  }
  return (
    <Field
      name={name}
      required={required}
      type={"checkbox"}
      validate={composeValidators(...validators)}
      render={({ input, meta }) => {
        const error = meta.touched && meta.error;
        return (
          <div>
            <CheckBox name="accept_terms" label={label} {...input} />
            {error && (
              <Text size="10px" color="#DB4C4C">
                {error}
              </Text>
            )}
          </div>
        );
      }}
    />
  );
}
