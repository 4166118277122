import { NameSpace } from "../const/namespace";

export const columns = {
  [NameSpace.mobile]: ["auto"],
  [NameSpace.tablet]: ["1fr"],
  [NameSpace.desktop1200]: ["auto", "auto"],
  [NameSpace.desktop1500]: ["auto", "auto"],
  [NameSpace.desktop1900]: ["auto", "auto", "auto"],
};

export const adaptiveColumns = {
  [NameSpace.mobile]: ["1fr"],
  [NameSpace.tablet]: ["1fr"],
  [NameSpace.desktop1200]: ["1fr", "1fr"],
  [NameSpace.desktop1500]: ["auto", "auto", "auto"],
  [NameSpace.desktop1900]: ["auto", "auto", "auto", "auto"],
};

export const areasSize = (size) => {
  return {
    small: { name: size, start: [0, 0], end: [1, 1] },
  };
};
