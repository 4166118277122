import { observer } from "mobx-react";
import React from "react";
import { BasketComponent } from "../../components/Basket";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Container } from "../../components/Container";
import { useBasketStore } from "../../hooks/useBasketStore";
import { usePaymentStore } from "../../hooks/usePaymentStore";

export const Basket = observer(() => {
  const {
    goods,
    removeShop,
    updateGoods,
    ticketFormatsInBasket,
    checkTicketsBeforePaymentByFormatId,
  } = useBasketStore();
  const {
    promoCodeError,
    setPromoCodeError,
    sendPromoCode,
    promoCodes,
    removePromoCode,
    payment,
    errorPayment,
    setError,
    requestPayment,
  } = usePaymentStore();
  return (
    <Container>
      <Breadcrumbs />
      <BasketComponent
        payment={payment}
        errorPayment={errorPayment}
        checkTicketsBeforePaymentByFormatId={
          checkTicketsBeforePaymentByFormatId
        }
        requestPayment={requestPayment}
        ticketFormatsInBasket={ticketFormatsInBasket}
        promoCodeError={promoCodeError}
        sendPromoCode={sendPromoCode}
        goods={goods}
        updateGoods={updateGoods}
        removeShop={removeShop}
        setPromoCodeError={setPromoCodeError}
        promoCodes={promoCodes}
        removePromoCode={removePromoCode}
        setError={setError}
      />
    </Container>
  );
});
