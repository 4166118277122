import React, { useEffect } from "react";
import { Anchor, Box, Card, Heading, Image, Text } from "grommet";
import styled from "styled-components";
import { Slider } from "../Slider";
import { Navigation } from "../Slider/Navigation";
import { returnSliderParams } from "../Slider/params";
import { Container } from "../Container";
import { observer } from "mobx-react";
import { useMembersStore } from "../../hooks/useMembersStore";
import { checkAndSetImgUrl } from "../../utils/utils";
import { DefaultButton } from "../DefaultButton";
import imagePlaceholder from "../../images/no-image.jpg";

const BoxAbsolute = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
`;

const styles = {
  container: "container-leaders",
  wrapperClass: "wrapper-leaders",
  slideClass: "slide-leaders",
  nav: "nav-leaders",
  btnNext: "next-btn-leaders",
  btnPrev: "prev-btn-leaders",
};

export const Leaders = observer(() => {
  const { members, loadMembers } = useMembersStore();

  useEffect(() => {
    members?.length === 0 &&
      loadMembers("a56dfb11c0cf42c1018f3b55738e85977cdba287");
    // eslint-disable-next-line
  }, []);

  return (
    <Container overflow={true}>
      <br />
      <Box fill margin={{ bottom: "72px" }} align={"start"}>
        <Heading
          margin={{ bottom: "72px" }}
          color={"dark-1"}
          level={2}
          size={"medium"}
          style={{ textTransform: "uppercase" }}
        >
          Лидеры сборной
        </Heading>
        <Slider styles={styles} params={returnSliderParams(styles)}>
          <Box
            fill
            className={styles.container}
            align={"start"}
            style={{ position: "relative" }}
          >
            <Box
              fill
              as={"ul"}
              direction={"row"}
              className={styles.wrapperClass}
            >
              {members.map((el) => {
                return (
                  <Card
                    as={"li"}
                    className={styles.slideClass}
                    elevation={"none"}
                    background={"light-1"}
                    basis={"22rem"}
                    flex={{ shrink: 0 }}
                    key={el.public_id}
                    pad={{ horizontal: "32px", vertical: "medium" }}
                    height={"152px"}
                    direction={"row"}
                    style={{ position: "relative" }}
                  >
                    <Box
                      width={"60%"}
                      margin={{ right: "6px" }}
                      flex={{ shrink: 0 }}
                    >
                      <Heading
                        level={5}
                        margin={{ right: "6px" }}
                        color={"dark-1"}
                      >
                        {el.name}
                      </Heading>
                      <Text
                        size={"10px"}
                        color={"rgba(0,0,0,0.6)"}
                        margin={{ top: "6px", right: "6px" }}
                      >
                        {el.title.toUpperCase()}
                      </Text>
                      <Anchor
                        size={"xsmall"}
                        color={"dark-1"}
                        href="/members/group/a56dfb11c0cf42c1018f3b55738e85977cdba287"
                        margin={{ top: "auto" }}
                      >
                        Вся сборная
                      </Anchor>
                    </Box>
                    <BoxAbsolute margin={{ left: "6px" }} fill>
                      <Image
                        fit={"cover"}
                        fill
                        src={
                          el.picture
                            ? `${process.env.REACT_APP_API}${el.picture}`
                            : imagePlaceholder
                        }
                        onError={checkAndSetImgUrl}
                        width="200px"
                      />
                    </BoxAbsolute>
                  </Card>
                );
              })}
            </Box>
            <Navigation styles={styles} />
          </Box>
        </Slider>
        <DefaultButton
          href="/members/group/a56dfb11c0cf42c1018f3b55738e85977cdba287"
          style={{ padding: "5px 10px" }}
          margin={{ top: "32px" }}
        >
          Смотреть всех участников
        </DefaultButton>
      </Box>
    </Container>
  );
});
