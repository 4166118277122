import { Box, Image } from "grommet";
import headerLogo from "../../../images/triathlon_logo.svg";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const LinkLogo = styled(NavLink)`
  width: max-content;
  color: #333333;
  text-decoration: none;
`;

export const MainLogo = () => {
  return (
    <LinkLogo to={"/"}>
      <Box>
        <Image src={headerLogo} height="26" alt="Логотип триатлона" />
      </Box>
    </LinkLogo>
  );
};
