import styled from "styled-components";
import coverImg from "../../images/webp/cover4.webp";
import { Box, Heading, Paragraph, ResponsiveContext, Text } from "grommet";
import { Container } from "../../components/Container";
import { useContext, useEffect } from "react";
import { DocumentItem } from "../../components/DocumentItem";
import { routes } from "../../const/routes";
import { Link, useParams } from "react-router-dom";
import { documentsLinks, Navigation } from "../Documents/components/Navigation";
import { Partners } from "../../components/Partners";
import { Banner } from "../../components/Banner";
import { observer } from "mobx-react";
import { useRegionsStore } from "../../hooks/useRegionsStore";
import { times } from "../../utils/date";

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
`;

const renderTitle = (type) => {
  return documentsLinks.filter((el) => el.link === type)[0].title;
};

export const DocumentsPage = observer(() => {
  const size = useContext(ResponsiveContext);
  const { type } = useParams();
  const { documents, loadDocuments } = useRegionsStore();

  useEffect(() => {
    loadDocuments(type);
    // eslint-disable-next-line
  }, [type]);

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Box
          margin={{ bottom: "64px" }}
          pad={
            ["desktop1900", "medium"].includes(size)
              ? { top: "56px", bottom: "80px", horizontal: "80px" }
              : { top: "26px", bottom: "20px", horizontal: "20px" }
          }
          background={{ color: "#F8F8F8" }}
          round={"small"}
        >
          <Paragraph
            margin={{ bottom: "82px" }}
            color={"rgba(0, 0, 0, 0.3)"}
            style={{ maxWidth: "555px" }}
          >
            <StyledLink to={routes.main.path}>Главная</StyledLink>
            <Text> &gt; </Text>
            <StyledLink to={routes.documents.path}>Документы</StyledLink>
            <Text> &gt; </Text>
            <Text>{renderTitle(type)}</Text>
          </Paragraph>
          <Heading
            level={"1"}
            size={["desktop1900", "medium"].includes(size) ? "56px" : "28px"}
            style={{ textTransform: "uppercase", color: "#000000" }}
          >
            {renderTitle(type)}
          </Heading>
        </Box>

        <Box style={{ maxWidth: "1190px" }}>
          <div style={{ marginBottom: "50px" }}>
            <Navigation />
          </div>
          {documents.map((el) => (
            <Box
              key={el.public_id}
              flex
              direction="column"
              gap="small"
              margin={{ top: "20px" }}
            >
              <DocumentItem
                title={el.title}
                date={times.getNumberFullDate(el.created_date)}
                link={el.path}
              />
            </Box>
          ))}
        </Box>

        {/*    <Partners /> */}
      </Container>
    </>
  );
});
