import { Box, Heading, ResponsiveContext } from "grommet";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useBasketStore } from "../../hooks/useBasketStore";
import { DefaultButton } from "../../components/DefaultButton";
import { isMobileTabletResponsive } from "../../utils/utils";
import { useContext } from "react";
import { routes } from "../../const/routes";

export const Thanks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkOrder } = useBasketStore();

  const CustomBtn = styled(DefaultButton)`
    padding: 6px 24px;
  `;

  useEffect(() => {
    if (location.search.slice(1).split("=")[0] === "Order_ID") {
      const id = location.search.slice(1).split("=")[1];
      checkOrder(id, navigate);
    }
  }, [location, navigate, checkOrder]);

  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  return (
    <Box
      height={"medium"}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Heading size={isMobileWidth ? "small" : "medium"}>
        Спасибо за покупку!
      </Heading>
      <Box
        style={{
          flexDirection: isMobileWidth ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <CustomBtn
          type={"submit"}
          margin={{ top: "medium" }}
          onClick={() => navigate("/")}
        >
          На главную
        </CustomBtn>
        <CustomBtn
          type={"submit"}
          margin={{ top: "medium" }}
          onClick={() => navigate(routes.myEvents.path)}
        >
          В личный кабинет
        </CustomBtn>
      </Box>
    </Box>
  );
};
