import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
// import { CoachingStaff } from "./components/Articles/CoachingStaff/CoachingStaff";
import { HistoryTria } from "./components/Articles/HistoryTria";
import { WhereToStart } from "./components/Articles/WhereToStart";
import { Disciplines } from "./components/Articles/Disciplines";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ScrollToTop } from "./components/System/ScrollToTop";
import { privateRoutes, routes, verifyRoutes } from "./const/routes";
import { Main } from "./pages/Main";
import { NewsList } from "./pages/News/NewsList";
import { Pages } from "./components/Articles/Pages";
import { OlympicReserve } from "./pages/OlympicReserve";
import { Calendar } from "./pages/Calendar";
import { Management } from "./pages/Management";
import { Regions } from "./pages/Regions";
import { Documents } from "./pages/Documents";
import { DocumentsPage } from "./pages/DocumentsPage";
import { Contacts } from "./pages/Contacts";
import { PartnersList } from "./pages/Partners";
import { ChildrensTriathlon } from "./pages/ChildrensTriathlon";
import { AmateurTriathlon } from "./pages/AmateurTriathlon";
import { InternationalTriathlon } from "./pages/InternationalTriathlon";
import { AntiDoping } from "./pages/AntiDoping";
import { Results } from "./pages/Results";
import { License } from "./pages/License";
import { observer } from "mobx-react";
import { useUserStore } from "./hooks/useUserStore";
import { NewsContent } from "./components/NewsContent";
import { REACT_APP_API } from "./api";
import { useDataStore } from "./hooks/useDataStore";
import renderHTML from "react-render-html";
import Loader from "react-js-loader";
import { VerifyPage } from "./pages/VerifyPage";
import { NewPassPage } from "./pages/NewPassPage";
import { Members } from "./pages/Members/Members";
import { Thanks } from "./pages/Thanks";
import { Basket } from "./pages/Basket";
import { Add } from "./pages/Add";
import { Ticket } from "./pages/Ticket";
import { Events } from "./pages/Events";
import { Judgment } from "./pages/Judgment";
import { Shop } from "./pages/Shop";
import { useBasketStore } from "./hooks/useBasketStore";
import { useEffect } from "react";
import Cabinet from "./pages/Cabinet";
import { NotSupport } from "./pages/NotSupport";
import { AUTH, NO_AUTH } from "./const/user";

const App = observer(() => {
  const navigate = useNavigate();
  const { authStatus, checkAuth } = useUserStore();
  const { oneNews, loadOneNews, newsList, loadNewsPreview } = useDataStore();
  const { addShop, addGoods } = useBasketStore();
  checkAuth();

  useEffect(() => {
    if (authStatus === AUTH) {
      const userObj = localStorage.getItem("userObj")
        ? JSON.parse(localStorage.getItem("userObj"))
        : {};

      if (!userObj.public_id) {
        localStorage.removeItem("token");
        navigate(routes.signIn.path);
      }
    }
  }, [authStatus]);

  useEffect(() => {
    if (localStorage.basketGoods) {
      const goods = JSON.parse(localStorage.basketGoods);

      goods.forEach((el) => {
        if (el.selector === "shop" || "license") addShop(el);
        else addGoods(el);
      });
    }
  }, []);

  useEffect(() => {
    if (
      window.MSCompatibleInfo != null &&
      window.location.pathname !== routes.notsupport.path
    ) {
      /*  navigation(routes.notsupport.path, { replace: true }) */
      window.location.href = `${REACT_APP_API}${routes.notsupport.path}`;
    }
  }, []);

  return (
    <section>
      <ScrollToTop />
      {window.location.pathname !== routes.notsupport.path && <Header />}
      <Routes>
        {privateRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={
                authStatus === route.status ? (
                  route.element
                ) : (
                  <Navigate to={route.pathRedirect} />
                )
              }
            />
          );
        })}

        <Route path={`${routes.me.path}/:type`} element={<Cabinet />} />

        <Route path={routes.judgment.path} element={<Judgment />} />
        <Route path={`${routes.me.path}/events/:id`} element={<Ticket />} />

        <Route path={routes.main.path} element={<Main />} />
        <Route path={`${routes.notsupport.path}`} element={<NotSupport />} />
        <Route path={routes.news.path} element={<NewsList />} />
        <Route path={`${routes.add.path}/:type/:id`} element={<Add />} />
        <Route
          path={`${routes.add.path}/:type/:id/:shop_size`}
          element={<Add />}
        />
        <Route path={`${routes.myEvents.path}/:id`} element={<Ticket />} />
        <Route path={routes.myEvents.path} element={<Events />} />
        <Route path={routes.shop.path} element={<Shop />} />
        <Route
          path={`${routes.news.path}/:id`}
          element={
            <NewsContent
              api={REACT_APP_API}
              loader={<Loader type="spinner-default" bgColor="#DB4C4C" />}
              renderHTML={renderHTML}
              oneNews={oneNews}
              loadOneNews={loadOneNews}
              newsList={newsList}
              loadNewsPreview={loadNewsPreview}
              as={Link}
            />
          }
        />
        <Route path={routes.newPassword.path} element={<NewPassPage />} />
        {verifyRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={<VerifyPage variants={route.variants} />}
            />
          );
        })}
        <Route path={routes.whereToStart.path} element={<WhereToStart />} />
        <Route path={routes.historyTria.path} element={<HistoryTria />} />
        {/*<Route path={routes.coachingStaff.path} element={<CoachingStaff />} />*/}
        <Route path={routes.disciplines.path} element={<Disciplines />} />
        <Route path={`${routes.pages.path}/:id`} element={<Pages />} />
        <Route path={routes.olympicReserve.path} element={<OlympicReserve />} />
        <Route path={routes.members.path} element={<Members />} />
        <Route
          path={routes.internationalTriathlon.path}
          element={<InternationalTriathlon />}
        />
        <Route path={`${routes.calendar.path}/:type`} element={<Calendar />} />
        <Route path={routes.management.path} element={<Management />} />
        <Route path={routes.regions.path} element={<Regions />} />
        <Route path={routes.documents.path} element={<Documents />} />
        <Route
          path={`${routes.documents.path}/:type`}
          element={<DocumentsPage />}
        />
        <Route path={routes.contacts.path} element={<Contacts />} />
        <Route path={routes.partners.path} element={<PartnersList />} />
        <Route
          path={routes.amateurTriathlon.path}
          element={<AmateurTriathlon />}
        />
        <Route
          path={routes.childrensTriathlon.path}
          element={<ChildrensTriathlon />}
        />
        <Route path={routes.antidoping.path} element={<AntiDoping />} />
        <Route path={routes.results.path} element={<Results />} />
        <Route path={routes.license.path} element={<License />} />
        <Route
          path={`${routes.license.path}/:public_id`}
          element={<License />}
        />
        <Route path={routes.thanks.path} element={<Thanks />} />
        <Route path={routes.basketThanks.path} element={<Thanks />} />
        <Route path={routes.basket.path} element={<Basket />} />
      </Routes>
      <Footer />
    </section>
  );
});

export default App;
