import { makeAutoObservable, observable } from "mobx";

export class MembersStore {
  membersGroupData = [];
  membersData = [];

  setMembersGroup = (groups) => {
    this.membersGroupData = groups;
  };

  setMembers = (groups) => {
    this.membersData = groups;
  };

  loadMembersGroup = async () => {
    const response = await this.api.get("/api/members/group");

    if (response.status === 200) {
      this.setMembersGroup(response.data.values);
    }
  };

  loadMembers = async (publicId) => {
    const response = await this.api.get(`/api/members/group/${publicId}`);

    if (response.status === 200) {
      this.setMembers(response.data.values);
    }
  };

  get membersGroup() {
    return this.membersGroupData;
  }

  get members() {
    return this.membersData;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref });
  }
}
