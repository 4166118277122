import * as yup from "yup";
import { REGEX_MAIL } from "../../const/regex";

export function makeValidationSchema(isShop, isDelivery) {
  return yup.object().shape({
    email: yup
      .string()
      .required()
      .test("emailIslValid", "Введите валидный email", (value) => {
        const testValue = REGEX_MAIL.test(value);

        if (!testValue) return false;
        if (testValue) return true;
      }),
    name: isShop && isDelivery && yup.string().required(),
    lastName: isShop && isDelivery && yup.string().required(),
    phone:
      isShop &&
      yup
        .string()
        .test("isValidPhone", "Введите корректный номер телефона", (value) => {
          if (!value || value.length !== 12) return false;
          if (value && value.length === 12) return true;
        }),
  });
}
