import * as yup from "yup";
import { REGEX_MAIL } from "../../../const/regex";

const addFiveMinutes = (startDate) => {
  const date = new Date(startDate);
  date.setMinutes(date.getMinutes() + 5);
  return date;
};

const roundToDay = (date) => {
  const inputDate = new Date(date);
  const now = new Date();

  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  inputDate.setHours(hours, minutes, seconds, 0);

  return inputDate;
};

export function makeValidationSchema(
  isDeliveryZero,
  isDelivery,
  activation_delay
) {
  return yup.object().shape({
    email: yup
      .string()
      .required()
      .test("emailIslValid", "Введите валидный email", (value) => {
        const testValue = REGEX_MAIL.test(value);

        if (!testValue) return false;
        if (testValue) return true;
      }),
    phone: yup
      .string()
      .test("isValidPhone", "Введите корректный номер телефона", (value) => {
        if (!value || value.length !== 12) return false;
        if (value && value.length === 12) return true;
      }),
    relative_phone:
      isDeliveryZero &&
      yup
        .string()
        .test("isValidPhone", "Введите корректный номер телефона", (value) => {
          if (!value || value.length !== 12) return false;
          if (value && value.length === 12) return true;
        }),
    relative_name: isDeliveryZero && yup.string().required(),
    p_address: yup.string().required(),
    passport_code: yup.string().required(),
    passport_birth_place: yup.string().required(),
    passport_date: yup.string().required(),
    passport_source: yup.string().required(),
    delivery_method:
      !isDelivery &&
      yup
        .string()
        .required()
        .test("isMethod", "Заполните поле!", (value) => {
          if (value && value !== "Выберите способ доставки") return true;
          if (!value || value === "Выберите способ доставки") return false;
        }),
    passport_serial: yup.string().required(),
    passport_number: yup.string().required(),
    city: yup.string().required(),
    b_date: yup.string().required(),
    start_date: yup
      .string()
      .required()
      .test("isValidDate", "Введите корректную дату", (value) => {
        if (value) {
          const now = Date.now();
          const maxStartDate = new Date(now + 180 * 24 * 60 * 60 * 1000);
          if (
            addFiveMinutes(roundToDay(value)) <
            new Date(Date.now() + activation_delay * 1000)
          ) {
            return false;
          } else if (addFiveMinutes(roundToDay(value)) > maxStartDate) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
    fio_en: yup
      .string()
      .required()
      .test("isFile", "Заполните Фамилия Имя!", (value) => {
        if (value) {
          const nameParts = value.trim().split(/\s+/);

          if (nameParts.length === 2) return true;
        } else {
          return false;
        }
      }),
    fio_ru: yup
      .string()
      .required()
      .test(
        "isFile",
        "Заполните Фамилия Имя (Отчество если есть)!",
        (value) => {
          if (value) {
            const nameParts = value.trim().split(/\s+/);

            if (nameParts.length === 2 || nameParts.length === 3) return true;
          } else {
            return false;
          }
        }
      ),
    file: yup.mixed().test("isFile", "Выберите файл!", (value) => {
      if (!value) return false;
      else if (value && !value[0]) return false;
      return true;
    }),
  });
}
