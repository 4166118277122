import { Box, Paragraph } from "grommet";
import React, { useEffect } from "react";
import { DocumentItem } from "../DocumentItem";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useRegionsStore } from "../../hooks/useRegionsStore";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;

  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export const HowToBeRegion = observer(() => {
  const { items, regionsDocuments } = useRegionsStore();

  useEffect(() => {
    if (!items.length) regionsDocuments();
    // eslint-disable-next-line
  }, []);
  return (
    <Box gap="medium">
      <Box>
        <CustomParagraph>
          <h2>3 шага по открытию отделения по триатлону</h2>
        </CustomParagraph>
        <CustomParagraph>
          <b>Шаг №1 «Изучение нормативно-правовых актов»:</b>
        </CustomParagraph>
        <P>
          В первую очередь, важно изучить следующие ключевые нормативно-правовые
          акты:
        </P>
        <P>
          - федеральный закон «О физической культуре и спорте в Российской
          Федерации»;
        </P>
        <P>
          - федеральный стандарт спортивной подготовки по виду спорта
          «триатлон»;
        </P>
        <P>
          - методические рекомендации по организации деятельности спортивных
          школ в Российской Федерации;
        </P>
        <P>
          - требования к обеспечению подготовки спортивного резерва для
          спортивных сборных команд Российской Федерации.{" "}
        </P>
        <CustomParagraph>
          <b>Шаг №2 «Изучение спортивной инфраструктуры региона»:</b>
        </CustomParagraph>
        <P>
          Следующим этапом важно понять, какой спортивной инфраструктурой
          обладает регион и сформировать реестр спортивных школ субъекта
          наиболее перспективных для развития триатлона (наличие плавательного
          бассейна, легкоатлетического манежа, велосипедных дорожек и/или
          велотрека).
        </P>
        <CustomParagraph>
          <b>Шаг №3 «Открытие отделения по виду спорта «триатлон»»:</b>
        </CustomParagraph>
        <P>- формирование «дорожной карты» по открытию отделения;</P>
        <P>
          - взаимодействие с ОГИВ в сфере физической культуры и спорта субъекта
          по вопросу открытия отделения;
        </P>
        <P>
          - использование в работе методического пособия «Типовая программа
          спортивной подготовки по виду спорта «триатлон» (этап начальной
          подготовки).
        </P>
      </Box>
      {items.map((i) => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          margin={{ bottom: "20px" }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.link} />
        </Box>
      ))}
    </Box>
  );
});
