import { Anchor, Box, Heading, Paragraph, Text } from "grommet";
import React from "react";
import renderHTML from "react-render-html";
import styled from "styled-components";
import { useDataStore } from "../../hooks/useDataStore";
import { observer } from "mobx-react";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export const DescriptionTab = observer(() => {
  const { amateurTriathlon } = useDataStore();

  return (
    <Box gap="medium">
      <Box>
        <Box flex direction="row" justify="between">
          <Heading
            level={"3"}
            margin={{ top: "18px", bottom: "18px" }}
            style={{ textTransform: "uppercase" }}
          >
            {amateurTriathlon?.subtitle}
          </Heading>
          <Paragraph
            fill
            style={{
              lineHeight: "1.5",
              whiteSpace: "pre-line",
            }}
          >
            {amateurTriathlon?.text && renderHTML(amateurTriathlon?.text)}
          </Paragraph>
        </Box>
      </Box>
    </Box>
  );
});
