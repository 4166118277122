import React from "react";
import { Box, Button, Heading, Layer, Paragraph } from "grommet";
import { IconSvg } from "../../IconSvg";

export const AnswerPopup = ({ paragraph, heading, closePopup }) => {
  return (
    <div>
      <Layer
        onClickOutside={closePopup}
        onEsc={closePopup}
        full={true}
        background="transparent"
        animation="fadeIn"
        position="center"
      >
        <Box
          fill
          background="rgba(196, 196, 196, 1)"
          align="center"
          justify="center"
          style={{ position: "relative", visibility: "visible" }}
          overflow="auto"
        >
          <Box
            flex
            width="694px"
            background={{ color: "site-colors-1" }}
            round="20px"
            style={{ position: "absolute" }}
            pad="20px 40px 56px 40px"
          >
            <Box width="22px" alignSelf="end">
              <Button
                icon={<IconSvg src="darkClose" />}
                onClick={closePopup}
                alignSelf="center"
              ></Button>
            </Box>

            <Paragraph
              size="0.75rem"
              fill
              color="black"
              style={{ paddingBottom: "16px" }}
            >
              {paragraph}
            </Paragraph>
            <Box gap="large">
              <Heading level={4} color="black">
                {heading}
              </Heading>

              <Paragraph
                size="1rem"
                style={{
                  lineHeight: "24px",
                }}
                color="black"
              >
                {paragraph}
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Layer>
    </div>
  );
};
