import { MaskedInput } from "grommet";
import styled from "styled-components";
import { DefaultButton } from "../../DefaultButton";

export const Section = styled.section``;
export const Title = styled.h2`
  font-weight: 700;
  font-size: 42px;
  line-height: 62px;

  text-transform: uppercase;

  color: #000000;
  max-width: ${(props) => props.maxWidth && props.maxWidth};

  @media (max-width: 1315px) {
    font-size: 32px;
    line-height: 52px;
  }

  @media (max-width: 590px) {
    font-size: 32px;
    line-height: 38px;
    max-width: 320px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media (max-width: 490px) {
    max-width: 276px;
    text-align: left;
    margin-left: 8px;
  }
`;

export const BorderTop = styled.div`
  position: absolute;
  width: 4px;
  height: 160px;

  background: #ff6a12;
  border-radius: 2px;
  top: 11px;
  left: -8px;

  @media (max-width: 1315px) {
    height: 130px;
  }
  @media (max-width: 590px) {
    height: 105px;
    top: 44px;
  }
  @media (max-width: 490px) {
    height: 143px;
  }
`;
export const BorderBottom = styled.div`
  position: absolute;
  width: 4px;

  background: #ff6a12;
  border-radius: 2px;
  top: 11px;
  height: 45px;
  left: -8px;
  @media (max-width: 1315px) {
    height: 28px;
  }
  @media (max-width: 590px) {
    top: 2px;
    height: 65px;
  }
`;
export const BorderMiddle = styled.div`
  position: absolute;
  width: 4px;
  height: 100px;

  background: #ff6a12;
  border-radius: 2px;
  top: 11px;
  left: -15px;
  @media (max-width: 1315px) {
    height: 78px;
  }
  @media (max-width: 790px) {
    height: 78px;
  }
  @media (max-width: 652px) {
    height: 185px;
  }
  @media (max-width: 590px) {
    top: 2px;
  }
  @media (max-width: 490px) {
    height: 225px;
  }
`;
export const WrapperTitle = styled.div`
  position: relative;
  margin-top: ${(props) => props.marginTop && props.marginTop};
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  @media (max-width: 1000px) {
    order: -2;
  }
`;

export const WrapperFirstItems = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    order: -1;
  }
`;
export const WrapperSecondItems = styled.div`
  display: flex;
  margin-left: 10px;
  @media (max-width: 1315px) {
    margin-left: 50px;
  }
  @media (max-width: 1034px) {
    margin-left: 0;
  }
`;
export const WrapperItems = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    margin-left: 50px;
  }
  @media (max-width: 590px) {
    margin-left: 0;
  }
`;
export const Number = styled.div`
  font-weight: 700;
  font-size: 180px;
  line-height: 172px;
  text-transform: uppercase;
  margin-right: 20px;

  color: #ff6a12;

  @media (max-width: 1315px) {
    font-size: 130px;
    line-height: 132px;
  }
  @media (max-width: 590px) {
    font-size: 80px;
    line-height: 80px;
  }
  @media (max-width: 490px) {
    font-size: 80px;
    line-height: 77px;
  }
`;

export const TitleItems = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 72px;
  white-space: nowrap;

  text-transform: uppercase;

  color: #000000;
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  white-space: ${(props) => props.whiteSpace && props.whiteSpace};

  @media (max-width: 1315px) {
    font-size: 25px;
    line-height: 45px;
  }
  @media (max-width: 590px) {
    font-size: 20px;
    line-height: 35px;
    max-width: 320px;
  }
  @media (max-width: 490px) {
    font-size: 20px;
    line-height: 35px;
    max-width: 300px;
    white-space: normal;
  }
`;

export const TitleItemsPar = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 72px;
  white-space: nowrap;

  text-transform: uppercase;

  color: #000000;
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  white-space: ${(props) => props.whiteSpace && props.whiteSpace};

  @media (max-width: 1315px) {
    font-size: 25px;
    line-height: 45px;
  }
  @media (max-width: 590px) {
    font-size: 20px;
    line-height: 35px;
    max-width: 320px;
  }
  @media (max-width: 490px) {
    font-size: 32px;
    line-height: 38px;
    max-width: 290px;
    white-space: normal;
  }
`;
export const TextItems = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 23px;
  max-width: 420px;
  color: #000000;

  @media (max-width: 1315px) {
    font-size: 20px;
    line-height: 20px;
  }
  @media (max-width: 590px) {
    font-size: 15px;
    line-height: 15px;
  }
`;

export const WrapperFlex = styled.div`
  display: flex;
  flex-wrap: wrap;

  row-gap: 20px;
  column-gap: 20px;

  @media (max-width: 1000px) {
    justify-content: center;
  }
  @media (max-width: 590px) {
    justify-content: unset;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;

    justify-items: center;
  }
  @media (max-width: 450px) {
    gap: 0;
    justify-items: flex-start;
  }
`;
export const Info = styled.div`
  max-width: 450px;
  height: 180px;
  box-shadow: 0px 8px 36px -7px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  text-align: center;
  align-items: center;

  @media (max-width: 1315px) {
    max-width: 400px;
    padding: 20px;
  }
  @media (max-width: 1000px) {
    max-width: 450px;
    padding: 40px;
    order: -2;
  }
  @media (max-width: 590px) {
    max-width: 320px;
    padding: 0;
  }
`;

export const InfoText = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;

  color: #000000;

  @media (max-width: 1315px) {
    font-size: 26px;
    line-height: 24px;
  }
  @media (max-width: 590px) {
    font-size: 24px;
    line-height: 23px;
    text-align: left;
    padding-left: 16px;
  }
`;
export const InfoCount = styled.span`
  color: #ff6a12;
`;
export const Img = styled.img`
  margin-left: -25px;
  max-width: 500px;

  @media (max-width: 1315px) {
    max-width: 470px;
  }
  @media (max-width: 1000px) {
    order: 1;
  }
  @media (max-width: 590px) {
    max-width: 320px;
  }
`;
export const Card = styled.div`
  width: 230px;
  height: 385px;
  background: #ffffff;
  box-shadow: 0px 8px 36px -7px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding-right: 5px;
`;
export const BottomCard = styled.div`
  width: 726px;
  height: 230px;
  margin-top: 28px;
  padding-left: 40px;
  padding-top: 56px;
  background: white;
  box-shadow: 0px 0px 10px rgba(94, 94, 94, 0.2);
  border-radius: 15px;
  position: relative;
  z-index: 2;

  @media (max-width: 790px) {
    max-width: 526px;
  }
  @media (max-width: 590px) {
    display: none;
  }
`;
export const CardBottomWrapper = styled.div`
  position: relative;
`;
export const BottomCardText = styled.span`
  font-weight: 400;
  font-size: 27px;
  line-height: 31px;

  color: #000000;
  @media (max-width: 790px) {
    line-height: 20px;
    font-size: 22px;
  }
  @media (max-width: 590px) {
    line-height: 15px;
    font-size: 15px;
  }
`;
export const BottomCardTitle = styled.h4`
  font-weight: 700;
  font-size: 48px;
  line-height: 46px;
  text-transform: uppercase;
  color: #3a3a3a;
  margin-bottom: 24px;
  @media (max-width: 590px) {
    font-size: 36px;
    line-height: 34px;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 790px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const CardMiddleWrapper = styled.div`
  position: relative;
`;
export const CardMiddle = styled.div`
  top: 420px;
  position: absolute;
  @media (max-width: 1315px) {
    position: static;
  }
`;
export const Cards = styled.div`
  display: flex;
  margin-top: 100px;

  @media (max-width: 1315px) {
    max-width: 470px;
    flex-wrap: wrap;
  }
  @media (max-width: 790px) {
    margin-top: 60px;
    justify-content: center;
  }
  @media (max-width: 590px) {
    margin-top: 40px;
  }
`;
export const BigCard = styled.div`
  position: relative;
  width: 376px;
  height: 679px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(94, 94, 94, 0.2);
  border-radius: 15px;
  z-index: 2;
  margin-left: 20px;

  @media (max-width: 1315px) {
    margin-left: 0;
    margin-top: 20px;
  }
  @media (max-width: 590px) {
    max-width: 300px;
  }
`;
export const CardTitle = styled.h4`
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 16px;
  margin-left: 12px;

  color: #3a3a3a;
`;
export const CardImg = styled.img`
  margin-top: 16px;
  margin-left: 16px;
`;

export const CardText = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;

  color: rgba(0, 0, 0, 0.5);
`;

export const CardFooter = styled.div`
  margin-top: 60px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: ${(props) => props.marginTop && props.marginTop};
`;

export const CardBorder = styled.div`
  width: 740px;
  height: 243px;

  background: #fd6a13;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 1;

  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};

  @media (max-width: 1315px) {
    left: 0;
  }
  @media (max-width: 790px) {
    width: 538px;
  }
  @media (max-width: 590px) {
    display: none;
  }
`;
export const CardBigBorder = styled.div`
  width: 740px;
  height: 243px;

  background: #fd6a13;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 1;

  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};

  @media (max-width: 1315px) {
    left: 0;
    top: 20px;
  }
  @media (max-width: 590px) {
    max-width: 310px;
  }
`;
export const BigCardImg = styled.img`
  margin-bottom: 20px;
  margin-left: ${(props) => props.marginLeft && props.marginLeft};

  @media (max-width: 1315px) {
    max-width: 390px;
  }
  @media (max-width: 590px) {
    max-width: 300px;
  }
`;
export const BigCardFinish = styled.img`
  width: 377px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  @media (max-width: 768px) {
    height: 400px;
  }
  @media (max-width: 590px) {
    max-width: 300px;
  }
`;
export const BigCardWrapper = styled.div`
  padding: 30px;
  margin-top: 60px;
  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 10px;
  }
  @media (max-width: 590px) {
    padding: 10px;
    margin-top: 10px;
  }
`;
export const PartnersText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;

  color: #000000;
  margin-top: ${(props) => props.marginTop && props.marginTop};
  @media (max-width: 590px) {
    font-size: 15px;
    line-height: 20px;
    max-width: 300px;
  }
`;

export const FormCard = styled.form`
  padding-top: 40px;
  padding-left: 56px;
  padding-right: 56px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  width: 684px;

  background: #f8f8f8;
  border-radius: 16px;
  @media (max-width: 1315px) {
    max-width: 584px;
  }
  @media (max-width: 590px) {
    padding: 20px;
    max-width: 320px;
  }
  @media (max-width: 490px) {
    margin-top: 20px;
    padding: 15px;
    max-width: 288px;
  }
`;

export const FormTitle = styled.h4`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;

  color: #000000;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #323232;
  outline: unset;
  padding-left: 10px;
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 32px;

  font-weight: 700;
  font-size: 16px;
  line-height: 15px;

  color: #000000;
`;
export const Button = styled(DefaultButton)`
  padding: 6px 32px;

  width: 100%;
  height: 48px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin-top: 36px;
  cursor: pointer;

  opacity: ${({ isChecked }) => !isChecked && 0.5};
  pointer-events: ${({ isChecked }) => (!isChecked ? "none" : "all")};
`;
export const TextArea = styled.textarea`
  outline: unset;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #323232;
  height: 150px;
`;
export const CheckboxWrapper = styled.div`
  margin-top: 16px;
`;
export const CheckboxText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
`;
export const Span = styled.span`
  text-decoration: underline;
`;

export const PhoneInput = styled(MaskedInput)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #323232;
  outline: unset;
  padding-left: 10px;
`;
