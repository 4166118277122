import { Box, Heading, Image, Paragraph } from "grommet";
import React from "react";
import styled from "styled-components";
import tbMen from "../../images/OlympicReserve/tbMen.svg";
import tbWomen from "../../images/OlympicReserve/tbWomen.svg";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

export const SelectionTab = () => {
  return (
    <Box>
      <Heading
        level={"3"}
        margin={{ top: "18px", bottom: "18px" }}
        style={{ textTransform: "uppercase" }}
      >
        Критерии отбора
      </Heading>
      <Box gap="large">
        <CustomParagraph>
          Отбор в команду Олимпийского резерва могут пройти юноши и девушки в
          возрасте от 12-16 лет. При условии, что результаты по каждому из видов
          спорта (плавание 300-500м, бег 2000-3000м) находятся в диапазоне ± 5%
          от целевых значений в таблице. Результат должен быть показан на
          официальных соревнованиях (протоколы нужно прикрепить к заявке) или
          снять на видео контрольную тренировку с нужной дистанции. Если нет
          возможности прикрепить одно из двух, просто указать в заявке свои
          лучшие результаты на данных дистанциях.
        </CustomParagraph>

        <Image src={tbMen} />

        <Image src={tbWomen} />

        <CustomParagraph>
          Отбор включает в себя 2 этапа. Результаты будут опубликованы в
          официальном аккаунте команды. Спортсмены, успешно прошедшие оба этапа,
          приглашаются на тренировочный сбор с командой «Олимпийский резерв» для
          улучшения собственных результатов с помощью комплексного и
          профессионального подхода к тренировочному процессу, восстановлению и
          питанию.
        </CustomParagraph>
      </Box>
    </Box>
  );
};
