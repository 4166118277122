import { Box, ResponsiveContext, Text } from "grommet";
import React, { useContext, useEffect, useState } from "react";
import { PasswordInput } from "../../Inputs/PasswordInput";
import { TextInput } from "../../Inputs/TextInput";
import { routes } from "../../../const/routes";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeValidationSchema } from "../makeValidationSchema";
import { PopupWithButton } from "../../Popups/PopupWithButton";
import styled from "styled-components";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { InputPhone } from "../../Inputs/PhoneInput";
import { DefaultButton } from "../../DefaultButton";

const LinkWrapper = styled.a``;

export const SignUpForm = ({
  getIsSendedMail,
  getAuthError,
  setAuthError,
  setSendMail,
  registration,
  as,
  setUserPhoneStatus,
  setUserCodeStatus,
  getRegistrationSmsCode,
  registrationSms,
  authPhoneStatus,
  authCodeStatus,
}) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const [isSms, setIsSms] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const validateForm = makeValidationSchema(
    isSms,
    sendCode,
    setUserPhoneStatus,
    setUserCodeStatus
  );
  const navigation = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
  });

  useEffect(() => {
    return () => {
      setAuthError(``);
      setSendMail(false);
    };
    //eslint-disable-next-line
  }, []);

  const sendedMailHandler = () => {
    setSendMail(false);
    window.location.href = routes.main.path;
  };

  const onSubmit = ({ email, phone, password, code }) => {
    if (email && !isSms) {
      registration({ email, password });
    } else if (phone && !code) {
      getRegistrationSmsCode(
        {
          phone: phone.slice(1),
          registration: true,
        },
        setSendCode
      );
    } else if (code) {
      registrationSms(
        {
          phone_code: code,
          phone: phone.slice(1),
        },
        navigation
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        round={"small"}
        pad={
          isMobileWidth
            ? { top: "26px", bottom: "45px", horizontal: "20px" }
            : { top: "30px", bottom: "40px", horizontal: "45px" }
        }
      >
        <Box flex direction={"column"}>
          {!isSms && (
            <Box
              direction={isMobileWidth ? "column" : "row"}
              gap={isMobileWidth ? "2rem" : "4rem"}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextInput
                    error={errors.email?.message}
                    handleFocus={() => clearErrors("email")}
                    name="email"
                    id="signUp-email"
                    handleChange={(e) => setValue(e.target.value)}
                    label="Введите ваш email"
                    placeholder="Ваш email"
                    {...field}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    error={errors.password?.message}
                    onFocus={() => clearErrors("password")}
                    name="password"
                    id="signUp-password"
                    label="Введите ваш пароль"
                    placeholder="Ваш пароль"
                  />
                )}
              />
            </Box>
          )}
          {isSms && (
            <Box
              flex
              direction={isMobileWidth ? "column" : "row"}
              gap={isMobileWidth ? "2rem" : "4rem"}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputPhone
                    country="RU"
                    international={true}
                    withCountryCallingCode={true}
                    error={errors.phone?.message || authPhoneStatus}
                    onChange={(e) => setValue("phone", e.target.value)}
                    id="profile-tel-reg"
                    label="Введите ваш телефон"
                    name="phone"
                    onFocus={() => clearErrors("phone")}
                    iconSrc="rect"
                    iconHeight="40px"
                    iconLeft="20px"
                    iconTop="8px"
                    {...field}
                  />
                )}
              />
              {!authPhoneStatus && sendCode && (
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      error={errors.code?.message || authCodeStatus}
                      handleFocus={() => clearErrors("code")}
                      name="code"
                      id="signUp-code-sms"
                      handleChange={(e) => setValue(e.target.value)}
                      label="Введите код из СМС"
                      {...field}
                    />
                  )}
                />
              )}
            </Box>
          )}
          <Box flex direction="column">
            <Box flex>
              <DefaultButton
                style={{
                  padding: "10px",
                  maxWidth: "406px",
                  marginTop: "20px",
                  textAlign: "center",
                  rel: "noopener noreferrer",
                }}
                type="submit"
              >
                {isSms && !sendCode ? "Получить код" : "Зарегистрироваться"}
              </DefaultButton>
            </Box>

            <Box
              flex
              direction={isMobileWidth ? "column" : "row"}
              gap="1rem"
              align="center"
              margin={{ top: "1.5rem" }}
            >
              <Text
                style={{
                  lineHeight: "22px",
                  fontStyle: "italic",
                  color: "black",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                size="small"
                color="black"
                onClick={() => setIsSms(!isSms)}
              >
                {isSms
                  ? "Регистрация через email"
                  : "Регистрация через телефон"}
              </Text>
              <Text
                style={{
                  lineHeight: "22px",
                  fontStyle: "italic",
                  color: "black",
                }}
                size="small"
                color="black"
              >
                Уже есть аккаунт?
              </Text>
              <LinkWrapper as={as} to={routes.signIn.path}>
                <Text
                  style={{
                    lineHeight: "22px",
                    fontStyle: "italic",
                    color: "black",
                  }}
                  size="small"
                  color="black"
                >
                  Войти
                </Text>
              </LinkWrapper>
            </Box>

            {getIsSendedMail ? (
              <PopupWithButton
                subtext={"Проверьте почту!"}
                paragraph={
                  "Для подтверждения перейдите по ссылке, указанной в письме (письмо может быть в спаме)"
                }
                closePopup={sendedMailHandler}
                button="Назад на главную"
              />
            ) : null}

            {getAuthError?.length ? (
              <PopupWithButton
                paragraph={getAuthError}
                subtext={"Ошибка регистрации"}
                closePopup={() => setAuthError("")}
                button="Вернуться к регистрации"
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </form>
  );
};
