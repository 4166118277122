import React, { useContext } from "react";
import { Box, Heading, ResponsiveContext, Text } from "grommet";
import styled from "styled-components";
import { Location } from "grommet-icons";
import { getDate } from "../../../utils/date";
import { isMobileTabletResponsive } from "../../../utils/utils";

const StyledText = styled(Text)`
  border-right: 1px solid black;
  padding-right: 1rem;
`;

export const ActivitySection = ({ activities }) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  return (
    <>
      <Heading level={2} margin={{ bottom: "3rem" }}>
        Активность профиля
      </Heading>

      <Text>Откуда вы заходили в ваш профиль</Text>

      {activities.length > 0
        ? activities.map((el, i) => (
            <Box
              flex
              justify="start"
              align="start"
              height={{ max: "100px" }}
              key={el.method + i}
            >
              <Box
                width="100%"
                pad={{ vertical: "24px" }}
                style={{ borderBottom: "1px solid black" }}
                flex
                direction="row"
              >
                <Box margin={{ right: "1rem" }}>
                  <Location size="medium" color="black" />
                </Box>
                <Box flex direction="column">
                  <Heading level={6}>
                    {el.city || `Неизвестный хутор`},{" "}
                    {el.country || `Далекое Забугорье`}
                  </Heading>

                  <Box flex direction="row" height={{ max: "16px" }}>
                    <StyledText
                      size={isMobileWidth ? "xsmall" : "small"}
                      margin={{ right: isMobileWidth ? "0.3rem" : "1rem" }}
                    >
                      {i === 0
                        ? "Сессия активна"
                        : `${getDate(el.temp).date} ${getDate(el.temp).month} ${
                            getDate(el.temp).year
                          }`}
                    </StyledText>
                    <StyledText
                      size={isMobileWidth ? "xsmall" : "small"}
                      margin={{ right: isMobileWidth ? "0.3rem" : "1rem" }}
                    >
                      {el.os}
                    </StyledText>
                    <Text size={isMobileWidth ? "xsmall" : "small"}>
                      Через {el.browser}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        : "Нет данных об активности профиля."}
    </>
  );
};
