import styled from "styled-components";

export const StyledLabel = styled.label`
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  font-size: 0.7rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
`;

export const StyledRadioButton = styled.span`
  height: 16px;
  width: 16px;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  &::after {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #db4c4c;
    opacity: 0;
    transition: opacity 0.2s;
  }
`;

export const StyledInput = styled.input`
  display: none;
  &:checked ~ ${StyledLabel} ${StyledRadioButton}::after {
    opacity: 1;
  }
`;
