import backImage from "../images/webp/slider1.68d92953.webp";
import backImage2 from "../images/webp/slider2.34dc42c1.webp";
import backImage3 from "../images/webp/slider3.48caac74.webp";
import backImage4 from "../images/webp/slider4.1bc73f19.webp";
import backImage6 from "../images/webp/slider6.48ff4b24.webp";
import GPB from "../images/partnerLogos/gpb_logo.svg";
import RZD from "../images/partnerLogos/rzd_logo.png";
import OCR from "../images/partnerLogos/ocr_logo.png";
import PSB from "../images/partnerLogos/psb_logo.svg";
import VTB from "../images/partnerLogos/vtb_logo.png";
import Lukoil from "../images/partnerLogos/lukoil_logo.svg";
import partnerLogo0 from "../images/partnerLogos/uralchemLogo.svg";
import partnerLogo1 from "../images/partnerLogos/partnerLogo1.png";
import partnerLogo2 from "../images/partnerLogos/partnerLogo2.png";
import partnerLogo4 from "../images/partnerLogos/partnerLogo4.png";
import partnerLogo5 from "../images/partnerLogos/partnerLogo5.png";
import partnerLogo6 from "../images/partnerLogos/partnerLogo6.png";
import partnerLogo7 from "../images/partnerLogos/partnerLogo7.png";
import partnerLogo8 from "../images/partnerLogos/partnerLogo8.png";
import partnerLogo9 from "../images/partnerLogos/partnerLogo9.png";

import partnerLogo11 from "../images/partnerLogos/partnerLogo11.png";
import partnerLogo12 from "../images/partnerLogos/partnerLogo12.png";
import partnerLogo13 from "../images/partnerLogos/partnerLogo13.png";
import partnerLogo15 from "../images/partnerLogos/partnerLogo15.png";
import partnerLogo16 from "../images/partnerLogos/partnerLogo16.png";

import partnerLogo18 from "../images/partnerLogos/partnerLogo18.svg";
import partnerLogo23 from "../images/partnerLogos/partnerLogo23.png";
import { routes } from "../const/routes";

export const mainPartnerLogos = [
  {
    id: 0,
    logo: GPB,
    width: 200,
    height: 53,
    title: "Генеральный партнёр",
    path: "https://www.gazprombank.ru/",
  },
  {
    id: 1,
    logo: RZD,
    width: 150,
    title: "Официальный партнёр",
    path: "https://www.rzd.ru/",
  },
  {
    id: 2,
    logo: PSB,
    width: 150,
    title: "Генеральный партнёр Детской лиги Триатлона",
    path: "https://www.psbank.ru/",
  },
  {
    id: 3,
    logo: Lukoil,
    width: 170,
    title: "Партнёр Суперсерии Триатлона",
    path: "https://lukoil.ru/",
  },
  {
    id: 4,
    logo: VTB,
    width: 100,
    title: "Официальный спонсор Кубка России",
    path: "https://www.vtb.ru/",
  },
];

export const mainPageSlides = [
  {
    id: 0,
    date: "",
    title: "Стартовая форма",
    desc: "Стартовые костюмы для триатлона от Veter Cycling",
    image: backImage6,
    link: "https://veter.cc/ftr",
    btnText: "Перейти",
    isButton: true,
    outside: true,
  },
  {
    id: 1,
    date: "",
    title: "Новости",
    desc: "Новости Федерации Триатлона России",
    image: backImage4,
    link: routes.news.path,
    btnText: "Читать",
    isButton: true,
    outside: false,
  },
  {
    id: 2,
    date: "",
    title: "Как начать?",
    desc: "Хотите пройти свой первый триатлон, но не знаете, с чего начать?",
    image: backImage2,
    link: routes.whereToStart.path,
    btnText: "Читать",
    isButton: true,
    outside: false,
  },
  {
    id: 3,
    date: "",
    title: "Календарь стартов",
    desc: "Актуальный календарь триатлонных стартов на всей территории России и за её пределами",
    image: backImage3,
    link: `${routes.calendar.path}/all`,
    btnText: "Посмотреть",
    isButton: true,
    outside: false,
  },
  {
    id: 4,
    date: "",
    title: "История триатлона",
    desc: "",
    image: backImage,
    link: routes.historyTria.path,
    btnText: "Читать",
    isButton: true,
    outside: false,
  },
];

export const partnerLogosArr = [
  {
    id: 2,
    logo: partnerLogo0,
    width: "136",
  },
  {
    id: 0,
    logo: partnerLogo1,
    width: "136",
  },
  {
    id: 1,
    logo: partnerLogo2,
    width: "150",
  },
  {
    id: 3,
    logo: partnerLogo4,
    width: "159",
  },
  {
    id: 4,
    logo: partnerLogo5,
    width: "212",
  },
  {
    id: 5,
    logo: partnerLogo6,
    width: "170",
  },
  {
    id: 6,
    logo: partnerLogo7,
    width: "133",
  },
  {
    id: 7,
    logo: partnerLogo8,
    width: "150",
  },
  {
    id: 8,
    logo: partnerLogo9,
    width: "150",
  },
  {
    id: 10,
    logo: partnerLogo11,
    width: "182",
  },
  {
    id: 11,
    logo: partnerLogo12,
    width: "150",
  },
  {
    id: 12,
    logo: partnerLogo13,
    width: "163",
  },
  {
    id: 14,
    logo: partnerLogo15,
    width: "110",
  },
  {
    id: 15,
    logo: partnerLogo16,
    width: "110",
  },
  {
    id: 16,
    logo: OCR,
    width: "60",
  },
  {
    id: 18,
    logo: partnerLogo18,
    width: "159",
  },
  {
    id: 23,
    logo: partnerLogo23,
    width: "180",
  },
];

export const antiDoping = [
  {
    title: "Сообщить о допинге",
    path: "https://www.rusada.ru/doping-control/investigations/report-about-doping/",
  },
  {
    title: "Дисквалификация",
    path: "https://rusada.ru/doping-control/disqualifications/",
  },
  {
    title: "РУСАДА",
    path: "http://rusada.ru/documents/",
  },
  {
    title: "МИНСПОРТА",
    path: "https://minsport.gov.ru/sport/antidoping/",
  },
  {
    title: "Проверить лекарство",
    path: "https://list.rusada.ru/",
  },
  {
    title: "Обучение",
    path: "https://course.rusada.ru/",
  },
];
