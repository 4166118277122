import { DocumentItem } from "../../DocumentItem";
import { Box } from "grommet";
import { antiDoping } from "../../../mocks";

export const OtherLinks = () => {
  return (
    <Box>
      {antiDoping.map((a) => (
        <Box key={a.title} flex direction="column" margin={{ bottom: "20px" }}>
          <DocumentItem title={a.title} link={a.path} />
        </Box>
      ))}
    </Box>
  );
};
