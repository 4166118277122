import React, { useContext, useState } from "react";
import { Box, Button, ResponsiveContext } from "grommet";
import { TextInput } from "../../Inputs/TextInput";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { getLSValue } from "../../../utils/storage";
import { InputPhone } from "../../Inputs/PhoneInput";
import { makeValidationSchema } from "./makeValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DefaultButton } from "../../DefaultButton";

export const PhoneForm = ({
  getViewCodeParam,
  changePhoneStatus,
  setViewCodeField,
  setChangePhoneStatus,
  setNewPhone,
  sendNewPhoneCode,
  getChangeCodeStatus,
  setChangeCodeStatus,
}) => {
  const validateForm = makeValidationSchema(setChangePhoneStatus);
  const user = localStorage.userObj && JSON.parse(localStorage.userObj);

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
    defaultValues: { phone: user?.phone && `+${user?.phone}` },
  });

  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const [phone, setPhone] = useState(user?.phone && `+${user?.phone}`);

  const handleCodeSend = () => {
    const phoneSlice = phone.slice(1);
    sendNewPhoneCode({ phone: phoneSlice });
  };

  const handleInputCode = (e) => {
    const value = e.target.value;
    if (Number(value) && value.length === 4) {
      setNewPhone({
        phone: phone.slice(1),
        phone_code: value,
      });
    } else {
      setChangeCodeStatus("Код должен состоять из 4 цифр");
    }
  };

  const handleResetChange = () => {
    setViewCodeField("");
    setChangePhoneStatus("");
    setChangeCodeStatus("");
  };

  const onSubmit = ({ phone }) => {
    setPhone(phone);
    const phoneSlice = phone.slice(1);
    sendNewPhoneCode({ phone: phoneSlice });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box flex direction={isMobileWidth ? "column" : "row"} gap="2rem">
          <Box width={isMobileWidth ? "100%" : "48%"} gap="0.5rem">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputPhone
                  country="RU"
                  international={true}
                  withCountryCallingCode={true}
                  error={errors.phone?.message || changePhoneStatus}
                  id="profile-tel"
                  name="phone"
                  onFocus={() => clearErrors("phone")}
                  disabled={getViewCodeParam}
                  iconSrc="rect"
                  iconHeight="40px"
                  iconLeft="20px"
                  iconTop="8px"
                  {...field}
                />
              )}
            />
            {getViewCodeParam ? (
              <Button onClick={handleResetChange}>Изменить</Button>
            ) : null}
          </Box>

          {!getViewCodeParam ? (
            <Box
              flex
              align="center"
              justify="center"
              height="56px"
              margin={{ top: !isMobileWidth && "1.6rem" }}
              width={{ max: "406px" }}
            >
              <DefaultButton
                style={{
                  padding: "10px",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "8px",
                  height: "56px",
                }}
                type="submit"
              >
                {getLSValue("userObj", "phone")
                  ? "Изменить телефон"
                  : "Добавить телефон"}
              </DefaultButton>
            </Box>
          ) : (
            <Box flex direction={"column"}>
              <TextInput
                error={getChangeCodeStatus}
                handleChange={handleInputCode}
                name={"code"}
                id={"profile-code"}
                label="Код из СМС"
              />
              <Box
                flex
                align="center"
                justify="center"
                width={{ max: "406px" }}
              >
                <DefaultButton
                  style={{
                    padding: "10px",
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "8px",
                    height: "56px",
                    marginTop: "20px",
                  }}
                  onClick={handleCodeSend}
                >
                  {"Отправить код повторно"}
                </DefaultButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
};
