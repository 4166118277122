import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Container } from "../../components/Container";
import { makeValidationSchema } from "./makeValidationSchema";
import { useUserStore } from "../../hooks/useUserStore";
import { PasswordInput } from "../../components/Inputs/PasswordInput";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Box } from "grommet";
import { DefaultButton } from "../../components/DefaultButton";

export const NewPassPage = () => {
  const validateForm = makeValidationSchema();
  const navigate = useNavigate();

  const { setNewPassword, initCode, secureCode } = useUserStore();

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({ resolver: yupResolver(validateForm), shouldFocusError: false });

  const onSubmit = ({ password }) => {
    const data = {
      code: initCode,
      secure: secureCode,
      password,
    };

    setNewPassword(data, navigate);
  };

  return (
    <Container>
      <Breadcrumbs />
      <Box margin={{ bottom: "100px", top: "100px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordInput
                {...field}
                error={errors.password?.message}
                onFocus={() => clearErrors("password")}
                name="password"
                id="current-new-password"
                label="Придумайте новый пароль"
                onChange={(e) => setValue("password", e.target.value)}
                iconSrc="rect"
                iconHeight="40px"
                iconLeft="20px"
                iconTop="8px"
              />
            )}
          />
          <DefaultButton
            style={{
              padding: "10px",
              width: "406px",
              textAlign: "center",
              borderRadius: "8px",
              height: "56px",
            }}
            type="submit"
          >
            Отправить
          </DefaultButton>
        </form>
      </Box>
    </Container>
  );
};
