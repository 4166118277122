import { Box } from "grommet";
import { DocumentItem } from "../../DocumentItem";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useRegionsStore } from "../../../hooks/useRegionsStore";
import { DefaultButton } from "../../DefaultButton";

export const Documents = observer(() => {
  const { items, antiDopingDocuments } = useRegionsStore();

  useEffect(() => {
    antiDopingDocuments();
    // eslint-disable-next-line
  }, []);
  return (
    <Box>
      <Box
        align={"start"}
        direction={"row"}
        gap={"small"}
        margin={{ bottom: "large" }}
        style={{ textAlign: "center" }}
      >
        <DefaultButton
          href={"https://rusada.triagonal.net/"}
          margin={{ top: "small" }}
          label={"Обучение"}
        />

        <DefaultButton
          href={"https://list.rusada.ru/"}
          margin={{ top: "small" }}
          label={"Проверить лекарство"}
        />
      </Box>
      {items.map((i) => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          margin={{ bottom: "20px" }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.path} />
        </Box>
      ))}
    </Box>
  );
});
