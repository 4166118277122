import React, { useContext } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { MESSAGES } from "../../../const/messages";
import { getLSValue } from "../../../utils/storage";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeValidationSchema } from "./makeValidationSchema";
import { TextInput } from "../../Inputs/TextInput";
import { DefaultButton } from "../../DefaultButton";

export const EmailForm = ({ getChangeEmailStatus, setNewMail }) => {
  const validateForm = makeValidationSchema();
  const user = localStorage.userObj && JSON.parse(localStorage.userObj);

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
    defaultValues: { email: user?.email || "" },
  });

  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  const onSubmit = (data) => setNewMail(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box
          flex
          direction={isMobileWidth ? "column" : "row"}
          gap={isMobileWidth ? "1rem" : "2rem"}
        >
          <Box width={isMobileWidth ? "100%" : "48%"}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInput
                  error={errors.email?.message}
                  handleFocus={() => clearErrors("email")}
                  handleChange={(e) => setValue("email", e.target.value)}
                  name="email"
                  iconSrc="rect"
                  iconHeight="40px"
                  iconLeft="20px"
                  iconTop="8px"
                  id="profile-email"
                  defaultValue={user?.email}
                  label="Ваш email"
                  {...field}
                />
              )}
            />
          </Box>
          <Box flex direction={"column"}>
            <Box
              flex
              height="4.8rem"
              margin={{ top: isMobileWidth && "1.6rem" }}
            >
              {getChangeEmailStatus.length ? (
                <Text size="small">{getChangeEmailStatus}</Text>
              ) : null}
            </Box>
            <Box width={{ max: "406px" }}>
              <DefaultButton
                type="submit"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "8px",
                  height: "56px",
                  marginBottom: `${errors.email?.message ? "27px" : "10px"}`,
                }}
              >
                {getChangeEmailStatus === MESSAGES.EMAIL_NOT_CONFIRM
                  ? "Подтвердить почту"
                  : getLSValue("userObj", "email")
                  ? "Изменить почту"
                  : "Добавить почту"}
              </DefaultButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
