import React from "react";
import { routes } from "../../const/routes";
import { getFormatTime } from "../../utils/date";
//import { ReactComponent as TimeLogo } from "../../images/svg/time.svg";
import { capitalizeFirstLetter } from "../../utils/utils";
import {
  StyledA,
  StyledAwait,
  StyledBtnHoverNoReg,
  StyledBtnHoverReg,
  StyledCover,
  StyledCoverImg,
  StyledInfo,
  StyledInfoLabel,
  StyledItem,
  StyledLogo,
  StyledPast,
  StyledWrap,
  StyledWrapper,
} from "./styles";
import { useState } from "react";
import { ReactComponent as IconQRCode } from "../../images/svg/icon-qr-code.svg";
import { QRCodeSVG } from "qrcode.react";
import styles from "./TicketCard.module.scss";

export const TicketEventsCard = ({ item, status, past, as, transfered }) => {
  const [isQrClick, setIsQrClick] = useState(false);
  return (
    <StyledItem>
      {isQrClick ? (
        <QRCodeSVG
          value={item.public_id}
          className={styles.qrCodeWrapper}
          size={200}
          onClick={() => setIsQrClick(false)}
          bgColor={"#fff"}
          fgColor={"#000"}
          level={"L"}
          includeMargin={false}
        />
      ) : (
        <StyledWrapper>
          <div>
            <StyledWrap>
              <StyledLogo
                src={item.event.event_type.logo}
                alt={item.event_format.title}
              />
            </StyledWrap>
            {transfered && (
              <StyledInfo>
                <span>{item.info.email}</span>
              </StyledInfo>
            )}
            <StyledInfo>
              <StyledInfoLabel>Формат:</StyledInfoLabel>
              {item.event_format.title}
            </StyledInfo>
            <StyledInfo>
              <StyledInfoLabel>Дата и время старта:</StyledInfoLabel>
              {getFormatTime(item)}
            </StyledInfo>
            <StyledInfo>
              <StyledInfoLabel>Город:</StyledInfoLabel>
              {item.city.name_ru}
            </StyledInfo>
            {status ? (
              <>
                {item.info.first_name && item.info.last_name && (
                  <StyledInfo>
                    <StyledInfoLabel>ФИО:</StyledInfoLabel>
                    {`${capitalizeFirstLetter(
                      item.info.first_name
                    )} ${capitalizeFirstLetter(item.info.last_name)}`}
                  </StyledInfo>
                )}
                {/* {item.event_format.insurance_available && (
                <StyledInfo>
                  <StyledInfoLabel>Страховка:</StyledInfoLabel>
                  {item.info.insurance ? "Есть" : "Нет"}
                </StyledInfo>
              )} */}
              </>
            ) : null}
          </div>

          {status ? (
            <>
              <StyledInfo>
                <StyledInfoLabel>Номер:</StyledInfoLabel>№&nbsp;
                {item.info.number || "*****"}
                {item.team && (
                  <>
                    <StyledInfoLabel>Команда:</StyledInfoLabel>№&nbsp;
                    {item.team.number}
                    {`${
                      item.info.inside_number
                        ? "-" + item.info.inside_number
                        : ""
                    }`}
                  </>
                )}
                {item?.info?.sector && (
                  <>
                    {" "}
                    <span
                      style={{ marginTop: "5px" }}
                      className={styles.itemLabel}
                    >
                      Сектор:
                    </span>
                    {item.info.sector}
                  </>
                )}
                {item?.info?.row && (
                  <>
                    {" "}
                    <span className={styles.itemLabel}>Ряд:</span>
                    {item.info.row}
                  </>
                )}
                {item?.info?.place && (
                  <>
                    <span className={styles.itemLabel}>Место:</span>
                    {item.info.place}
                  </>
                )}
                {item?.event_format?.qr && (
                  <IconQRCode
                    style={{
                      right: "30px",
                      top:
                        item.info.row && item.team
                          ? "170px"
                          : item.info.row && !item.team
                          ? "140px"
                          : item.team
                          ? "120px"
                          : "80px",
                    }}
                    className={styles.qr}
                    onClick={() => setIsQrClick(true)}
                  />
                )}
              </StyledInfo>
              <StyledA
                as={as}
                to={`${routes.me.path}/events/${item.public_id}`}
              >
                <StyledBtnHoverNoReg>Билет зарегистрирован</StyledBtnHoverNoReg>
                <StyledBtnHoverReg>Подробнее</StyledBtnHoverReg>
              </StyledA>
            </>
          ) : !past ? (
            <>
              <StyledInfo>
                <StyledInfoLabel>Номер билета:</StyledInfoLabel>№
                {item.ticketNumber || "*****"}
                {item?.info?.sector && (
                  <>
                    {" "}
                    <span
                      style={{ marginTop: "5px" }}
                      className={styles.itemLabel}
                    >
                      Сектор:
                    </span>
                    {item.info.sector}
                  </>
                )}
                {item?.info?.row && (
                  <>
                    {" "}
                    <span className={styles.itemLabel}>Ряд:</span>
                    {item.info.row}
                  </>
                )}
                {item?.info?.place && (
                  <>
                    <span className={styles.itemLabel}>Место:</span>
                    {item.info.place}
                  </>
                )}
                {item?.event_format?.qr && (
                  <IconQRCode
                    style={{
                      right: "30px",
                      top:
                        item.info.row && item.team
                          ? "170px"
                          : item.info.row && !item.team
                          ? "140px"
                          : item.team
                          ? "120px"
                          : "80px",
                    }}
                    className={styles.qr}
                    onClick={() => setIsQrClick(true)}
                  />
                )}
              </StyledInfo>
              {item.status === "processing" ? (
                <StyledAwait>
                  {/*  <TimeLogo /> */}
                  <span style={{ marginLeft: "0.75rem" }}>
                    Ожидайте возврат
                  </span>
                </StyledAwait>
              ) : (
                <StyledA
                  as={as}
                  to={`${routes.me.path}/events/${item.public_id}`}
                >
                  Зарегистрироваться
                </StyledA>
              )}
            </>
          ) : (
            <>
              <StyledInfo>
                <StyledInfoLabel>Номер билета:</StyledInfoLabel>№&nbsp;
                {item.info.number || "*****"}
                {item?.info?.sector && (
                  <>
                    {" "}
                    <span
                      style={{ marginTop: "5px" }}
                      className={styles.itemLabel}
                    >
                      Сектор:
                    </span>
                    {item.info.sector}
                  </>
                )}
                {item?.info?.row && (
                  <>
                    {" "}
                    <span className={styles.itemLabel}>Ряд:</span>
                    {item.info.row}
                  </>
                )}
                {item?.info?.place && (
                  <>
                    <span className={styles.itemLabel}>Место:</span>
                    {item.info.place}
                  </>
                )}
                {/*  {item?.event_format?.qr && (
                  <IconQRCode
                    style={{ top: "145px" }}
                    className={styles.qr}
                    onClick={() => setIsQrClick(true)}
                  />
                )} */}
              </StyledInfo>
              {/*   {item.event_format.online && item.event_format.online ? (
              <StyledPast as={as} to={`${routes.myEvents.path}/${item.public_id}`}>
                Завершено
              </StyledPast>
            ) : ( */}
              <StyledPast>Завершено</StyledPast>
              {/*   )} */}
            </>
          )}
        </StyledWrapper>
      )}
      <StyledCover>
        {item.event.banners.landing_page.mobile_picture && (
          <StyledCoverImg
            src={item.event.banners.landing_page.mobile_picture}
            alt={item.event.event_type.title}
          />
        )}
      </StyledCover>
    </StyledItem>
  );
};
