import axios from "axios";
import { routes } from "./const/routes";

export const REACT_APP_API = process.env.REACT_APP_API;
export const additionalApi = "https://rustriathlon.ru";

const TIMEOUT = 5000;

const Error = {
  GLOBAL_ERROR: 400,
  INTERNAL_SERVER: 500,
  UNAUTHORIZED: 401,
  INVALID_TOKEN: 403,
};

// Экспорт основного модуля api
export const createAPI = () => {
  // базовый объект конфигурации
  const api = axios.create({
    baseURL: REACT_APP_API,
    timeout: TIMEOUT,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  // functions - for interceptors
  const onSuccess = (response) => response;

  const onFail = (err) => {
    const { response } = err;
    if (!response) throw err;
    else if (
      response.status === Error.UNAUTHORIZED ||
      response.status === Error.INVALID_TOKEN
    ) {
      if (response.status === Error.INVALID_TOKEN)
        localStorage.removeItem("token");
      window.location.href = routes.signIn.path;
      throw err;
    } else if (response.data) return response;

    // Если запрос вообще неудачный - бросить ошибку
    throw err;
  };

  api.interceptors.response.use(onSuccess, onFail); // перехватчик ответа

  return api;
};

export const api = createAPI();
