import { Box, ResponsiveContext, Text } from "grommet";
import React, { useContext, useEffect } from "react";
import { PasswordInput } from "../../Inputs/PasswordInput";
import { TextInput } from "../../Inputs/TextInput";
import { PopupWithButton } from "../../Popups/PopupWithButton";
import { routes } from "../../../const/routes";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeValidationSchema } from "../makeValidationSchema";
import styled from "styled-components";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { useState } from "react";
import { InputPhone } from "../../Inputs/PhoneInput";
import { DefaultButton } from "../../DefaultButton";

const LinkWrapper = styled.a``;

export const SignInForm = ({
  getAuthError,
  setAuthError,
  login,
  as,
  setRecoveryPopup,
  getRegistrationSmsCode,
  phoneAuth,
  authCodeStatus,
  setUserPhoneStatus,
  authPhoneStatus,
  setUserCodeStatus,
}) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const [isSms, setIsSms] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const validateForm = makeValidationSchema(
    isSms,
    sendCode,
    setUserPhoneStatus,
    setUserCodeStatus
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({ resolver: yupResolver(validateForm), shouldFocusError: false });

  useEffect(() => {
    return () => {
      setAuthError("");
    };
    //eslint-disable-next-line
  }, []);

  const onSubmit = ({ email, phone, password, code }) => {
    if (email && !isSms) {
      login({ email, password });
    } else if (phone && !code) {
      getRegistrationSmsCode(
        {
          phone: phone.slice(1),
          registration: false,
        },
        setSendCode
      );
    } else if (code) {
      phoneAuth({
        phone_code: code,
        phone: phone.slice(1),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        round={"small"}
        pad={
          isMobileWidth
            ? { top: "26px", bottom: "20px", horizontal: "20px" }
            : { vertical: "30px", horizontal: "45px" }
        }
      >
        <Box flex direction={"column"}>
          {!isSms && (
            <Box
              flex
              direction={isMobileWidth ? "column" : "row"}
              gap={isMobileWidth ? "2rem" : "4rem"}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextInput
                    error={errors.email?.message}
                    handleFocus={() => clearErrors("email")}
                    name="email"
                    id="signIn-email"
                    handleChange={(e) => setValue(e.target.value)}
                    label="Введите ваш email"
                    placeholder="Ваш email"
                    {...field}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    error={errors.password?.message}
                    onFocus={() => clearErrors("password")}
                    name="password"
                    id="signIn-password"
                    label="Введите ваш пароль"
                    placeholder="Ваш пароль"
                  />
                )}
              />
            </Box>
          )}
          {isSms && (
            <Box
              flex
              direction={isMobileWidth ? "column" : "row"}
              gap={isMobileWidth ? "2rem" : "4rem"}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputPhone
                    country="RU"
                    international={true}
                    withCountryCallingCode={true}
                    error={errors.phone?.message || authPhoneStatus}
                    onChange={(e) => setValue("phone", e.target.value)}
                    id="profile-tel-sms"
                    label="Введите ваш телефон"
                    name="phone"
                    onFocus={() => clearErrors("phone")}
                    iconSrc="rect"
                    iconHeight="40px"
                    iconLeft="20px"
                    iconTop="8px"
                    {...field}
                  />
                )}
              />
              {!authPhoneStatus && sendCode && (
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      error={errors.code?.message || authCodeStatus}
                      handleFocus={() => clearErrors("code")}
                      name="code"
                      id="signIn-code"
                      handleChange={(e) => setValue(e.target.value)}
                      label="Введите код из СМС"
                      {...field}
                    />
                  )}
                />
              )}
            </Box>
          )}
          <Box flex>
            <DefaultButton
              style={{
                padding: "10px",
                maxWidth: "406px",
                marginTop: "20px",
                textAlign: "center",
                rel: "noopener noreferrer",
              }}
              type="submit"
            >
              {isSms && !sendCode ? "Получить код" : "Войти"}
            </DefaultButton>
          </Box>

          <Box
            flex
            direction={isMobileWidth ? "column" : "row"}
            gap="1rem"
            align="center"
            margin={{ top: "1.5rem" }}
          >
            <Text
              style={{
                lineHeight: "22px",
                fontStyle: "italic",
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setIsSms(!isSms)}
              size="small"
              color="black"
            >
              {isSms ? "Авторизация по email" : "Авторизация по смс"}
            </Text>
            <Text
              style={{
                lineHeight: "22px",
                fontStyle: "italic",
                color: "black",
              }}
              size="small"
              color="black"
            >
              Нет аккаунта?
            </Text>
            <LinkWrapper as={as} to={routes.auth.path}>
              <Text
                style={{
                  lineHeight: "22px",
                  fontStyle: "italic",
                  color: "black",
                }}
                size="small"
                color="black"
              >
                Регистрация
              </Text>
            </LinkWrapper>
            <Text
              style={{
                lineHeight: "22px",
                fontStyle: "italic",
                color: "black",
                cursor: "pointer",
              }}
              size="small"
              color="black"
              onClick={() => {
                setRecoveryPopup(true);
              }}
              primary
            >
              Забыли пароль?
            </Text>
          </Box>

          {getAuthError.length ? (
            <PopupWithButton
              paragraph={getAuthError}
              subtext={"Ошибка авторизации"}
              closePopup={() => setAuthError("")}
              button="Вернуться к авторизации"
            />
          ) : null}
        </Box>
      </Box>
    </form>
  );
};
