import { StyledBox, StyledOption, StyledSelect } from "./styles";

export const CustomSelect = ({
  options = [],
  disabled,
  onChange = () => {},
  ...args
}) => {
  return (
    <StyledBox>
      <StyledSelect onChange={onChange} disabled={disabled} {...args}>
        {options.map((el) => {
          return (
            <StyledOption
              style={{ color: el?.count <= 0 ? "#4a4b4c" : "#000000" }}
              key={el.value}
              value={el.value}
            >
              {el.label}
            </StyledOption>
          );
        })}
      </StyledSelect>
    </StyledBox>
  );
};
