import coverImg from "../../images/webp/cover6.webp";
import { useDataStore } from "../../hooks/useDataStore";
import { Container } from "../../components/Container";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Image,
  Paragraph,
  Text,
} from "grommet";
import styled from "styled-components";
import { LicensePolicyPopup } from "../../components/Popups/LicensePolicyPopup";
import { LicensePopup } from "../../components/Popups/LicensePopup";
import { useEffect, useState } from "react";
import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { observer } from "mobx-react";
import { DefaultButton } from "../../components/DefaultButton";
import { DocumentItem } from "../../components/DocumentItem";
import { times } from "../../utils/date";
import { ReklamaPopup } from "../../components/Popups/ReklamaPopup";
import styles from "./license.module.scss";
import renderHTML from "react-render-html";
import { REACT_APP_API } from "../../api";
import { useParams } from "react-router-dom";

const LicenseCard = styled(Card)`
  padding: 55px 32px;
  min-height: 700px;
  background-color: #f8f8f8;
  border-radius: 24px;
  box-shadow: none;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
`;

export const License = observer(() => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [licensePopup, setLicensePopup] = useState(false);
  const [license, setLicense] = useState(null);
  const [isClickText, setIsClickText] = useState(false);
  const [partItem, setPartItem] = useState(null);

  const { public_id } = useParams();

  const LICENCE_DOCUMENT_ID = "be70abbd0d699e6091425a467e1797d6cac11169";
  const {
    licensesList,
    loadLicense,
    documents,
    loadDocument,
    licenseInfo,
    getLicenseInfo,
    getBanners,
    getPartnersBanners,
  } = useDataStore();
  const sponsors = getBanners("sponsors");

  const handleClickText = (e, item) => {
    e.preventDefault();
    setIsClickText(true);
    setPartItem(item);
  };

  const handlePopupLicence = (license) => {
    setLicense(license);
    setLicensePopup((prev) => !prev);
  };
  useEffect(() => {
    loadLicense();
    loadDocument({ id: LICENCE_DOCUMENT_ID });
    getLicenseInfo();
    if (!sponsors.length) {
      getPartnersBanners();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (public_id && licensesList.length > 0) {
      const license = licensesList.find((el) => el?.public_id === public_id);
      if (license) {
        setLicense(license);
        setLicensePopup((prev) => !prev);
      }
    }
  }, [public_id, licensesList]);

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <Grid
          columns={{ count: "fit", size: "348px" }}
          gap={"24px"}
          margin={{ bottom: "80px" }}
        >
          {licensesList.map((el) => (
            <LicenseCard key={el.id}>
              <CardHeader
                direction={"column"}
                align={"start"}
                gap={"0"}
                margin={{ bottom: "72px" }}
              >
                <Heading
                  style={{
                    fontSize: "42px",
                    textTransform: "uppercase",
                    marginBottom: "0",
                  }}
                  color={"#000"}
                >
                  {el.name}
                </Heading>
                <Text
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: "500",
                  }}
                  color={"#333"}
                >
                  {el.title}
                </Text>
              </CardHeader>

              <CardBody>
                <Paragraph style={{ fontWeight: "500", marginBottom: "16px" }}>
                  {el.subtitle}
                </Paragraph>
                <Paragraph size={"small"}>{el.description}</Paragraph>
              </CardBody>

              <CardFooter direction={"column"} align={"start"} gap={"0"}>
                <Text
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    marginBottom: "16px",
                  }}
                  size={"16px"}
                  color={"#333"}
                >
                  Цена:
                </Text>
                <Text
                  style={{ fontWeight: "900", marginBottom: "16px" }}
                  size={"42px"}
                  color={"#333"}
                >
                  {el.price} &#8381;
                </Text>
                <Text
                  style={{ marginBottom: "32px" }}
                  size={"small"}
                  color={"rgba(0, 0, 0, 0.3)"}
                >
                  {el.footnote && el.footnote}
                </Text>
                <DefaultButton
                  onClick={() => handlePopupLicence(el)}
                  label={"Оформить лицензию"}
                  style={{
                    padding: "10px",
                    width: "100%",
                    textAlign: "center",
                    rel: "noopener noreferrer",
                  }}
                />
              </CardFooter>
            </LicenseCard>
          ))}
        </Grid>

        {licenseInfo && (
          <Paragraph
            fill
            style={{
              lineHeight: "1.5",
              whiteSpace: "pre-line",
              marginBottom: "3rem",
            }}
          >
            {renderHTML(licenseInfo)}
          </Paragraph>
        )}

        <StyledBox>
          {documents.map((el) => (
            <DocumentItem
              key={el.public_id}
              title={el.title}
              date={times.getNumberFullDate(el.created_date)}
              link={el.path}
            />
          ))}
        </StyledBox>

        <Heading
          level={"2"}
          color={"#333"}
          margin={{ bottom: "large" }}
          style={{ textTransform: "uppercase" }}
        >
          Пользуйтесь привилегиями
        </Heading>
        <Grid
          columns={{ count: "fit", size: "250px" }}
          gap={"24px"}
          margin={{ bottom: "80px" }}
        >
          {sponsors.length
            ? sponsors.map((el) => (
                <Card
                  key={el.id}
                  style={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "24px",
                    boxShadow: "none",
                    maxWidth: "450px",
                    position: "relative",
                  }}
                  pad={"32px"}
                >
                  {el?.reklama && !isClickText && (
                    <span
                      onClick={(e) => handleClickText(e, el)}
                      className={styles.textSponsors}
                    >
                      Реклама
                    </span>
                  )}
                  {el.picture_main && (
                    <Image
                      style={{ marginBottom: "10px" }}
                      src={`${REACT_APP_API}${el.picture_main}`}
                      width={"180"}
                    />
                  )}
                  <Heading
                    level={3}
                    size={"16px"}
                    style={{ fontWeight: "500px", marginBottom: "16px" }}
                  >
                    {el.title}
                  </Heading>
                  <Paragraph
                    style={{
                      fontSize: "12px",
                      marginBottom: "16px",
                      lineHeight: "1.2",
                    }}
                    color={"#333"}
                  >
                    {el.description}
                  </Paragraph>
                  <Box
                    style={{
                      marginTop: "auto",
                      marginBottom: "32px",
                      minHeight: "65px",
                    }}
                  >
                    <Text
                      style={{ textTransform: "uppercase", fontWeight: "700" }}
                      color={"brand"}
                    >
                      {el.subtitle}
                    </Text>
                    <Text size={"12px"}>{el.footnote}</Text>
                  </Box>
                  <DefaultButton
                    href={el.link}
                    label={"На сайт"}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      rel: "noopener noreferrer",
                    }}
                    target={"_blank"}
                  />
                </Card>
              ))
            : null}
        </Grid>
      </Container>

      {isOpenPopup && <LicensePolicyPopup statePopupHandler={setOpenPopup} />}
      {licensePopup && (
        <LicensePopup closePopup={handlePopupLicence} license={license} />
      )}
      {isClickText && partItem && (
        <ReklamaPopup
          closeHandler={() => setIsClickText(false)}
          item={partItem}
          styles={styles}
        />
      )}
      {/*     {isInfoPopup && (
        <PopupInfoBasket closePopup={() => setIsInfoPopup(false)} />
      )} */}
    </>
  );
});
