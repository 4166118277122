import { Subscribe } from "../../components/Subscribe";
import { Container } from "../../components/Container";
import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import coverImg from "../../images/webp/pageCalendar.1f429a5b.webp";
import { NewsMainSection } from "../../components/NewsMainSection";
import { NewsSubSection } from "../../components/NewsSubSection";
import { useDataStore } from "../../hooks/useDataStore";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box } from "grommet";
import { REACT_APP_API } from "../../api";
import { Link } from "react-router-dom";
import { DefaultButton } from "../../components/DefaultButton";

export const NewsList = observer(() => {
  const { newsList, loadNewsPreview } = useDataStore();

  const [isHidden, setIsHidden] = useState(false);

  const handleClick = () => {
    loadNewsPreview(40);
    setIsHidden(true);
  };

  useEffect(() => {
    loadNewsPreview(8);
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <Box gap="medium" margin={{ bottom: "100px" }}>
          <Box flex direction="column" gap="24px">
            <NewsMainSection
              newsList={newsList}
              app_api={REACT_APP_API}
              as={Link}
            />
            <NewsSubSection
              newsList={newsList}
              app_api={REACT_APP_API}
              as={Link}
            />
          </Box>
        </Box>
        <DefaultButton
          onClick={handleClick}
          style={{
            textAlign: "center",
            display: `${isHidden && "none"}`,
            height: "44px",
            marginBottom: "2rem",
          }}
          fill
        >
          Больше новостей
        </DefaultButton>
        <Subscribe />
      </Container>
    </div>
  );
});
