import coverImg from "../../images/webp/cover4.webp";
import { useUserStore } from "../../hooks/useUserStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Container } from "../../components/Container";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";
import { SignUpForm } from "../../components/Forms/SignUpForm";

const Auth = observer(() => {
  const {
    isSendedMail,
    authError,
    setAuthError,
    setSendMail,
    registration,
    setUserPhoneStatus,
    setUserCodeStatus,
    getRegistrationSmsCode,
    authPhoneStatus,
    authCodeStatus,
    registrationSms,
  } = useUserStore();

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <SignUpForm
          as={Link}
          getIsSendedMail={isSendedMail}
          getAuthError={authError}
          setAuthError={setAuthError}
          setSendMail={setSendMail}
          registration={registration}
          setUserPhoneStatus={setUserPhoneStatus}
          setUserCodeStatus={setUserCodeStatus}
          getRegistrationSmsCode={getRegistrationSmsCode}
          registrationSms={registrationSms}
          authPhoneStatus={authPhoneStatus}
          authCodeStatus={authCodeStatus}
        />
      </Container>
    </>
  );
});

export default Auth;
