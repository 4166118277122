import * as yup from "yup";

export function makeValidationSchema(setChangePhoneStatus) {
  return yup.object().shape({
    phone: yup
      .string()
      .test("isValidPhone", "Введите корректный номер телефона", (value) => {
        setChangePhoneStatus("");
        if (!value || value.length !== 12) return false;
        if (value && value.length === 12) return true;
      }),
  });
}
