import coverImg from "../../images/webp/cover4.webp";
import { Container } from "../../components/Container";
import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { RegionsTriathlonTab } from "../../components/RegionsTabs";

export const Regions = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <RegionsTriathlonTab />
      </Container>
    </>
  );
};
