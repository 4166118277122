import React from "react";
import { Box, Button, Layer, Paragraph } from "grommet";
import { IconSvg } from "../../IconSvg";

export const PopupWithButton = ({
  button,
  subtext,
  heading,
  closePopup,
  paragraph,
}) => {
  return (
    <div>
      <Layer
        onClickOutside={closePopup}
        onEsc={closePopup}
        full={true}
        background="transparent"
        animation="fadeIn"
        position="center"
      >
        <Box
          fill
          background="rgba(196, 196, 196, 1)"
          align="center"
          justify="center"
          style={{ position: "relative", visibility: "visible" }}
          overflow="auto"
        >
          <Box
            flex
            width="694px"
            background={{ color: "site-colors-1" }}
            round="20px"
            style={{ position: "absolute" }}
            pad="10px 40px 56px 40px"
            gap="24px"
          >
            <Box width="22px" alignSelf="end">
              <Button
                icon={<IconSvg src="darkCloseIcon" />}
                onClick={closePopup}
                alignSelf="center"
              ></Button>
            </Box>
            <Box align="center" color="black" gap="16px">
              <Paragraph size="0.75rem" fill>
                {heading}
              </Paragraph>
              <Paragraph
                size="medium"
                style={{
                  fontWeight: "700",
                }}
              >
                {subtext}
              </Paragraph>

              <Paragraph
                size="1rem"
                style={{
                  lineHeight: "24px",
                  textAlign: "center",
                  paddingTop: "16px",
                }}
              >
                {paragraph}
              </Paragraph>
            </Box>
            <Box alignSelf="center">
              <Button
                href=""
                style={{
                  backgroundColor: "black",
                  border: "none",
                  padding: "6px 32px",
                  borderRadius: "30px",
                }}
                onClick={closePopup}
              >
                <Paragraph
                  size="0.75rem"
                  style={{
                    textAlign: "center",
                    color: "white",
                    textTransform: "uppercase",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                  }}
                >
                  {button}
                </Paragraph>
              </Button>
            </Box>
          </Box>
        </Box>
      </Layer>
    </div>
  );
};
