import { observer } from "mobx-react";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Container } from "../../components/Container";
import { Banner } from "../../components/Banner";
import { Heading, Paragraph } from "grommet";
import { useDataStore } from "../../hooks/useDataStore";
import { useEffect } from "react";
import renderHTML from "react-render-html";

export const Judgment = observer(() => {
  const { judgment, loadJudgmentData } = useDataStore();

  useEffect(() => {
    loadJudgmentData();
  }, []);

  return (
    <>
      <Banner cover={judgment?.banners?.main} />
      <Container>
        <Breadcrumbs />
        <Heading level={2} margin={{ bottom: "medium" }}>
          {judgment?.subtitle}
        </Heading>

        <Paragraph
          fill
          style={{
            lineHeight: "1.5",
            /*    whiteSpace: "pre-line", */
            marginBottom: "40px",
          }}
        >
          {judgment?.text && renderHTML(judgment?.text)}
        </Paragraph>
      </Container>
    </>
  );
});
