import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Heading,
  Paragraph,
  ResponsiveContext,
} from "grommet";
import moment from "moment";
import { countries } from "../../mocks/countries";
import {
  category,
  distance,
  event_subtype,
  event_type_filter,
  status,
} from "../../utils/const";
import { useLocation } from "react-router-dom";
import { routes } from "../../const/routes";
import { CalendarItemMore } from "../Popups/CalendarItemMore";
import { formatEventDate, getEventCategoryName } from "../../utils/utils";
import { DefaultButton } from "../DefaultButton";

export const CalendarEvents = ({ events, selectedMonth }) => {
  const [popupMore, setPopupMore] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const location = useLocation();
  const calendarRoute = routes.calendar.path;
  const currentUrl = location.pathname.slice(0, calendarRoute.length);
  const sizes = ["small", "tablet"];
  const date = selectedMonth.value;

  let activeEvents = events;

  if (date) {
    activeEvents = events.filter((event) => {
      const eventStart = moment(event.start_date, "YYYY-MM-DD");

      return eventStart.isSame(date, "M");
    });
  }

  const handleOpenPopupMore = (item) => {
    setActiveEvent(item);
    setPopupMore(true);
  };

  return (
    <div>
      <ResponsiveContext.Consumer>
        {(size) =>
          currentUrl === calendarRoute ? (
            <Grid gap={"medium"}>
              {activeEvents.map((event) => (
                <Box
                  direction={!sizes.includes(size) ? "row" : "column"}
                  gap="small"
                  round={"20px"}
                  background={{ color: "#f8f8f8" }}
                  pad={{ vertical: "24px", horizontal: "30px" }}
                >
                  <Box width={{ min: "50px" }}>
                    <div
                      style={{
                        padding: "2px",
                        width: "49px",
                        height: "49px",
                        border: "1px solid #dadada",
                        borderRadius: "50%",
                      }}
                    >
                      <Avatar src={countries[event.country]} size="43px" />
                    </div>
                  </Box>
                  <Box>
                    <Paragraph
                      size="small"
                      style={{ fontWeight: "bolder", maxWidth: "100%" }}
                    >
                      {formatEventDate(event)}
                    </Paragraph>
                    <Paragraph
                      size="small"
                      style={{
                        lineHeight: "16px",
                        fontWeight: "bolder",
                        maxWidth: "100%",
                      }}
                    >
                      {event.title}
                    </Paragraph>
                    <Paragraph
                      size="small"
                      style={{ fontWeight: "bolder", maxWidth: "100%" }}
                    >
                      {event.place}
                    </Paragraph>
                    <Paragraph
                      size="small"
                      style={{ lineHeight: "16px", maxWidth: "100%" }}
                      margin={{ bottom: "small" }}
                    >
                      {event.desc}
                    </Paragraph>
                    <Paragraph
                      size="small"
                      style={{ lineHeight: "16px", maxWidth: "100%" }}
                    >
                      {getEventCategoryName(event.status, status)}{" "}
                      {getEventCategoryName(event.event_subtype, event_subtype)}{" "}
                      {getEventCategoryName(event.distance, distance)}{" "}
                      {getEventCategoryName(event.category, category)}{" "}
                      {getEventCategoryName(
                        event.event_type,
                        event_type_filter
                      )}
                    </Paragraph>
                  </Box>
                  <Box
                    width={{ min: "222px", max: "222px" }}
                    justify="end"
                    margin={{ left: !sizes.includes(size) ? "auto" : "" }}
                  >
                    {/*{calendarLink(event.link)}*/}
                    <DefaultButton
                      size={"medium"}
                      label="Подробнее"
                      onClick={() => handleOpenPopupMore(event)}
                    />
                  </Box>
                </Box>
              ))}
            </Grid>
          ) : (
            <Grid
              columns={{ count: "fill", size: "330px" }}
              justify={"center"}
              gap={"medium"}
            >
              {activeEvents.map((event) => (
                <Box
                  width={"330px"}
                  height={"350px"}
                  round={"20px"}
                  background={{ color: "#f8f8f8" }}
                  margin={{ bottom: "medium" }}
                >
                  <Box direction={"row"}>
                    <Box flex direction="row" justify="between" color="light">
                      <Box pad={{ top: "32px", left: "medium" }}>
                        <Heading
                          level={4}
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {event.date && event.date !== ""
                            ? event.date
                            : formatEventDate(event, "DD MMMM YYYY")}
                        </Heading>
                        <Paragraph
                          size="small"
                          style={{ fontWeight: "bolder" }}
                        >
                          {event.place}
                        </Paragraph>
                      </Box>
                    </Box>

                    <Box pad={"13px"}>
                      <Avatar src={countries[event.country]} size="43px" />
                    </Box>
                  </Box>
                  <Box
                    flex
                    gap={"xlarge"}
                    pad={{ vertical: "32px", horizontal: "medium" }}
                    color="light"
                  >
                    <Box flex justify={"end"}>
                      <Box gap={"xxsmall"}>
                        <Heading level={4} size={"small"}>
                          {event.title}
                        </Heading>
                        <Paragraph size="small" style={{ lineHeight: "16px" }}>
                          {event.desc}
                        </Paragraph>
                      </Box>
                    </Box>
                    {/*{calendarLink(event.link)}*/}
                    <DefaultButton
                      size={"medium"}
                      label="Подробнее"
                      onClick={() => handleOpenPopupMore(event)}
                    />
                  </Box>
                </Box>
              ))}
            </Grid>
          )
        }
      </ResponsiveContext.Consumer>

      <CalendarItemMore
        isShow={popupMore}
        onClosePopup={setPopupMore}
        event={activeEvent}
      />
    </div>
  );
};
