import React from "react";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;

  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
`;

export const InfoImage = ({
  image,
  paragraph,
  heading,
  publication_date,
  link,
  as,
}) => {
  return (
    <>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            width={{ max: "684px", min: "auto" }}
            height={{ min: size === "small" ? "220px" : "464px" }}
            round="20px"
            background={{
              image: `linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8)), url(${image})`,
              size: `cover`,
            }}
          >
            <Box flex pad="large">
              <Paragraph size="0.75rem" color="white">
                {publication_date}
              </Paragraph>
              <Box flex justify="end" gap="small">
                <StyledLink
                  as={as}
                  to={link}
                  style={{ textDecoration: "none" }}
                >
                  <Heading level={3} color="white">
                    {heading}
                  </Heading>
                </StyledLink>

                <Paragraph size="1rem" color="white" fill>
                  {paragraph}
                </Paragraph>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </>
  );
};
