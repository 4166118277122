import React from "react";
import coverImg from "../../images/webp/cover4.webp";
import { Container } from "../../components/Container";
import { Partners } from "../../components/Partners";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";
import { Protocols } from "../../components/Protocols";

export const Results = () => {
  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <Protocols />
        {/*   <Partners /> */}
      </Container>
    </>
  );
};
