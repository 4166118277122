import React, { useEffect, useState } from "react";
import { Banner } from "../../components/Banner";
import coverImg from "../../images/webp/cover4.webp";
import { Container } from "../../components/Container";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { AthleteCards } from "../../components/AthleteCards";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useMembersStore } from "../../hooks/useMembersStore";

export const Members = observer(() => {
  const { publicId } = useParams();
  const [breadcrumbsLabel, setBreadcrumbsLabel] = useState("");

  const { members, membersGroup, loadMembers, loadMembersGroup } =
    useMembersStore();

  useEffect(() => {
    if (publicId) {
      loadMembers(publicId);
    }
    // eslint-disable-next-line
  }, [publicId]);

  useEffect(() => {
    if (membersGroup?.length === 0) {
      loadMembersGroup();
    }

    if (membersGroup?.length > 0) {
      const foundGroup = membersGroup.find(
        (item) => item.public_id === publicId
      );
      setBreadcrumbsLabel(foundGroup.name);
    }
    // eslint-disable-next-line
  }, [membersGroup]);

  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs label={breadcrumbsLabel} />
        <AthleteCards athletesArr={members} />
      </Container>
    </>
  );
});
