import { Anchor, Box, ResponsiveContext } from "grommet";
import { Calendar } from "../../components/Calendar";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useRegionsStore } from "../../hooks/useRegionsStore";
import { useContext, useEffect } from "react";
import { isMobileTabletResponsive } from "../../utils/utils";
import ResultCard from "./ResultCard/ResultCard";

const CustomTbody = styled.tbody`
  color: #313131;
  font-size: 1rem;

  & th {
    padding: 20px;
    width: 100%;
    text-align: left;
    border-left: 2px solid #dadde0;
    background-color: #e8eaec;
  }

  & th:first-child {
    max-width: 70px;
    color: #777777;
    font-weight: 400;
    text-align: center;
    border-left: none;
  }

  & tr {
    display: flex;
    border-top: 2px solid #e8eaec;
  }

  & tr:first-child {
    border-top: none;
  }

  & td {
    width: 100%;
    padding: 20px;
    word-break: break-word;
    background-color: #f8f8f8;
    border-left: 2px solid #e8eaec;
  }

  & td:first-child {
    max-width: 70px;
    color: #777777;
    text-align: center;
    border-left: none;
  }

  & td:last-child,
  & th:last-child {
    min-width: 390px;
  }

  & td:nth-child(2),
  & th:nth-child(2) {
    max-width: 200px;
  }

  & td:nth-child(3),
  & th:nth-child(3) {
    max-width: 250px;
  }

  & td:nth-child(4),
  & th:nth-child(4) {
    max-width: 320px;
  }

  & tr:first-child th:first-child {
    border-top-left-radius: 24px;
  }

  & tr:first-child th:last-child {
    border-top-right-radius: 24px;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 24px;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 24px;
  }
`;

export const RegionContentTab = observer(() => {
  const { regions, getAllRegions } = useRegionsStore();
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  useEffect(() => {
    if (regions.length === 0) getAllRegions();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        margin={{ bottom: "large" }}
        style={{ overflow: isMobileWidth ? "unset" : "auto" }}
      >
        {!isMobileWidth ? (
          <table>
            <CustomTbody>
              <tr>
                <th>№</th>
                <th>Субъект РФ</th>
                <th>ФИО руководителя</th>
                <th>
                  Контактные данные
                  <br />
                  (e-mail, телефон)
                </th>
                <th>Интернет-сайт, социальные сети</th>
              </tr>

              {regions.map((region, index) => (
                <tr key={region.id}>
                  <td>{index + 1}</td>
                  <td>{region.name}</td>
                  <td>{region.person}</td>
                  <td>
                    <p style={{ paddingBottom: "0.675rem" }}>
                      <Anchor
                        href={`tel:${region.phone}`}
                        style={{ fontWeight: "normal" }}
                        color={"#313131"}
                      >
                        {region.phone}
                      </Anchor>
                    </p>
                    <p>
                      <Anchor
                        href={`mailto:${region.email}`}
                        style={{ fontWeight: "normal" }}
                        color={"#313131"}
                      >
                        {region.email}
                      </Anchor>
                    </p>
                  </td>
                  <td>
                    <Anchor
                      href={region.link}
                      label={region.link}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    />
                  </td>
                </tr>
              ))}
            </CustomTbody>
          </table>
        ) : (
          <>
            {" "}
            {regions.map((region, index) => (
              <ResultCard region={region} index={index} key={index} />
            ))}
          </>
        )}
      </Box>

      <Calendar event_type="all" />
    </>
  );
});
