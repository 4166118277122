export const services = {
  dataStore: Symbol.for("dataStore"),
  api: Symbol.for("api"),
  olympicStore: Symbol.for("olympicStore"),
  regionsData: Symbol.for("regionsData"),
  paymentStore: Symbol.for("paymentStore"),
  profileStore: Symbol.for("profileStore"),
  userStore: Symbol.for("userStore"),
  membersStore: Symbol.for("membersStore"),
  additionalApi: Symbol.for("additionalApi"),
  basketStore: Symbol.for("basketStore"),
  ticketsStore: Symbol.for("ticketsStore"),
  subscribeStore: Symbol.for("subscribeStore"),
};

export const iocNames = services;
