import * as yup from "yup";

export function makeValidationSchema() {
  return yup.object().shape({
    password: yup
      .string()
      .required()
      .test(
        "validNewPassword",
        "Длина пароля должна превышать 4 буквы",
        (value) => {
          if (value && value.length < 4) return false;
          if (value && value.length > 4) return true;
        }
      ),
  });
}
