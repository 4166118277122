import { Box, Heading } from "grommet";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useRegionsStore } from "../../hooks/useRegionsStore";
import { times } from "../../utils/date";
import { DocumentItem } from "../DocumentItem";

export const Documents = observer(() => {
  const { items, amateurDocuments } = useRegionsStore();

  useEffect(() => {
    amateurDocuments();
    // eslint-disable-next-line
  }, []);
  return (
    <Box>
      <Box flex direction="row" justify="between">
        <Heading
          level={"3"}
          margin={{ top: "18px", bottom: "18px" }}
          style={{ textTransform: "uppercase" }}
        >
          Документы
        </Heading>
      </Box>
      {items.map((el) => (
        <Box
          key={el.public_id}
          flex
          direction="column"
          gap="small"
          margin={{ top: "20px" }}
        >
          <DocumentItem
            title={el.title}
            date={times.getNumberFullDate(el.created_date)}
            link={el.path}
          />
        </Box>
      ))}
    </Box>
  );
});
