import { makeAutoObservable, observable } from "mobx";

export class OlympicStore {
  filesData = [];

  setFiles = (files) => {
    this.filesData = files;
  };

  sendFormData = async (
    formState,
    setDisable,
    setShowPopup,
    setFormState,
    setErrorPopup
  ) => {
    const { accept_terms, ...formData } = formState;
    if (!accept_terms) return;
    setDisable(true);
    try {
      const response = await this.api.post(
        "/api/olympic/feedback",
        JSON.stringify(formData)
      );
      if (response.status === 200) {
        setShowPopup(true);
        setFormState({ name: "", email: "", text: "" });
      }
    } catch (error) {
      console.log(error);
      setErrorPopup(true);
    }
  };

  sendData = async (formState, form, setErrorPopup, setShowPopup, file) => {
    try {
      const { accept_terms, passport, ...formData } = formState;
      if (!accept_terms) return;

      let response = await this.api.post(
        "/api/olympic/application",
        JSON.stringify({
          ...formData,
          b_date: new Date(formState.b_date).toISOString(),
          passport_valid: new Date(formState.passport_valid).toISOString(),
          height: Number(formState.height),
          weight: Number(formState.weight),
          phone: formState?.phone?.replace(/[(\-)\s]/g, "")?.slice(1),
          emergency_phone: formState?.emergency_phone
            ?.replace(/[(\-)\s]/g, "")
            ?.slice(1),
          file,
        })
      );
      if (response.status === 200) {
        setShowPopup(true);
        form.restart();
      }
    } catch (error) {
      console.log(error);
      setErrorPopup(true);
    }
  };

  get files() {
    return this.filesData;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref });
  }
}
