import styles from "./OptionalCard.module.scss";
import "./OptionalCard.scss";
import { useEffect, useRef, useState } from "react";
import { BASKET_COUNT } from "../../../../const/const";
import { setCountOptions } from "../../../../mocks/calendarOptions";
import { observer } from "mobx-react";
import { useBasketStore } from "../../../../hooks/useBasketStore";
import { getTotalCost } from "../../../../utils/goods";
import {
  StyledBasketSelect,
  StyledBox,
  StyledIconSvg,
  StyledParagraph,
} from "../../../../pages/Ticket/styles";
import { CheckBox } from "grommet";

const OptionalCard = observer(({ product, id }) => {
  const { removeShop, addShop, goods, currentRemoveGoods } = useBasketStore();

  const [errorSize, setErrorSize] = useState(``);
  const [sizeValue, setSizeValue] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const checkboxRef = useRef();
  const handleSizeResetError = () => setErrorSize(``);

  const getSize = () => {
    if (product.proportions.length === 1) {
      return product.proportions[0]?.name;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setSizeValue(getSize());
    goods.forEach((el) => {
      if (checkboxRef.current.id?.includes(el?.public_id)) {
        setIsChecked(true);
      }
    });
  }, [isChecked]);

  useEffect(() => {
    if (errorSize) {
      setIsChecked(false);
    }
    if (currentRemoveGoods) {
      if (checkboxRef.current.id?.includes(currentRemoveGoods?.public_id)) {
        setIsChecked(false);
        setSizeValue(getSize());
      }
    }
  }, [currentRemoveGoods, errorSize, checkboxRef]);

  const basketEventGoodsHandler = (e) => {
    if (errorSize) {
      setIsChecked(false);
    } else {
      setIsChecked(e.target.checked);
    }
    if (!e.target.checked) {
      setSizeValue(getSize());
    }
    const value = e.target.checked;
    let count = 0;
    let totalCost;
    const selector = "shop";

    if (product.proportions.length > 0) {
      if (value) {
        if (sizeValue) {
          count =
            count >= BASKET_COUNT.max_count
              ? BASKET_COUNT.max_count
              : count + 1;

          totalCost = getTotalCost(
            product.price,
            count,
            product.insurance && product.insurance_cost
          );
          const newProduct = {
            ...product,
            size: product.proportions.length > 0 ? sizeValue : null,
            promocode: null,
            logo: product.picture,
            totalCost,
            count,
            selector,
          };

          addShop(newProduct);
        } else {
          setErrorSize("Выберите размер!");
        }
      } else {
        const count = 0;
        totalCost = getTotalCost(
          product.price,
          count,
          product.insurance && product.insurance_cost
        );

        const newProduct = {
          ...product,
          totalCost,
          count,
        };

        removeShop(newProduct);
      }
    } else {
      if (value) {
        count =
          count >= BASKET_COUNT.max_count ? BASKET_COUNT.max_count : count + 1;

        totalCost = getTotalCost(
          product.price,
          count,
          product.insurance && product.insurance_cost
        );
        const newProduct = {
          ...product,
          size: product.proportions.length > 0 ? sizeValue : null,
          promocode: null,
          logo: product.picture,
          totalCost,
          count,
          selector,
        };

        addShop(newProduct);
      } else {
        const count = 0;
        totalCost = getTotalCost(
          product.price,
          count,
          product.insurance && product.insurance_cost
        );

        const newProduct = {
          ...product,
          totalCost,
          count,
        };

        removeShop(newProduct);
      }
    }
  };

  return (
    <div className={styles.optionalCard}>
      <div className={styles.imageWrap}>
        <img className={styles.image} src={product.picture} alt="Товар" />
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.insideItem}>
          <div className={styles.content}>
            <b className={styles.title}>{product.title}</b>
            {product.proportions.length > 0 && (
              <div>
                <StyledParagraph error={errorSize}>
                  {errorSize ? errorSize : "Размер футболки"}
                </StyledParagraph>
                <StyledBox error={errorSize}>
                  <StyledIconSvg error={errorSize} src="tShirt" />

                  <StyledBasketSelect
                    options={setCountOptions(product.proportions)}
                    placeholder="Выберите ваш размер"
                    valueKey={{ key: "value", reduce: true }}
                    onChange={(event) => {
                      setSizeValue(event.target.value);
                    }}
                    value={sizeValue}
                    onFocus={handleSizeResetError}
                  />
                </StyledBox>
              </div>
            )}
          </div>
          <div className={styles.count}>
            <div className={styles.checkbox_group}>
              <label className={styles.label} htmlFor={id}>
                Добавить
              </label>
              <CheckBox
                id={id}
                checked={isChecked}
                onChange={basketEventGoodsHandler}
                name="checkbox"
                ref={checkboxRef}
              />
            </div>
          </div>
          <div className={styles.costWrap}>
            <p className={styles.cost}>{product.price} &#8381;</p>
          </div>
        </div>
        <p className={`${styles.text} ${styles.description}`}>
          {product.description}
        </p>
      </div>
    </div>
  );
});

export default OptionalCard;
