export const NotSupport = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2 style={{ color: "black" }}>Ваш браузер не поддерживается</h2>
    </div>
  );
};
