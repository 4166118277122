import React, { useState, useEffect } from "react";
import {
  StyledButton,
  StyledButtonDefault,
  StyledDeleteButton,
  StyledErrorText,
  StyledInput,
  StyledInsideItem,
  StyledInsideLocation,
  StyledInsideTitle,
} from "./styles";
import styles from "../../Popups/LicensePopup/License.module.scss";
import { convertBase64 } from "../../../utils/convertBase64";
import { searchSize, updateLocalStorage } from "../../../utils/utils";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { BASKET_COUNT } from "../../../const/const";
import { getTotalCost, setDiscountPrice } from "../../../utils/goods";
import { IconSvg } from "../../IconSvg";
import { LicensePopup } from "../../Popups/LicensePopup";
import { useDataStore } from "../../../hooks/useDataStore";
import { TextInput } from "../../Inputs/TextInput";
import { InputPhone } from "../../Inputs/PhoneInput";
import {
  StyledBox,
  StyledDiv,
  StyledErrorMessage,
  StyledIconSvg,
  StyledParagraph,
  StyledRadioButtons,
  StyledBasketSelect,
} from "../../../pages/Ticket/styles";
import { setCountOptions } from "../../../mocks/calendarOptions";
import { DatePicker } from "../../Inputs/DatePicker";
import { RadioButton } from "grommet";
import {
  getDate,
  useValidationDate,
} from "../../../customHooks/useValidationDate";
import { toJS } from "mobx";
import OptionalCard from "./OptionalCard/OptionalCard";

export const Goods = observer(
  ({
    item,
    usedTicket,
    removeShop,
    isVipUser,
    updateGoods,
    promoCodeError,
    sendPromoCode,
    promoCodes,
    removePromoCode,
    checkTicketsBeforePaymentByFormatId,
    ticketFormatsInBasket,
    removeCurrentGoods,
    handleChange = () => {},
  }) => {
    const userObj = JSON.parse(localStorage.getItem("userObj"));
    const formatCluster = item.clusters ?? [];
    const isPreregistration = item.preregistration ?? null;

    const {
      getSizesByID,
      loadSizeById,
      clearSize,
      licensesList,
      loadLicense,
      getEventGoodsById,
      loadEventGoods,
    } = useDataStore();

    const sizes = getSizesByID(item?.format_public_id)?.sizes ?? [];
    const isSize = sizes.length > 0;
    const isCluster = formatCluster.length > 0;
    const userName = item?.info?.first_name || userObj?.name || "";
    const userLastName = item?.info?.last_name || userObj?.last_name || "";
    const userBirthDate =
      item?.info?.birth_date ||
      (userObj?.birth_date && getDate(userObj.birth_date * 1000)) ||
      "";
    const userSecondName =
      item?.info?.second_name || userObj?.second_name || "";

    const [promoCodeApplied, setPromoCodeApplied] = useState(false);
    const [showLicensePopup, setShowLicensePopup] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState(null);
    const [newLicensePopup, setNewLicensePopup] = useState(false);
    const [showLicenseUpdatePopup, setShowLicenseUpdatePopup] = useState(false);
    const [license, setLicense] = useState(item);
    const [isSecondName, setIsSecondName] = useState(item?.insurance);
    const [gender, setGender] = useState(
      item?.info?.gender || userObj?.gender || `none`
    );
    const [name, setName] = useState(userName);
    const [lastName, setLastName] = useState(userLastName);
    const [secondName, setSecondName] = useState(userSecondName);
    const [size, setSize] = useState(null);
    const [cluster, setCluster] = useState(null);
    const [file, setFile] = useState(null);
    const [document, setDocument] = useState(null);
    const [isLoadFile, setIsLoadFile] = useState(false);

    const [phone, setPhone] = useState(
      (item?.info?.phone && `+${item.info.phone}`) ||
        (userObj?.phone && `+${userObj?.phone}`)
    );
    const [formErrors, setFormErrors] = useState({
      date: "",
      phone: "",
      gender: "",
      name: "",
      lastName: "",
    });
    const [valueDate, handleDateChange, date, isValidDate, setValueDate] =
      useValidationDate(userBirthDate);

    const eventGoods = getEventGoodsById(item?.event_city?.public_id) ?? null;
    const goods =
      localStorage.getItem("++") &&
      JSON.parse(localStorage.getItem("basketGoods"));

    const defaultSize = item?.info?.item_size
      ? item.info.item_size
      : searchSize("Футболка не выбрана", sizes)?.label;

    const defaultPhone =
      (item?.info?.phone && `+${item.info.phone}`) ||
      (userObj?.phone && `+${userObj?.phone}`);

    const handleSelectLicense = (event) => {
      if (JSON.parse(event.target.value)) {
        const selectedLicense = JSON.parse(event.target.value);
        const findGoods =
          goods &&
          goods.find((el) => el.public_id === selectedLicense.public_id);

        if (findGoods) {
          const license = {
            ...findGoods,
            format_start_time: item.start_time,
          };
          setLicense(license);
          setShowLicenseUpdatePopup(true);
          setSelectedLicense({});
        } else {
          const license = {
            ...selectedLicense,
            format_start_time: item.start_time,
          };
          setSelectedLicense(license);
          setNewLicensePopup(true);
        }
      }
    };

    useEffect(() => {
      if (item.event_city?.public_id && item.event?.public_id) {
        loadSizeById({
          event_city_public_id: item.event_city.public_id,
          event_public_id: item.event.public_id,
          id: item?.format_public_id,
        });
      }
      return () => clearSize();
    }, []);

    useEffect(() => {
      if (formatCluster.length > 0 && item?.cluster) {
        const defaultCluster = formatCluster.find(
          (el) => el.value === item?.cluster?.name
        );
        setCluster(defaultCluster);
      }
    }, []);

    useEffect(() => {
      if (!eventGoods && item?.event_city?.public_id) {
        loadEventGoods(item?.event_city?.public_id);
      }
    }, []);

    const handleFileChange = async (e) => {
      if (e.target.files[0]) {
        const document = await convertBase64(e.target.files[0]);
        setDocument(document);
      }
      setFile(e.target.files[0]);
      setFormErrors((prev) => ({ ...prev, file: "" }));
    };

    useEffect(() => {
      if (item?.info?.item_size && sizes?.length > 0) {
        const defaultSize = sizes.find((el) => el.size === item.info.item_size);

        setSize(defaultSize?.size);
      } else if (sizes.length > 0) {
        setSize("Футболка не выбрана");
      }
    }, [sizes]);

    useEffect(() => {
      if (formatCluster.length > 0 && cluster) {
        const currentCluster = formatCluster.find(
          (el) => el.value === cluster.value
        );

        if (currentCluster.file) {
          setIsLoadFile(true);
        } else {
          setIsLoadFile(false);
        }
      }
    }, [cluster]);

    useEffect(() => {
      if (item?.cluster?.document) {
        setDocument(item?.cluster?.document);
      }
      if (item?.file) {
        setFile({ name: item?.file });
      }
    }, []);

    useEffect(() => {
      if (isPreregistration) {
        const currentCluster = formatCluster?.find(
          (el) => el?.value === cluster?.value
        );

        if (formatCluster.length > 0) {
          if (!cluster) {
            setFormErrors((prev) => ({
              ...prev,
              cluster: "Выберите кластер!",
            }));
            return;
          } else {
            setFormErrors((prev) => ({ ...prev, cluster: "" }));
          }
          if (!document && currentCluster?.file) {
            setFormErrors((prev) => ({ ...prev, file: "Выберите файл!" }));
            return;
          } else {
            setFormErrors((prev) => ({ ...prev, file: "" }));
          }
        }
        if (!name) {
          setFormErrors((prev) => ({ ...prev, name: "Заполните поле!" }));
        } else {
          setFormErrors((prev) => ({ ...prev, lastName: "" }));
        }
        if (!lastName) {
          setFormErrors((prev) => ({ ...prev, lastName: "Заполните поле!" }));
        } else {
          setFormErrors((prev) => ({ ...prev, lastName: "" }));
        }
        if (!isSecondName) {
          setFormErrors((prev) => ({ ...prev, secondName: "" }));
        }
        if (!isValidDate) {
          setFormErrors((prev) => ({
            ...prev,
            date: "Введите корректную дату!",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, date: "" }));
        }
        if (!phone) {
          setFormErrors((prev) => ({ ...prev, phone: "Заполните поле!" }));
        } else if (phone.length !== 12) {
          setFormErrors((prev) => ({
            ...prev,
            phone: "Введите корректный номер телефона",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, phone: "" }));
        }
        if (gender === "none")
          setFormErrors((prev) => ({
            ...prev,
            gender: "Выберите одно из значений!",
          }));

        if (
          name &&
          lastName &&
          isValidDate &&
          phone &&
          phone.length >= 12 &&
          gender !== `none` &&
          !formErrors.date
        ) {
          let info = {
            first_name: name,
            last_name: lastName,
            phone: phone?.slice(1),
            gender,
            birth_date: date,
          };

          if (secondName) {
            info = {
              ...info,
              second_name: secondName,
            };
          }

          if (
            (size && size !== "Футболка не выбрана") ||
            (item?.info?.item_size && size !== "Футболка не выбрана")
          ) {
            info = {
              ...info,
              item_size: size || item?.info?.item_size,
            };
          }
          let newObj = {
            ...item,
            info,
            eventGoods: eventGoods?.data,
            preReg: "ok",
          };

          if (formatCluster.length > 0 && currentCluster?.file) {
            newObj = {
              ...newObj,
              file: file?.name,
              isClusterFile: cluster?.file,
              cluster: {
                name: cluster && cluster.value,
                document,
              },
            };
          } else if (formatCluster.length > 0 && !currentCluster?.file) {
            newObj = {
              ...newObj,
              cluster: {
                name: cluster && cluster.value,
              },
            };
          }
          updateLocalStorage(`basketGoods`, `add`, newObj);
          updateGoods(newObj);
          handleChange();
        }
      }
    }, [
      name,
      size,
      lastName,
      secondName,
      phone,
      gender,
      valueDate,
      date,
      isSecondName,
      cluster,
      file,
      document,
      isLoadFile,
    ]);

    useEffect(() => {
      if (item.selector === "ticket") {
        //test add/ticket/tracking2023_524305_1
        loadLicense();
      }
      // eslint-disable-next-line
    }, []);

    const ITEM_ID = item.public_id;

    const {
      register,
      handleSubmit,
      clearErrors,
      setError,
      setValue,
      formState: { errors },
    } = useForm({ shouldFocusError: false });

    const deleteGoodsHandler = (item) => {
      if (item.public_id === "18f88686a3ae3f4eebf08cfae807a6c58635ac1f") {
        const goods =
          localStorage.getItem("basketGoods") &&
          JSON.parse(localStorage.getItem("basketGoods"));
        const medal = goods?.find(
          (el) => el.public_id === "190ba3238ce08da60220668278b2f92c68e967e7"
        );
        if (medal) {
          removeShop(medal);
          removePromoCode(medal);
          removeCurrentGoods(medal);
        }
      } else if (item?.eventGoods) {
        const goods =
          localStorage.getItem("basketGoods") &&
          JSON.parse(localStorage.getItem("basketGoods"));
        item?.eventGoods?.forEach((el) => {
          goods.forEach((elem) => {
            if (el?.public_id === elem?.public_id) {
              removeShop(elem);
              removePromoCode(elem);
              removeCurrentGoods(elem);
            }
          });
        });
      }
      removeShop(item);
      removePromoCode(item);
      removeCurrentGoods(item);
    };

    const basketTicketHandler = (evt) => {
      evt.preventDefault();

      const target = evt.target.dataset.ticketBtn;
      let count = item.count;
      const ticketLeft = item.tickets_left?.athlete;

      let min_count = item.buy_limit ? item.buy_limit : BASKET_COUNT.min_count;
      let max_count =
        (item.buy_limit && item.buy_limit) ||
        ticketLeft ||
        BASKET_COUNT.max_count;

      if (item.team_all) {
        min_count =
          item?.fixed_count === false ? BASKET_COUNT.min_count : item.max_count;
        max_count = item.max_count * BASKET_COUNT.max_count;
      }
      let totalCost = item.totalCost;

      if (target === "increment") {
        count = count >= max_count ? max_count : count + min_count;
      } else if (target === "decrement") {
        count = count <= min_count ? min_count : count - min_count;
      }
      totalCost =
        isVipUser && usedTicket === undefined && item.selector === "ticket"
          ? getTotalCost(
              setDiscountPrice(
                item.price,
                userObj.discount,
                userObj.discount_form
              ),
              1,
              item.insurance && item.insurance_cost
            ) +
            getTotalCost(
              item.price,
              count - 1,
              item.insurance && item.insurance_cost
            )
          : getTotalCost(
              item.price,
              count,
              item.insurance && item.insurance_cost
            );

      const newObj = { ...item, totalCost, count };

      updateGoods(newObj);
      updateLocalStorage("basketGoods", "add", newObj);
    };

    const handleSubmitForm = (data) => {
      if (!data[ITEM_ID].length) {
        setError(ITEM_ID, {
          type: "custom",
          message: "Введите промокод",
        });
        return;
      }
      const codeInfo = { code: data[ITEM_ID], ...item };
      sendPromoCode(codeInfo);
    };

    const handleRemoveCode = (event) => {
      event.preventDefault();
      setPromoCodeApplied(false);
      setValue(ITEM_ID, "");
      removePromoCode(item);
    };
    const handleButtonRemoveInsurance = () => {
      setIsSecondName(false);
      const newObj = {
        ...item,
        insurance: false,
        totalCost: getTotalCost(item.price, item.count),
      };
      updateGoods(newObj);
      updateLocalStorage("basketGoods", "add", newObj);
    };
    const handleButtonAddInsurance = () => {
      setIsSecondName(true);
      const newObj = {
        ...item,
        insurance: true,
        totalCost: getTotalCost(item.price, item.count, item.insurance_cost),
      };
      updateGoods(newObj);
      updateLocalStorage("basketGoods", "add", newObj);
    };

    useEffect(() => {
      if (promoCodeError.message && promoCodeError.id === item.public_id) {
        setError(ITEM_ID, {
          type: "custom",
          message: promoCodeError.message,
        });
      }
      //eslint-disable-next-line
    }, [promoCodeError]);

    const handleSelectChange = (e) => {
      const newObj = { ...item, cluster: {}, preReg: "" };
      updateGoods(newObj);
      updateLocalStorage(`basketGoods`, `add`, newObj);
      handleChange();

      setCluster({ value: e.target.value });
    };

    useEffect(() => {
      if (promoCodes.length) {
        setPromoCodeApplied(
          !!promoCodes.find((el) => el.public_id === item.public_id)
        );
      }
      //eslint-disable-next-line
    }, [promoCodes]);

    useEffect(() => {
      if (ticketFormatsInBasket?.length) {
        const format = ticketFormatsInBasket.find(
          (el) => el.public_id === item.public_id
        );

        if (item.selector === "ticket") {
          //loadEventGoods(item.event_public_id)

          if (format && format.public_id !== item.public_id) {
            checkTicketsBeforePaymentByFormatId(item.public_id);
          }
        }
      }
      // eslint-disable-next-line
    }, [item]);

    return (
      <>
        <form
          onSubmit={
            promoCodeApplied ? handleRemoveCode : handleSubmit(handleSubmitForm)
          }
        >
          <li className={styles.item}>
            <div className={styles.logoWrap}>
              <img className={styles.logo} src={item.logo} alt="Гонка Героев" />
            </div>
            <div className={styles.infoBlock}>
              {item.event && item.event.title && (
                <h3 className={styles.infoBlockTitle}>{item.event.title}</h3>
              )}
              <div className={styles.insideItem}>
                <div className={styles.location}>
                  <b>
                    {item.selector === "shop" || "license"
                      ? "Продукт:"
                      : "Локация:"}
                  </b>
                  <p className={styles.text}>
                    {item.selector === "shop" || "license"
                      ? item.title
                      : item.address}
                  </p>
                </div>
                <div className={styles.format}>
                  <b>
                    {item.selector === "shop"
                      ? item.size
                        ? "Размер:"
                        : null
                      : "Формат:"}
                  </b>
                  <p className={styles.text}>
                    {item.selector === "shop" ? item.size : item.title}
                  </p>
                </div>
                <div className={styles.count}>
                  <b>Количество:</b>
                  <div className={`${styles.text} ${styles.countText}`}>
                    <button
                      type="button"
                      data-ticket-btn="increment"
                      className={styles.countBtn}
                      onClick={basketTicketHandler}
                    >
                      +
                    </button>
                    <span>{item.count}</span>
                    <button
                      type="button"
                      data-ticket-btn="decrement"
                      className={styles.countBtn}
                      onClick={basketTicketHandler}
                    >
                      -
                    </button>
                  </div>
                </div>
                <div className={styles.costWrapC}>
                  <div>
                    {" "}
                    <b>Стоимость: &nbsp;</b>
                  </div>
                  <p className={styles.cost}>
                    {Number((item.price * item.count).toFixed(0))} &#8381;
                  </p>
                </div>
              </div>
              {item.insurance_available && (
                <div
                  className={`${styles.insideItem} ${styles.insideItemInsurance}`}
                >
                  <div
                    className={`${styles.location} ${styles.locationInsurance}`}
                  >
                    <b>Дополнительно:</b>
                    <p className={styles.text}>Страхование участника</p>
                  </div>
                  <div className={styles.count}>
                    <b>Количество:</b>

                    <div className={styles.text}>
                      {item.insurance && <span>{item.count}</span>}
                      <button
                        onClick={
                          item.insurance
                            ? handleButtonRemoveInsurance
                            : handleButtonAddInsurance
                        }
                        className={`${
                          item.insurance
                            ? styles.deleteInsuranceBtn
                            : styles.addInsuranceBtn
                        }`}
                      >
                        {item.insurance ? "Удалить" : "Добавить"}
                      </button>
                    </div>
                  </div>

                  <div className={styles.costWrapC}>
                    <p className={styles.cost}>
                      {item.insurance && item.insurance_cost * item.count}
                      {item.insurance && " ₽"}
                    </p>
                  </div>
                </div>
              )}

              {item.selector === "license" && (
                <StyledInsideItem>
                  <StyledInsideLocation>
                    <div>
                      <StyledButtonDefault
                        onClick={() => setShowLicensePopup(true)}
                      >
                        Редактировать
                      </StyledButtonDefault>
                    </div>
                  </StyledInsideLocation>
                </StyledInsideItem>
              )}
              {item.selector === "ticket" && (
                <StyledInsideItem>
                  <StyledInsideLocation>
                    <StyledInsideTitle>Дополнительно:</StyledInsideTitle>
                    <div style={{ marginTop: "5px" }}>
                      <select
                        className={styles.select}
                        name="delivery_city"
                        onChange={handleSelectLicense}
                        value={selectedLicense}
                      >
                        <option selected value="null">
                          Добавить лицензию
                        </option>
                        {licensesList.length &&
                          licensesList.map((el) => (
                            <option
                              key={el.public_id}
                              value={JSON.stringify(el)}
                            >
                              {el?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </StyledInsideLocation>
                </StyledInsideItem>
              )}

              {eventGoods &&
              eventGoods.data.length &&
              item.selector === "ticket"
                ? eventGoods.data.map((product, i) => (
                    <OptionalCard
                      key={`${product.public_id}${i}`}
                      product={product}
                      id={`${eventGoods?.id}${product?.public_id}`}
                    />
                  ))
                : null}
            </div>

            <div className={styles.addBlock}>
              <div
                className={`${styles.promoCodeBtnWrap}
        ${
          promoCodeError && !!promoCodeError.message
            ? styles.promoCodeBtnWrapError
            : ""
        }
        ${promoCodeApplied ? styles.promoCodeBtnWrapCheck : ``}`}
              >
                <div style={{ position: "relative" }}>
                  <StyledInput
                    promoCodeApplied={promoCodeApplied}
                    errors={errors[ITEM_ID]}
                    type="text"
                    {...register(ITEM_ID)}
                    placeholder={"Есть промокод?"}
                    onFocus={() => clearErrors(ITEM_ID)}
                  />
                  <span
                    style={{ position: "absolute", left: "9px", top: "10px" }}
                  >
                    <IconSvg
                      src="promoCode"
                      color={
                        (errors[ITEM_ID] && "#c86363") ||
                        (promoCodeApplied && "rgb(28, 185, 0)")
                      }
                    />
                  </span>

                  {promoCodeApplied && (
                    <span
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    >
                      <IconSvg src="check" stroke="rgb(28, 185, 0)" />
                    </span>
                  )}
                  {errors[ITEM_ID] && (
                    <span
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px",
                      }}
                    >
                      <IconSvg src="warn" />
                    </span>
                  )}
                </div>

                {errors[ITEM_ID] && (
                  <StyledErrorText>{errors[ITEM_ID].message}</StyledErrorText>
                )}
                <StyledButton type="submit">
                  {!promoCodeApplied ? "Применить" : "Изменить"}
                </StyledButton>
              </div>
              <span className={styles.serf}>
                На все наши мероприятия необходимо иметь при себе медицинскую
                справку
              </span>
            </div>
            <StyledDeleteButton onClick={() => deleteGoodsHandler(item)}>
              Удалить
            </StyledDeleteButton>
          </li>
        </form>
        {showLicensePopup && (
          <LicensePopup
            closePopup={() => setShowLicensePopup(false)}
            license={item}
            isEdit
          />
        )}

        {showLicenseUpdatePopup && (
          <LicensePopup
            closePopup={() => setShowLicenseUpdatePopup(false)}
            license={license}
            isEdit
          />
        )}

        {newLicensePopup && (
          <LicensePopup
            closePopup={() => setNewLicensePopup(false)}
            license={selectedLicense}
          />
        )}

        {isPreregistration && item?.preReg !== "later" && (
          <div className={styles.ticketForm}>
            <div style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
              <p className={styles.formText}>
                {" "}
                Необходимо заполнить обязательные данные участника
              </p>
            </div>
            <form>
              <div className={styles.formWrap}>
                <div className={`${styles.row} ${styles.mobileRow}`}>
                  <TextInput
                    error={formErrors.name}
                    handleFocus={() =>
                      setFormErrors((prev) => ({ ...prev, name: "" }))
                    }
                    label="Ваше имя"
                    iconSrc="user"
                    value={name}
                    handleChange={(e) => setName(e.target.value)}
                    placeholder="Ваше имя"
                    defaultValue={userName}
                  />
                  <TextInput
                    error={formErrors.lastName}
                    handleFocus={() =>
                      setFormErrors((prev) => ({ ...prev, lastName: "" }))
                    }
                    label="Ваша Фамилия"
                    iconSrc="user"
                    value={lastName}
                    handleChange={(e) => setLastName(e.target.value)}
                    placeholder="Ваша Фамилия"
                    defaultValue={userLastName}
                  />
                </div>
                <div className={styles.row}>
                  {isSecondName && (
                    <TextInput
                      error={formErrors.secondName}
                      handleFocus={() =>
                        setFormErrors((prev) => ({ ...prev, secondName: "" }))
                      }
                      label="Ваше Отчество"
                      iconSrc="user"
                      handleChange={(e) => setSecondName(e.target.value)}
                      placeholder="Ваше Отчество"
                      defaultValue={userSecondName}
                      value={secondName}
                    />
                  )}
                  <InputPhone
                    country="RU"
                    international={true}
                    withCountryCallingCode={true}
                    error={formErrors.phone}
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    onFocus={() =>
                      setFormErrors((prev) => ({ ...prev, phone: "" }))
                    }
                    defaultValue={`+${defaultPhone}`}
                  />
                </div>
                <div
                  className={styles.row}
                  style={{ marginTop: !sizes.length && !isSecondName && 0 }}
                >
                  {isSize && (
                    <StyledDiv>
                      <StyledParagraph error={formErrors.size}>
                        {formErrors.size ? formErrors.size : "Размер футболки"}
                      </StyledParagraph>
                      <StyledBox error={formErrors.size}>
                        <StyledIconSvg error={formErrors.size} src="tShirt" />

                        <StyledBasketSelect
                          options={setCountOptions(sizes, "size")}
                          placeholder="Выберите ваш размер"
                          valueKey={{ key: "value", reduce: true }}
                          onChange={(event) => {
                            setSize(event.target.value);
                          }}
                          value={size}
                          defaultValue={defaultSize}
                          onFocus={() =>
                            setFormErrors((prev) => ({ ...prev, size: "" }))
                          }
                        />
                      </StyledBox>
                    </StyledDiv>
                  )}
                </div>

                <div className={`${styles.row} ${styles.mobileRow}`}>
                  <DatePicker
                    error={formErrors.date}
                    value={valueDate}
                    onFocus={() =>
                      setFormErrors((prev) => ({ ...prev, birth: "" }))
                    }
                    label="День рождения "
                    id="birthDate"
                    onChange={handleDateChange}
                    placeholder="30.12.2000"
                    defaultValue={userBirthDate}
                  />
                  <div>
                    <StyledParagraph>Ваш пол:</StyledParagraph>
                    {formErrors.gender ? (
                      <StyledErrorMessage>
                        {formErrors.gender}
                      </StyledErrorMessage>
                    ) : null}
                    <StyledRadioButtons>
                      <RadioButton
                        onFocus={() =>
                          setFormErrors((prev) => ({ ...prev, gender: "" }))
                        }
                        name="gender"
                        checked={gender === "male"}
                        label="Мужской"
                        value="male"
                        onChange={(event) => setGender(event.target.value)}
                      />
                      <RadioButton
                        onFocus={() =>
                          setFormErrors((prev) => ({ ...prev, gender: "" }))
                        }
                        name="gender"
                        value="female"
                        checked={gender === "female"}
                        label="Женский"
                        onChange={(event) => setGender(event.target.value)}
                      />
                    </StyledRadioButtons>
                  </div>
                </div>

                <div className={styles.row}>
                  {isCluster && (
                    <StyledDiv error={formErrors.cluster}>
                      <StyledParagraph error={""}>
                        {formErrors.cluster ? formErrors.cluster : "Кластер"}
                      </StyledParagraph>
                      <StyledBasketSelect
                        onFocus={() =>
                          setFormErrors((prev) => ({ ...prev, cluster: "" }))
                        }
                        onChange={handleSelectChange}
                        valueKey={{ key: "value", reduce: true }}
                        labelKey="label"
                        options={formatCluster}
                        placeholder="Выберите ваш кластер"
                        value={cluster?.value}
                      />
                    </StyledDiv>
                  )}
                  {isLoadFile && (
                    <label className={styles.labelFileBasket}>
                      Подтверждение*
                      <span
                        className={`${styles.textFile} ${
                          formErrors.file && styles.labelFileError
                        }`}
                      >
                        Загрузить документ
                        <input
                          className={styles.inputFile}
                          accept="image/pdf, image/jpeg, image/png , image/jpg"
                          type="file"
                          onChange={handleFileChange}
                          name="file"
                          onFocus={() =>
                            setFormErrors((prev) => ({ ...prev, file: "" }))
                          }
                        />
                      </span>
                      {formErrors.file.length ? (
                        <span>{formErrors.file}</span>
                      ) : null}
                      {(file && file.name) || item.fileName ? (
                        <span style={{ maxWidth: "300px", overflow: "hidden" }}>
                          {file?.name || item.fileName}
                        </span>
                      ) : null}
                    </label>
                  )}
                </div>

                <div className={styles.row}>
                  {item.notes && (
                    <ul className={styles.notesWrapper}>
                      {item.notes.map((el, i) => (
                        <li key={i} className={styles.notes}>
                          {el}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
);
