import { useState } from "react";
import {
  Box,
  Card,
  CheckBox,
  Heading,
  Layer,
  Paragraph,
  ResponsiveContext,
} from "grommet";
import { NameSpace } from "../../const/namespace";
import styled from "styled-components";
import { DefaultButton } from "../DefaultButton";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeValidationSchema } from "./makeValidationSchema";
import { TextInput } from "../Inputs/TextInput";
import { useSubscribeStore } from "../../hooks/useSubscribeStore";
import { observer } from "mobx-react";

const CustomHeading = styled(Heading)`
  text-transform: uppercase;
`;

const CustomParagraph = styled(Paragraph)`
  max-width: 448px;
`;

const CustomBtn = styled(DefaultButton)`
  padding: 6px 12px;
  max-width: 406px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Subscribe = observer(() => {
  const validateForm = makeValidationSchema();
  const user = localStorage.userObj && JSON.parse(localStorage.userObj);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedReklama, setIsCheckedReklama] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
    defaultValues: { email: user?.email || "" },
  });

  const { setNewSubscribe, status, setNewStatus } = useSubscribeStore();

  const onSubmit = ({ email }) => setNewSubscribe(email);

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            margin={{ bottom: "42px" }}
            pad={{
              horizontal: size !== NameSpace.desktop1900 ? "medium" : "large",
              vertical: size !== NameSpace.desktop1900 ? "20px" : "38px",
            }}
            background={"light-1"}
            direction={size !== NameSpace.tablet ? "row" : "column"}
            align={size !== NameSpace.desktop1900 ? "start" : "center"}
            elevation={"none"}
            wrap
            gap={[NameSpace.mobile].includes(size) ? "large" : "medium"}
          >
            <Box margin={{ right: "auto" }}>
              <CustomHeading
                level={3}
                size={"medium"}
                margin={{ bottom: "8px" }}
              >
                Подпишитесь на наши новости
              </CustomHeading>
              <CustomParagraph size={"small"}>
                Следите за последними новостями в Российском триатлоне!
                Получайте новостные уведомления себе на почту!
              </CustomParagraph>
            </Box>
            <Box flex direction="column" margin={{ right: "64px" }}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextInput
                    error={errors.email?.message}
                    handleFocus={() => clearErrors("email")}
                    handleChange={(e) => setValue("email", e.target.value)}
                    name="email"
                    iconSrc="rect"
                    iconHeight="40px"
                    iconLeft="20px"
                    iconTop="8px"
                    id="profile-email"
                    defaultValue={user?.email}
                    label="Ваш email"
                    {...field}
                  />
                )}
              />

              <CheckBox
                value={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                label={
                  <span style={{ marginTop: "5px" }}>
                    я даю согласие на обработку
                    <a
                      href="/api/files/document/pers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      моих персональных данных
                    </a>
                  </span>
                }
              />
              <div style={{ marginTop: "10px" }}>
                <CheckBox
                  value={isCheckedReklama}
                  onChange={() => setIsCheckedReklama(!isCheckedReklama)}
                  label={
                    <span style={{ marginTop: "5px" }}>
                      я даю{" "}
                      <a
                        href="/api/files/document/advertising"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        согласие на получение рекламы
                      </a>
                    </span>
                  }
                />
              </div>
              <CustomBtn
                disabled={!isChecked || !isCheckedReklama}
                type="submit"
              >
                Подписаться на рассылку
              </CustomBtn>
            </Box>
            {status && (
              <Layer
                onClickOutside={() => setNewStatus(false)}
                onEsc={() => setNewStatus(false)}
                animation="fadeIn"
                position="center"
              >
                <div
                  style={{
                    padding: "50px",
                    display: "flex",
                    gap: "40px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2>Вы успешно подписались на новости</h2>
                  <DefaultButton
                    style={{
                      minWidth: "200px",
                      maxWidth: "406px",
                      width: "100%",
                      textAlign: "center",
                      height: "44px",
                    }}
                    onClick={() => setNewStatus(false)}
                  >
                    Закрыть
                  </DefaultButton>
                </div>
              </Layer>
            )}
          </Card>
        </form>
      )}
    </ResponsiveContext.Consumer>
  );
});
