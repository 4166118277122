import { CollapsibleCard } from "../CollapsibleCard";
import {
  Anchor,
  Box,
  CheckBox,
  Form,
  Grid,
  Grommet,
  Heading,
  Text,
  TextArea,
  TextInput,
} from "grommet";
import { theme } from "../../utils/theme";
import React from "react";
import { FormPopup } from "../Popups/OlympicReservePopup/FormPopup";
import { ErrorPopup } from "../Popups/OlympicReservePopup/ErrorPopup";
import { observer } from "mobx-react";
import { useOlympicStore } from "../../hooks/useOlympicStore";
import { DefaultButton } from "../DefaultButton";

const questions = [
  {
    title: "Что происходит на 2 этапе отбора?",
    body: "Спортсмены прошедшие отбор во второй этап приглашаются в Москву (2-3 дня) для более глубокого анализа, включающего себя: 1. Прохождение врачей-специалистов для оценки общего состояния здоровья 2.  Функциональная диагностика (ЭКГ, эхокардиография) для выявления скрытых патологий и определения функционального потенциала сердечно-сосудистой системы 3.  Антропометрическое обследование для определения состава тела и морфотипа спортсмена 4.  Тест на тредбане для определения аэробных возможностей организма (МПК, ПАНО) 5.  Скоростно-силовой тест для определения динамической силы основных мышечных групп 6.  Общий, развернутый анализ крови, ДОПИНГ проба 7. Психологическое тестирование",
  },
  {
    title: "Преимущества участия в программе на 2 этапе",
    body: "Спортсмен получает детальную расшифровку всех тестов с заключением и рекомендациями, назначениями врачей. Рекомендации тренерского состава на чем сделать акцент на ближайшие несколько месяцев подготовки",
  },
  {
    title: "Критерии отбора спортсменов на 3 этап",
    body: "На 3 этап спортсмены проходят показавшие наиболее высокие показатели, не провалившие ни одного из 7 способов тестирования на 2 этапе",
  },
  {
    title: "3 этап",
    body: "Спортсмены прошедшие успешно 2 этапа приглашаются на тренировочный сбор с командой «Олимпийский резерв» для улучшения собственных результатов с помощью более сильных партнеров по команде, более комплексного и  профессионального подхода не только к тренировочному процессу, но и процессу  восстановления, питания и т.д. Если у одного тренера 2 и более спортсменов прошли успешно 2 этапа, то этот тренер так же приглашается на сбор для повышения квалификации и анализа методик подготовки.",
  },
  {
    title: "Преимущества прохождения 3 этапа",
    body: "Спортсмен получает пошаговый план подготовки до следующего вызова на сбор. Выдается экипировка, которая позволяет выполнить этот план, в том числе пульсометр, для объективного контроля тренером его выполнения. Создается группа в месенджере, для онлайн поддержки по вопросам тренировочного процесса",
  },
];

export const FaqTab = observer(() => {
  const [openID, setOpenID] = React.useState(null);
  const [formState, setFormState] = React.useState({});
  const [disable, setDisable] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [errorPopup, setErrorPopup] = React.useState(false);

  const { sendFormData } = useOlympicStore();

  const handleTextChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Grommet theme={theme}>
      <Box>
        <Heading
          level={"3"}
          margin={{ top: "18px", bottom: "18px" }}
          style={{ textTransform: "uppercase" }}
        >
          FAQ, обратная связь
        </Heading>

        <Grid
          margin={{ top: "20px" }}
          columns={{ count: "fit", size: "280px" }}
          justify={"center"}
          gap={"medium"}
        >
          {questions.map((q, id) => (
            <Box width={{ min: "100%" }} key={q.title}>
              <CollapsibleCard
                onClick={() => setOpenID(id === openID ? null : id)}
                open={id === openID}
                title={q.title}
                body={q.body}
                text={`Вопрос №${id + 1}`}
              />
            </Box>
          ))}
        </Grid>

        <Form onSubmit={handleSubmit}>
          <Box>
            <Box margin={{ top: "large", bottom: "medium" }}>
              <Text size="medium" weight="bold">
                Остались вопросы или предложения, пишите нам:{" "}
              </Text>
            </Box>
            <Box margin={{ bottom: "small" }}>
              <Text size="small" weight="bold">
                Ваш вопрос или предложение:
              </Text>
            </Box>
          </Box>
          <Box direction="row-responsive" style={{ maxWidth: "750px" }}>
            <Box pad="small" direction="row-responsive" fill="horizontal">
              <TextInput
                placeholder="Ваш Имя"
                name="name"
                type="text"
                value={formState.name}
                onChange={handleTextChange}
                style={{ height: "50px" }}
              />
            </Box>
            <Box pad="small" direction="row-responsive" fill="horizontal">
              <TextInput
                placeholder="Ваш email"
                name="email"
                type="email"
                value={formState.email}
                onChange={handleTextChange}
                style={{ height: "50px" }}
              />
            </Box>
          </Box>
          <Box direction="row-responsive" style={{ maxWidth: "750px" }}>
            <Box pad="small" direction="row-responsive" fill="horizontal">
              <TextArea
                placeholder="Введите текст"
                name="text"
                value={formState.text}
                onChange={handleTextChange}
                style={{ height: "300px" }}
              />
            </Box>
          </Box>
          <Box margin="30px 5px">
            <CheckBox
              name="accept_terms"
              label={
                <Text size="14px" weight="normal">
                  Нажимая на кнопку, я принимаю условия{" "}
                  <Anchor>Пользовательского соглашения</Anchor>*
                </Text>
              }
              onChange={handleTextChange}
              required
            />
          </Box>

          <DefaultButton
            onClick={() =>
              sendFormData(
                formState,
                setDisable,
                setShowPopup,
                setFormState,
                setErrorPopup
              )
            }
            disabled={disable}
            label={
              <Text size="small" color="white">
                Отправить заявку
              </Text>
            }
            style={{
              padding: "10px 50px",
            }}
          />
          <Text margin={{ left: "small" }} size="small">
            * — отмечены поля обязательные для заполнения
          </Text>
        </Form>
      </Box>

      {showPopup && (
        <FormPopup statePopupHandler={setShowPopup} setDisable={setDisable} />
      )}
      {errorPopup && (
        <ErrorPopup statePopupHandler={setErrorPopup} setDisable={setDisable} />
      )}
    </Grommet>
  );
});
