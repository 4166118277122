import { useNavigate } from "react-router-dom";
import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Container } from "../../components/Container";
import { DefaultButton } from "../../components/DefaultButton";
import { routes } from "../../const/routes";
import { MyEvents } from "../MyEvents";
import coverImg from "../../images/webp/bgCabinet.webp";

export const Events = () => {
  const navigate = useNavigate();
  const handleSettingsClick = () => {
    navigate(routes.me.path);
  };

  return (
    <>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <DefaultButton
            style={{
              padding: "10px",
              maxWidth: "206px",
              width: "100%",
              marginTop: "20px",
              textAlign: "center",
              rel: "noopener noreferrer",
            }}
          >
            Билеты
          </DefaultButton>
          <DefaultButton
            onClick={handleSettingsClick}
            style={{
              padding: "10px",
              maxWidth: "206px",
              width: "100%",
              backgroundColor: "transparent",
              border: "1.5px solid #DB4C4C",
              color: "#5f5e5e",
              marginTop: "20px",
              textAlign: "center",
              rel: "noopener noreferrer",
            }}
          >
            Настройки
          </DefaultButton>
        </div>
        <MyEvents />
      </Container>
    </>
  );
};
