import * as yup from "yup";

export const SUPPORTED_FILE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const SUPPORTED_DOCUMENT_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const VALIDATION_ERROR_MESSAGES = {
  isRequired: "Заполните поле!",
  fileSize: "Размер изображения слишком большой!",
  documentSize: "Размер документа слишком большой!",
  fileUpload: "Загрузите файл!",
  fileFormat: "Неподдерживаемый формат!",
};

export const MAX_SIZE_IMAGE = 1048576;

export const MAX_SIZE_LICENSE_IMAGE = 2097152;
//2097152 = 2mb

export function makeValidationSchema() {
  return yup.object().shape({
    rank: yup.string(),
    region: yup.string(),
    file: yup
      .mixed()
      .test("fileFormat", VALIDATION_ERROR_MESSAGES.fileFormat, (value) => {
        if (value && SUPPORTED_FILE_FORMATS.includes(value.type)) return true;
        else if (value.length === 0) return true;
      })
      .test("fileSize", VALIDATION_ERROR_MESSAGES.fileSize, (value) => {
        if (value && value.size < MAX_SIZE_IMAGE) return true;
        else if (value.length === 0) return true;
      }),
    document: yup
      .mixed()
      .test("documentFormat", VALIDATION_ERROR_MESSAGES.fileFormat, (value) => {
        if (value && SUPPORTED_DOCUMENT_FORMATS.includes(value.type))
          return true;
        else if (value.length === 0) return true;
      })
      .test("documentSize", VALIDATION_ERROR_MESSAGES.documentSize, (value) => {
        if (value && value.size < MAX_SIZE_IMAGE) return true;
        else if (value.length === 0) return true;
      }),
  });
}
