import React from "react";
import * as Icon from "./icons";

export const IconSvg = (props) => {
  const { src, ...restProps } = props;

  switch (src) {
    case "search":
      return <Icon.Search {...restProps} />;
    case "passwordKey":
      return <Icon.PasswordKey {...restProps} />;
    case "arrowDown":
      return <Icon.ArrowDown {...restProps} />;
    case "arrowUp":
      return <Icon.ArrowUp {...restProps} />;
    case "bicycle":
      return <Icon.Bicycle width="32px" height="32px" {...restProps} />;
    case "drop":
      return <Icon.Drop width="32px" height="32px" {...restProps} />;
    case "running":
      return <Icon.Running width="32px" height="32px" {...restProps} />;
    case "swimmer":
      return <Icon.Swimmer width="32px" height="32px" {...restProps} />;
    case "wind":
      return <Icon.Wind width="32px" height="32px" {...restProps} />;
    case "path":
      return <Icon.Path width="8px" height="14px" {...restProps} />;
    case "status":
      return <Icon.Status width="6px" height="6px" {...restProps} />;
    case "down":
      return <Icon.Down width="14px" height="8px" {...restProps} />;
    case "close":
      return <Icon.Close width="26px" height="26px" {...restProps} />;
    case "lightClose":
      return <Icon.LightClose width="20px" height="20px" {...restProps} />;
    case "darkClose":
      return <Icon.DarkClose width="26px" height="26px" {...restProps} />;
    case "facebook":
      return <Icon.Facebook height="22px" {...restProps} />;
    case "instagram":
      return <Icon.Instagram height="22px" {...restProps} />;
    case "vk":
      return <Icon.VK height="22px" {...restProps} />;
    case "youtube":
      return <Icon.Youtube height="20px" {...restProps} />;
    case "play":
      return <Icon.Play width="80px" height="80px" {...restProps} />;
    case "download":
      return <Icon.Download width="24px" height="22px" {...restProps} />;
    case "marketLogo":
      return <Icon.MarketLogo {...restProps} />;
    case "moscow":
      return <Icon.Moscow {...restProps} />;
    case "user":
      return <Icon.User width="32px" height="32px" {...restProps} />;
    case "basket":
      return <Icon.Basket width="19px" height="19px" {...restProps} />;
    case "leftButton":
      return <Icon.LeftSlider width="10px" height="18px" {...restProps} />;
    case "rightButton":
      return <Icon.RightSlider width="10px" height="18px" {...restProps} />;
    case "calendar":
      return <Icon.Calendar width="18px" height="20px" {...restProps} />;
    case "email":
      return <Icon.Email width="20px" height="16px" {...restProps} />;
    case "tel":
      return <Icon.Tel width="10px" height="16px" {...restProps} />;
    case "tShirt":
      return <Icon.TShirt width="14px" height="16px" {...restProps} />;
    case "default":
      return <Icon.Default width="13px" height="16px" {...restProps} />;
    case "backedHistory":
      return <Icon.BaskedHistory width="24px" height="24px" {...restProps} />;
    case "userCircle":
      return <Icon.UserCircle width="24px" height="24px" {...restProps} />;
    case "rect":
      return <Icon.Rect {...restProps} />;
    case "dropButton":
      return <Icon.DropButton width="16" height="8" {...restProps} />;
    case "errorIcon":
      return <Icon.ErrorIcon width="16" height="16" {...restProps} />;
    case "userAvaLoad":
      return <Icon.UserAvaLoad width="96" height="96" {...restProps} />;
    case "userLicense":
      return <Icon.UserLicense width="24" height="24" {...restProps} />;
    case "rectClose":
      return <Icon.RectClose width="16" height="8" {...restProps} />;
    case "check":
      return <Icon.Check width="16" height="16" {...restProps} />;
    case "promoCode":
      return <Icon.PromoCode width="18" height="18" {...restProps} />;
    case "warn":
      return <Icon.Warn width="16" height="16" {...restProps} />;
    default:
      return null;
  }
};
