import { Container } from "../../components/Container";
import React from "react";
import coverImg from "../../images/webp/cover4.webp";
import { ChildrensTriathlonTab } from "../../components/ChildrensTriathlonTab";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";

export function ChildrensTriathlon() {
  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <ChildrensTriathlonTab />
      </Container>
    </div>
  );
}
