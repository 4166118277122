import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  margin-bottom: 5rem;
  column-gap: 1rem;

  @media (max-width: 927px) {
    flex-direction: column;
  }
`;

export const StyledAside = styled.aside`
  flex-shrink: 2;
  width: 100%;
  max-width: 23.75rem;
  padding: 1.875rem 0 2.5rem;
  background-color: rgb(243, 242, 242);
  border-radius: 0.625rem;
  flex-shrink: 1.9;
  min-width: 19rem;
`;

export const StyledAsideTitle = styled.b`
  display: block;
  margin-bottom: 0.3125rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.222;
`;
export const StyledAsideTitleWrap = styled.div`
  padding: 0 1.875rem 1.25rem;
  border-bottom: 1px solid rgba(68, 68, 68, 0.18);
`;
export const StyledAsideSubTitle = styled.p`
  font-size: 0.6rem;
  line-height: 1;
`;
export const StyledWarningMessage = styled.div`
  padding: 0.625rem 0;
  border-radius: 0.625rem;
`;
export const StyledCertificate = styled.p`
  position: relative;
  padding-left: 2.125rem;
  color: #c86363;
  font-size: 0.625rem;
  line-height: 1.2;
`;
export const StyledList = styled.ul`
  list-style: none;
`;
export const StyledListItem = styled.li`
  padding: 1.875rem 1.875rem 2.5rem;
  border-bottom: 1px solid rgba(68, 68, 68, 0.18);
`;
export const StyledListItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const StyledActivity = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  line-height: 1.3;

  & + & {
    margin-top: 1.25rem;
  }
`;
export const StyledTotalWrap = styled.div`
  padding: 2.5rem 1.875rem 1.375rem;
`;
export const StyledText = styled.span`
  color: rgb(29, 26, 26);
  white-space: nowrap;
  opacity: 0.6;
`;
export const StyledCost = styled.span`
  display: inline-flex;
  white-space: nowrap;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7rem;
`;
export const StyledSaleWrap = styled.div`
  margin-bottom: 1rem;
`;
export const StyledTotalCost = styled.div`
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.21;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledSendBlock = styled.div`
  padding: 0 1.875rem;
`;
export const StyledButton = styled.button`
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1.25rem;
  min-height: 2.8125rem;
  border-radius: 0.3125rem;
  background: linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%), #c7252b;
  font-size: 0.875rem;
  line-height: 1.143;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;

  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }
`;
export const StyledParagraph = styled.p`
  font-size: 0.6rem;
  line-height: 1.1;
  text-align: center;
`;
export const StyledLink = styled.a`
  color: inherit;
  text-decoration: underline;
`;
