import React from "react";
import { Box, ResponsiveContext } from "grommet";
import { useContext } from "react";
import styled from "styled-components";
import { InfoImage } from "../InfoImage";
import { SmallImage } from "../SmallImage";
import { isMobileTabletResponsive } from "../../utils/utils";
import { routes } from "../../const/routes";
import { times } from "../../utils/date";

const StyledGridBox = styled(Box)`
  display: grid;
  grid-template-rows: auto;
  gap: 24px;
`;

export const NewsMainSection = ({ as, app_api, newsList }) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  return (
    <StyledGridBox
      style={{
        gridTemplateColumns: isMobileWidth
          ? "repeat(auto-fit, minmax(280px, 1fr))"
          : "repeat(4, 1fr)",
      }}
    >
      {newsList?.length > 0 ? (
        <>
          <Box
            style={{
              gridArea: isMobileWidth
                ? "auto / auto / auto / auto"
                : "1 / 1 / span 2 / span 2",
            }}
          >
            <InfoImage
              image={
                newsList[0].banners.preview ||
                newsList[0].banners.small ||
                newsList[0].banners.main
              }
              heading={newsList[0].headline}
              paragraph={newsList[0].subtitle}
              publication_date={times.getFullDate(newsList[0].publication_date)}
              link={`${routes.news.path}/${newsList[0].public_id}`}
              as={as}
            />
          </Box>
          {newsList.slice(1, 5).map((newsItem) => {
            const banner =
              newsItem.banners.small ||
              newsItem.banners.preview ||
              newsItem.banners.main;
            return (
              <Box key={newsItem.public_id}>
                <SmallImage
                  image={banner}
                  heading={newsItem.headline}
                  publication_date={times.getFullDate(
                    newsItem.publication_date
                  )}
                  link={`${routes.news.path}/${newsItem.public_id}`}
                  as={as}
                />
              </Box>
            );
          })}
        </>
      ) : null}
    </StyledGridBox>
  );
};
