import { makeAutoObservable, observable } from "mobx";
import {
  returnNewArrayById,
  returnNewShopItem,
  updateLocalStorage,
  removeItemBasket,
  updateObjectInArray,
  returnAuthConfig,
} from "../utils/utils";
import { backendLogger } from "../utils/logger";
import { routes } from "../const/routes";
import { ticketFormatsSetter } from "../utils/goods";
import { getPaymentObject } from "../utils/basket";

export class BasketStore {
  goodsData = [];
  ticketFormats = {};
  shopItemsData = [];
  deliveryPriceData = null;
  currentShopData = null;
  allOrders = [];
  currentRemoveGoodsData = {};

  removeGoods = (goods) => {
    this.goodsData = removeItemBasket(this.goodsData, goods);
  };

  setOrders = (goods) => {
    this.allOrders = goods;
  };

  setShopGoods = (goods) => {
    this.shopItemsData = goods;
  };

  setCurrentShop = (shop) => {
    this.currentShopData = shop;
  };

  setDeliveryPrice = (price) => {
    this.deliveryPriceData = price;
  };
  removeCurrentGoods = (item) => {
    this.currentRemoveGoodsData = item;
  };
  setGoods = (goods) => {
    this.goodsData = returnNewShopItem(this.goodsData, goods);
  };

  addShop = (item) => {
    this.setGoods(item);
    updateLocalStorage("basketGoods", "add", item);
  };

  updateGoods = (item) => {
    this.goodsData = updateObjectInArray(this.goodsData, item);
  };

  addGoods = (goods) => {
    this.goodsData = returnNewArrayById(this.goodsData, goods);
  };

  removeShop = (item) => {
    this.goodsData = removeItemBasket(this.goodsData, item);
    updateLocalStorage("basketGoods", "delete", item);
  };

  getDeliveryPrice = async (deliveryObject) => {
    const response = await this.api.post(
      `/api/shop/delivery/price`,
      deliveryObject
    );
    if (response.status === 200) {
      this.setDeliveryPrice(response.data.delivery);
    }
  };

  setTicketFormats = (item) => {
    this.ticketFormats = ticketFormatsSetter(this.ticketFormats, item);
  };

  loadDefaultGoods = () => {
    if (localStorage.basketGoods) {
      const goods = JSON.parse(localStorage.basketGoods);

      goods.forEach((el) => {
        this.addGoods(el);
      });
    }
  };

  loadOrders = async () => {
    const config = returnAuthConfig();
    const response = await this.api.get("/api/user/shop/orders", config);

    if (response.status === 200) {
      this.setOrders(response.data.values);
    }
  };
  checkTicketsBeforePaymentByFormatId = async (format_id) => {
    const response = await this.api.get(`/api/event_format/${format_id}`);

    if (response.status === 200) {
      this.setTicketFormats(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  getCurrentShop = async (id) => {
    const response = await this.api.get(`/api/shop/product/${id}`);

    if (response.status === 200) {
      this.setCurrentShop(response.data);
    }
  };

  getAllShopItems = async () => {
    const response = await this.api.get("/api/shop/project/triathlon");
    if (response.status === 200) {
      this.setShopGoods(response.data.values);
    }
  };

  checkGoodsInLocalStorage = (tickets, shop, licenses) => {
    tickets.forEach((el) => {
      const newEl = { ...el, public_id: el.format_public_id };
      updateLocalStorage("basketGoods", "delete", newEl);
      this.removeGoods(newEl);
    });

    shop.forEach((el) => {
      const newEl = { ...el, selector: "shop", size: el.name };
      updateLocalStorage("basketGoods", "delete", newEl);
      this.removeGoods(newEl);
    });
    licenses.forEach((el) => {
      const newEl = {
        ...el,
        selector: "license",
        check: "license",
        size: el.name,
      };
      updateLocalStorage("basketGoods", "delete", newEl);
      this.removeGoods(newEl);
    });
    const remainingGoods = JSON.parse(localStorage.getItem("basketGoods"));

    return remainingGoods;
  };

  otherPayment = (array, items) => {
    if (array.length) {
      const goods = JSON.parse(localStorage.getItem("basketGoods"));
      const sendGoods = getPaymentObject(goods, array, items.email);

      this.paymentStore.payment(sendGoods);
    }
  };

  checkOrder = async (id, navigate) => {
    const response = await this.api.get(`/api/order/${id}`);

    if (response.status === 200) {
      const { items } = response.data;
      const { tickets, shop, licenses } = items;

      // Если в корзине остались НЕоплаченные товары/билеты
      if (this.checkGoodsInLocalStorage(tickets, shop, licenses).length) {
        this.otherPayment(
          this.checkGoodsInLocalStorage(tickets, shop, licenses),
          items
        );
      } else {
        // Если в корзине НЕ осталось НЕоплаченных товаров/билетов
        // И пользователь авторизирован
        if (localStorage.userObj) {
          if (response?.data?.items?.licenses?.length) {
            navigate(`${routes.me.path}/license`);
          } else if (response?.data?.tickets?.length > 1) {
            navigate(`${routes.me.path}/events`);
          } else if (response?.data?.items?.shop?.length) {
            navigate(`${routes.me.path}/shop`);
          } else if (response?.data?.tickets?.length === 1) {
            navigate(`${routes.me.path}/events/${response.data.tickets[0]}`);
          } else {
            navigate(`${routes.me.path}`);
          }
        }
      }
    }
  };

  get goods() {
    return this.goodsData;
  }

  get ticketFormatsInBasket() {
    return this.ticketFormats;
  }

  get deliveryPrice() {
    return this.deliveryPriceData;
  }

  get currentShop() {
    return this.currentShopData;
  }

  get shopItems() {
    return this.shopItemsData;
  }

  get orders() {
    return this.allOrders;
  }

  get currentRemoveGoods() {
    return this.currentRemoveGoodsData;
  }

  constructor(api, PaymentStore) {
    this.paymentStore = PaymentStore;
    this.api = api;
    this.loadDefaultGoods();

    makeAutoObservable(this, { api: observable.ref });
  }
}
