import React from "react";
import { Grid } from "grommet";
import { ImageWithLink } from "../ImageWithLink";

export const NewsSubSection = ({
  newsList,
  app_api,
  as,
  slice = 5,
  size = "330px",
}) => {
  return (
    <Grid
      columns={{ count: "fit", size }}
      gap="24px"
      style={{ marginTop: "20px" }}
    >
      {newsList.slice(slice).map((newsItem) => {
        const banner =
          newsItem.banners.small ||
          newsItem.banners.preview ||
          newsItem.banners.main;
        return (
          <ImageWithLink
            as={as}
            key={newsItem.public_id}
            image={banner}
            heading={newsItem.headline}
            publication_date={newsItem.publication_date}
            link={newsItem.public_id}
          />
        );
      })}
    </Grid>
  );
};
