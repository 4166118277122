import React, { useEffect } from "react";
import { Box, Heading, Paragraph } from "grommet";
import { useParams } from "react-router-dom";
import { NewsBanner } from "../NewsBanner";
import { times } from "../../utils/date";
import { NewsSubSection } from "../NewsSubSection";
import { Container } from "../Container";
import { Subscribe } from "../Subscribe";

export const NewsContent = ({
  api,
  loader,
  renderHTML,
  oneNews,
  loadOneNews,
  newsList,
  loadNewsPreview,
  as,
}) => {
  const { id } = useParams();

  useEffect(() => {
    loadOneNews(id);
    loadNewsPreview();
    //eslint-disable-next-line
  }, [id]);

  if (!oneNews) {
    return (
      <Box fill flex justify="center" alignSelf="center">
        {loader}
      </Box>
    );
  } else {
    const banner =
      oneNews.banners.preview || oneNews.banners.small || oneNews.banners.main;

    return (
      <>
        <Box key={oneNews.public_id} margin={{ bottom: "100px" }}>
          <NewsBanner
            image={banner}
            heading={oneNews.headline}
            paragraph={oneNews.subtitle}
            publication_date={times.getFullDate(oneNews.publication_date)}
          />
          <Box
            width={{ max: "1200px" }}
            alignSelf="center"
            pad={{ horizontal: "medium" }}
          >
            {oneNews.text ? (
              <Paragraph
                size="small"
                fill
                style={{
                  lineHeight: "1.5",
                  whiteSpace: "pre-line",
                }}
              >
                {renderHTML(oneNews.text)}
              </Paragraph>
            ) : null}
          </Box>

          <Container>
            <Box margin={{ top: "100px" }}>
              <Subscribe />
            </Box>
            <Heading style={{ marginBottom: "5rem" }}>Читайте также</Heading>
            <NewsSubSection
              size="130px"
              slice={0}
              app_api={api}
              as={as}
              newsList={newsList}
            />
          </Container>
        </Box>
      </>
    );
  }
};
