import { action, makeAutoObservable, observable } from "mobx";
import { routes } from "../const/routes";
import { AUTH, NO_AUTH } from "../const/user";
import { returnAuthConfig } from "../utils/config";
import { updateLocalStorage } from "../utils/utils";

export class UserStore {
  user = {};
  authStatusData = NO_AUTH;
  authErrorData = "";
  isSendedMailData = false;
  isConfirmEmailErrorMessage = "";
  isRecoveryMail = false;
  userCodeStatus = "";
  userPhoneStatus = "";
  redirectStatusPopup = "";
  isVerify = false;
  initCodeData = "";
  secureCodeData = "";
  resetPopupStatusData = false;
  resetPasswordErrors = "";

  setRecLoginError = (errors) => {
    this.resetPasswordErrors = errors;
  };

  setResetPasswordPopup = (resetPopupStatus) => {
    this.resetPopupStatusData = resetPopupStatus;
  };
  setRedirectCode = (code) => {
    this.secureCodeData = code;
  };

  setInitCode = (code) => {
    this.initCodeData = code;
  };

  setRegistration = (user) => {
    this.user = user;
  };
  setVerifyPopup = (isVerifyPopup) => {
    this.isVerify = isVerifyPopup;
  };
  setRedirectStatus = (status) => {
    this.redirectStatusPopup = status;
  };
  setUserCodeStatus = (status) => {
    this.userCodeStatus = status;
  };
  setUserPhoneStatus = (status) => {
    this.userPhoneStatus = status;
  };

  setAuthStatus = (authStatus) => {
    this.authStatusData = authStatus;
  };

  setSendMail = (isSendedMail) => {
    this.isSendedMailData = isSendedMail;
  };

  setConfirmEmailError = (isConfirmEmailError) => {
    this.isConfirmEmailErrorMessage = isConfirmEmailError;
  };
  setRecoveryMailPopup = (isRecoveryMailPopup) => {
    this.isRecoveryMail = isRecoveryMailPopup;
  };

  setAuthError = (authError) => {
    this.authErrorData = authError;
  };
  logout = () => {
    this.setAuthStatus(NO_AUTH);
    localStorage.removeItem("userObj");
    localStorage.removeItem("token");
  };

  checkAuth = () => {
    const token = localStorage.token;
    if (token) {
      this.setAuthStatus(AUTH);
    } else {
      this.setAuthStatus(NO_AUTH);
      localStorage.removeItem("token");
      localStorage.removeItem("userObj");
    }
  };

  setNewPassword = async (data, navigate) => {
    const response = await this.api.post(
      "/api/auth/resetpassword/update",
      data
    );

    if (response.status === 200) {
      this.setRedirectStatus(``);
      this.setResetPasswordPopup(true);
      navigate(routes.signIn.path);
    } else if (response.status >= 400) {
      console.log(response);
    }
  };

  registration = async (userRegistrationObject) => {
    try {
      const response = await this.api.post("/api/user", userRegistrationObject);
      if (response.status === 200) {
        this.setSendMail(true);
      } else if (response.status >= 400) {
        this.setAuthError(response.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  resetPassword = async (email) => {
    const response = await this.api.post("/api/auth/resetpassword", email);

    if (response.status === 200) {
      this.setRecoveryMailPopup(true);
    } else if (response.status >= 400) {
      this.setRecLoginError(response.data.message);
    }
  };

  login = async (authData) => {
    const response = await this.api.put("/api/auth", authData);

    if (response.status === 200) {
      localStorage.setItem("userObj", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      this.setAuthStatus(AUTH);
    } else if (response.status >= 400) {
      this.setAuthError(response.data.message);

      if (response.status === 418) {
        this.setConfirmEmailError(response.data.message);
      }
    }
  };

  getRegistrationSmsCode = async (telObj, setSendCode) => {
    const response = await this.api.post(`/api/user/code`, telObj);

    if (response.status === 200) {
      setSendCode(true);
      this.setUserPhoneStatus("");
    } else if (response.status >= 400) {
      this.setUserPhoneStatus(response.data.message);
    }
  };

  phoneAuth = async (authData) => {
    const response = await this.api.put(`/api/auth/phone`, authData);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userObj", JSON.stringify(response.data.user));
      this.setAuthStatus(AUTH);
    } else if (response.status >= 400) {
      this.setUserCodeStatus(response.data.message);
    }
  };

  registrationSms = async (userParams, navigation) => {
    const response = await this.api.post(`/api/user/phone`, userParams);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userObj", JSON.stringify(response.data.user));
      this.setAuthStatus(AUTH);
      navigation(routes.main.path);
    } else if (response.status >= 400) {
      this.setUserCodeStatus(response.data.message);
    }
  };

  verify = async (variants, code, navigate) => {
    if (variants === "checkResetCode") {
      const response = await this.api.put("/api/auth/resetpassword", code);
      if (response.status === 200) {
        this.setInitCode(code.code);
        this.setRedirectCode(response.data.secure);
        this.setSendMail(false);
        navigate(routes.newPassword.path);
      } else if (response.status >= 400) {
        this.setRedirectStatus(response.data.message);
      }
    } else if (variants === "verifyEmail") {
      const response = await this.api.post("/api/user/verify/email", code);
      if (response.status === 200) {
        this.setVerifyPopup(true);
        this.setRedirectStatus("");
        navigate(routes.signIn.path);
      } else if (response.status >= 400) {
        this.setRedirectStatus(response.data.message);
      }
    } else if (variants === "verifyNewEmail") {
      const config = returnAuthConfig();
      const response = await this.api.post(
        "/api/user/verify/new/email",
        code,
        config
      );

      if (response.status === 200) {
        //dispatch(ProfileActCreator.changeEmailStatus(MESSAGES.EMAIL_CONFIRM));
        updateLocalStorage("userObj", "update", { email: response.data.email });
        navigate(routes.me.path);
      } else if (response.status >= 400) {
        this.setRedirectStatus(response.data.message);
      }
    }
  };

  get isVerifyPopup() {
    return this.isVerify;
  }
  get authStatus() {
    return this.authStatusData;
  }
  get authError() {
    return this.authErrorData;
  }
  get isSendedMail() {
    return this.isSendedMailData;
  }
  get isConfirmEmailError() {
    return this.isConfirmEmailErrorMessage;
  }

  get isRecoveryMailPopup() {
    return this.isRecoveryMail;
  }
  get authCodeStatus() {
    return this.userCodeStatus;
  }
  get authPhoneStatus() {
    return this.userPhoneStatus;
  }
  get redirectStatus() {
    return this.redirectStatusPopup;
  }

  get initCode() {
    return this.initCodeData;
  }

  get secureCode() {
    return this.secureCodeData;
  }
  get resetPopupStatus() {
    return this.resetPopupStatusData;
  }
  get resetPasswordError() {
    return this.resetPasswordErrors;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref, setAuthStatus: action });
  }
}
