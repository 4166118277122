import React from "react";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import styled from "styled-components";
import { isMobileTabletResponsive } from "../../utils/utils";

const StyledLink = styled.a`
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

export const SmallImage = ({ image, heading, publication_date, link, as }) => {
  return (
    <>
      <ResponsiveContext.Consumer>
        {(size) => (
          <StyledLink as={as} to={link}>
            <Box
              width={{ max: isMobileTabletResponsive(size) ? "" : "330px" }}
              height="220px"
              round="20px"
              background={{
                image: `linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8)), url(${image})`,
                size: "cover",
              }}
            >
              <Box flex pad="medium">
                <Paragraph size="0.75rem" color="white">
                  {publication_date}
                </Paragraph>
                <Box flex justify="end">
                  <Heading size="1.125rem" color="white">
                    {heading}
                  </Heading>
                </Box>
              </Box>
            </Box>
          </StyledLink>
        )}
      </ResponsiveContext.Consumer>
    </>
  );
};
