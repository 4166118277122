import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../const/routes";
import {
  StyledList,
  StyledNotExistBlock,
  StyledNotExistTitle,
  StyledRegEvents,
  StyledTitle,
  StyledTitleLink,
  StyledTransferredEvents,
  StyledUnRegEvents,
} from "./styles";
import { REG_STATUS } from "../../const/const";
import { checkRegDate } from "../../utils/date";
import { useTicketsStore } from "../../hooks/useTicketsStore";
import { observer } from "mobx-react";
import { TicketEventsCard } from "../../components/TicketEventsCard";
import { DefaultButton } from "../../components/DefaultButton";

const getFilteredTickets = (tickets, status) => {
  const userObj = JSON.parse(localStorage.getItem("userObj"));

  return tickets.filter(
    (ticket) =>
      ticket.info.registered === status &&
      !checkRegDate(ticket.event_format.start_time) &&
      ticket.original_user_public_id !== userObj?.public_id
  );
};

const getPastTickets = (tickets) => {
  return tickets.filter((el) => checkRegDate(el.event_format.start_time));
};

export const MyEvents = observer(() => {
  const [isPastTicketTitleClick, setIsPastTicketTitleClick] = useState(false);
  const [isTransferTitleClick, setIsTransferTitleClick] = useState(false);

  const {
    tickets,
    transferTicket,
    pastTicket,
    getTransferTicket,
    getPastTicket,
    loadTickets,
  } = useTicketsStore();

  const handleTransferClick = () => {
    setIsTransferTitleClick(!isTransferTitleClick);
    getTransferTicket();
  };
  const handlePastClick = () => {
    setIsPastTicketTitleClick(!isPastTicketTitleClick);
    getPastTicket();
  };

  useEffect(() => {
    loadTickets();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {getFilteredTickets(tickets, REG_STATUS.FALSE).length === 0 &&
      getFilteredTickets(tickets, REG_STATUS.TRUE).length === 0 &&
      getPastTickets(tickets).length === 0 ? (
        <StyledNotExistBlock>
          <StyledNotExistTitle>
            Вы пока не участвуете ни в одном мероприятии
          </StyledNotExistTitle>
          <DefaultButton
            style={{ padding: "10px 15px" }}
            as={Link}
            to={routes.calendarall.path}
          >
            Перейти в календарь мероприятий
          </DefaultButton>
        </StyledNotExistBlock>
      ) : (
        <>
          {getFilteredTickets(tickets, REG_STATUS.FALSE).length ? (
            <StyledUnRegEvents>
              <StyledTitle>Незарегистрированные мероприятия</StyledTitle>
              <StyledList>
                {getFilteredTickets(tickets, REG_STATUS.FALSE).map((item) => (
                  <TicketEventsCard
                    as={Link}
                    item={item}
                    key={item.public_id}
                    status={REG_STATUS.FALSE}
                  />
                ))}
              </StyledList>
            </StyledUnRegEvents>
          ) : null}

          {getFilteredTickets(tickets, REG_STATUS.TRUE).length ? (
            <StyledRegEvents>
              <StyledTitle>Зарегистрированные мероприятия</StyledTitle>
              <StyledList>
                {getFilteredTickets(tickets, REG_STATUS.TRUE).map((item) => (
                  <TicketEventsCard
                    as={Link}
                    item={item}
                    key={item.public_id}
                    status={REG_STATUS.TRUE}
                  />
                ))}
              </StyledList>
            </StyledRegEvents>
          ) : null}

          {/* {getPastTickets(tickets).length ? (
            <StyledUnRegEvents>
              <StyledTitleLink
                click={isPastTicketTitleClick}
                onClick={() =>
                  setIsPastTicketTitleClick(!isPastTicketTitleClick)
                }
              >
                Прошедшие мероприятия
              </StyledTitleLink>
              <StyledList>
                {isPastTicketTitleClick &&
                  getPastTickets(tickets).map((item) => (
                    <TicketEventsCard
                      as={Link}
                      item={item}
                      key={item.public_id}
                      past={true}
                    />
                  ))}
              </StyledList>
            </StyledUnRegEvents>
          ) : null} */}
        </>
      )}
      <StyledUnRegEvents>
        <StyledTitleLink
          click={isPastTicketTitleClick}
          onClick={handlePastClick}
        >
          Прошедшие мероприятия
        </StyledTitleLink>
        {isPastTicketTitleClick && (
          <StyledList>
            {pastTicket?.length ? (
              pastTicket.map((item) => (
                <TicketEventsCard
                  as={Link}
                  item={item}
                  key={item.public_id}
                  past={true}
                />
              ))
            ) : (
              <>
                {!pastTicket ? null : (
                  <h3 style={{ whiteSpace: "nowrap" }}>
                    Прошедшие мероприятия не найдены!
                  </h3>
                )}
              </>
            )}
          </StyledList>
        )}
      </StyledUnRegEvents>

      <StyledTransferredEvents>
        <StyledTitleLink
          click={isTransferTitleClick}
          onClick={handleTransferClick}
        >
          Переданные билеты
        </StyledTitleLink>
        {isTransferTitleClick && (
          <StyledList>
            {transferTicket.length ? (
              transferTicket.map((item) => (
                <TicketEventsCard
                  as={Link}
                  item={item}
                  key={item.public_id}
                  transfered
                />
              ))
            ) : (
              <h3 style={{ whiteSpace: "nowrap" }}>
                Переданные билеты не найдены!
              </h3>
            )}
          </StyledList>
        )}
      </StyledTransferredEvents>
    </>
  );
});
