import React, { useEffect } from "react";
import { Box } from "grommet";
import { DocumentItem } from "../DocumentItem";
import { observer } from "mobx-react";
import { useRegionsStore } from "../../hooks/useRegionsStore";

export const DocumentsTab = observer(() => {
  const { items, internationalDocuments } = useRegionsStore();

  useEffect(() => {
    internationalDocuments();
    // eslint-disable-next-line
  }, []);

  return (
    <Box gap="medium">
      {items.map((i) => (
        <Box
          key={i.public_id}
          flex
          direction="column"
          margin={{ bottom: "20px" }}
        >
          <DocumentItem title={i.title} date={i.document_date} link={i.link} />
        </Box>
      ))}
    </Box>
  );
});
