export const optionsPerson = [
  { value: "Партнер", label: "Партнер" },
  { value: "Родитель", label: "Родитель" },
  { value: "Родственник", label: "Родственник" },
  { value: "Друг ", label: "Друг " },
  { value: "Коллега", label: "Коллега" },
];

export const optionsRank = [
  { value: "ЗМС", label: "ЗМС" },
  { value: "МСМК", label: "МСМК" },
  { value: "МС", label: "МС" },
  { value: "1 разряд", label: "1 разряд" },
  { value: "2 разряд", label: "2 разряд" },
  { value: "3 разряд", label: "3 разряд" },
  { value: "1 юн. разряд", label: "1 юн. разряд" },
];
