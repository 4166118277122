import React from "react";
import coverImg from "../../images/webp/olympicReserve.webp";
import { Container } from "../../components/Container";
import { OlympicTabs } from "../../components/OlympicTabs";
import { Calendar } from "../../components/Calendar";
import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";

export const OlympicReserve = () => {
  return (
    <div>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <OlympicTabs />

        <Calendar event_type="all" />
      </Container>
    </div>
  );
};
