import austria from "../images/country/austria.png";
import bermuda from "../images/country/bermuda.png";
import canada from "../images/country/canada.png";
import andorra from "../images/country/candora.png";
import china from "../images/country/china.png";
import czech from "../images/country/czech.png";
import denmark from "../images/country/denmark.png";
import france from "../images/country/france.png";
import greatbritain from "../images/country/greatbritain.png";
import italy from "../images/country/italy.png";
import japan from "../images/country/japan.png";
import mexico from "../images/country/mexico.png";
import netherlands from "../images/country/netherlands.png";
import portugal from "../images/country/portugal.png";
import romania from "../images/country/romania.png";
import spain from "../images/country/spain.png";
import switzerland from "../images/country/switzerland.png";
import turkey from "../images/country/turkey.png";
import russia from "../images/calendar/flag.png";
import ironstar from "../images/gerbs/ironstar.png";
import altai from "../images/gerbs/altai.png";
import ironman from "../images/gerbs/ironman.png";
import krasnoyarsk from "../images/gerbs/krasnoyarsk.png";
import kurchatov from "../images/gerbs/kurchatov.png";
import kursk from "../images/gerbs/kursk.png";
import nizhniy from "../images/gerbs/nizhniy.png";
import belovo from "../images/country/belovo.svg";

export const countries = {
  austria: austria,
  bermuda: bermuda,
  canada: canada,
  andorra: andorra,
  china: china,
  czech: czech,
  denmark: denmark,
  france: france,
  greatbritain: greatbritain,
  italy: italy,
  japan: japan,
  mexico: mexico,
  netherlands: netherlands,
  portugal: portugal,
  romania: romania,
  spain: spain,
  switzerland: switzerland,
  turkey: turkey,
  russia: russia,
  ironstar: ironstar,
  altai: altai,
  belovo: belovo,
  ironman: ironman,
  krasnoyarsk: krasnoyarsk,
  kurchatov: kurchatov,
  kursk: kursk,
  nizhniy: nizhniy,
};
