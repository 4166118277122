import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useUserStore } from "../../../hooks/useUserStore";
import coverImg from "../../../images/webp/cover4.webp";
import { Container } from "../../../components/Container";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { Banner } from "../../../components/Banner";
import { AnswerPopup } from "../../../components/Popups/AnswerPopup";
import { SignInForm } from "../../../components/Forms/SignInForm";
import { RecoveryPopup } from "../../../components/Popups/Recovery/RecoveryPopup";
import { useCallback, useState } from "react";
import { VerifyPopup } from "../../../components/Popups/VerifyPopup";
import { ResetPopup } from "../../../components/Popups/ResetPopup";

export const SignIn = observer(() => {
  const [isRecoveryPopup, setRecoveryPopup] = useState(false);

  const {
    isConfirmEmailError,
    setConfirmEmailError,
    authError,
    setAuthError,
    login,
    setRecoveryMailPopup,
    isRecoveryMailPopup,
    getRegistrationSmsCode,
    phoneAuth,
    authCodeStatus,
    authPhoneStatus,
    setUserPhoneStatus,
    setUserCodeStatus,
    isVerifyPopup,
    setVerifyPopup,
    resetPopupStatus,
    setResetPasswordPopup,
  } = useUserStore();

  const handlePopup = () => {
    setConfirmEmailError("");
  };

  const closeVerifyPopup = useCallback(() => {
    setVerifyPopup(false);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <SignInForm
          setUserCodeStatus={setUserCodeStatus}
          setUserPhoneStatus={setUserPhoneStatus}
          authPhoneStatus={authPhoneStatus}
          authCodeStatus={authCodeStatus}
          as={Link}
          getRegistrationSmsCode={getRegistrationSmsCode}
          phoneAuth={phoneAuth}
          getAuthError={authError}
          setAuthError={setAuthError}
          login={login}
          setLoginError={() => {}}
          setRecoveryPopup={setRecoveryPopup}
        />
      </Container>

      {isConfirmEmailError.length ? (
        <AnswerPopup
          heading={"Ошибка авторизации"}
          paragraph={isConfirmEmailError}
          closePopup={handlePopup}
        />
      ) : null}
      {isRecoveryPopup && (
        <RecoveryPopup
          statePopupHandler={setRecoveryPopup}
          isRecoveryMailPopup={isRecoveryMailPopup}
          setRecoveryMailPopup={setRecoveryMailPopup}
        />
      )}
      {isVerifyPopup && <VerifyPopup closePopupHandler={closeVerifyPopup} />}

      {resetPopupStatus && (
        <ResetPopup setResetPasswordPopup={setResetPasswordPopup} />
      )}
    </>
  );
});
