import { Box, Heading } from "grommet";
import React, { useEffect } from "react";
import Loader from "react-js-loader";
import styled from "styled-components";
import { IconSvg } from "../../IconSvg";
import { LicenseInfoCard } from "./LicenseInfoCard";

const StyledSummary = styled.summary`
  position: relative;
  color: black;
  cursor: pointer;
  margin-bottom: 3rem;

  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  list-style: none;
`;

export const LicenseSection = ({
  userValidLicense,
  userPastLicense,
  loadPastUserInsurances,
  loadValidUserInsurances,
}) => {
  useEffect(() => {
    loadValidUserInsurances();
  }, []);

  const handleClick = () => {
    if (!userPastLicense) {
      loadPastUserInsurances();
    }
  };

  if (!userValidLicense)
    return <Loader type="spinner-default" bgColor="#DB4C4C" />;
  return (
    <>
      <Heading level={6} margin={{ bottom: "3rem" }} color="black">
        Лицензия
      </Heading>
      {userValidLicense.length === 0 ? (
        <Heading level={5} margin={{ bottom: "3rem" }}>
          Нет данных
        </Heading>
      ) : (
        <div>
          {" "}
          {userValidLicense.map((el, i) => (
            <LicenseInfoCard key={i} license={el} />
          ))}
        </div>
      )}

      <details>
        <StyledSummary onClick={handleClick}>
          Предыдущие лицензии
          <span>
            {" "}
            <IconSvg src="rectClose" width="12px" />
          </span>
        </StyledSummary>
        {userPastLicense?.length ? (
          userPastLicense?.map((el) => (
            <Box key={el.public_id} margin={{ top: "3rem" }}>
              <LicenseInfoCard license={el} />
            </Box>
          ))
        ) : (
          <h3
            style={{
              textAlign: "center",
              color: "black",
              marginTop: "3rem",
              marginBottom: "3rem",
            }}
          >
            Нет лицензий
          </h3>
        )}
      </details>
    </>
  );
};
