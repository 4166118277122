import coverImg from "../../images/webp/cover4.webp";
import { Container } from "../../components/Container";
import { Partners } from "../../components/Partners";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";
import { AntiDopingTabs } from "../../components/AntiDopingTabs";

export const AntiDoping = () => {
  return (
    <div>
      <Banner cover={coverImg} />
      <Container>
        <Breadcrumbs />
        <AntiDopingTabs />
        {/*         <Partners /> */}
      </Container>
    </div>
  );
};
