import { Box } from "grommet";

export const Container = ({ children, overflow }) => {
  return (
    <Box
      width={"1240px"}
      overflow={overflow ? "hidden" : "unset"}
      margin={{ horizontal: "auto" }}
      pad={{ horizontal: "medium" }}
    >
      {children}
    </Box>
  );
};
