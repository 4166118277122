import * as yup from "yup";

export function makeValidationSchema() {
  return yup.object().shape({
    contact_phone: yup
      .string()
      .test("isValidPhone", "Номер телефона введен не корректно", (value) => {
        if (!value || value.length !== 12) return false;
        if (value && value.length === 12) return true;
      }),
    extra_contact: yup.string().required(),
    type_relate: yup.string().required("Выберите вариант!"),
  });
}
