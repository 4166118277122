import { Subscribe } from "../../components/Subscribe";
import { Container } from "../../components/Container";
import coverImg from "../../images/webp/pageCalendar.1f429a5b.webp";
import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PartnersTab } from "../../components/Partners/PartnersTab";

export const PartnersList = () => {
  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <PartnersTab />
        <Subscribe />
      </Container>
    </>
  );
};
