import { Anchor, Box, Grommet, Heading } from "grommet";
import styled from "styled-components";
import { theme } from "../../utils/theme";

const CustomTbody = styled.tbody`
  color: #313131;
  font-size: 16px;

  & th {
    padding: 10px;
    text-align: left;
    border-left: 2px solid #dadde0;
    background-color: #e8eaec;
  }

  & th:first-child {
    max-width: 56px;
    color: #777777;
    font-weight: 400;
    text-align: center;
    border-left: none;
  }

  & tr {
    border-top: 2px solid #e8eaec;
  }

  & tr:first-child {
    border-top: none;
  }

  & td {
    padding: 10px;
    background-color: #f8f8f8;
    border-left: 2px solid #e8eaec;
  }

  & td:first-child {
    max-width: 56px;
    color: #777777;
    text-align: center;
    border-left: none;
  }

  & td:nth-child(4) {
    min-width: 180px;
  }

  & tr:first-child th:first-child {
    border-top-left-radius: 24px;
  }

  & tr:first-child th:last-child {
    border-top-right-radius: 24px;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 24px;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 24px;
  }
`;

export const ChildrenSchools = () => {
  return (
    <Grommet theme={theme}>
      <Box>
        <Box flex direction="row" justify="between">
          <Heading
            level={"3"}
            margin={{ top: "18px", bottom: "18px" }}
            style={{ textTransform: "uppercase" }}
          >
            Детские школы
          </Heading>
        </Box>

        <Box
          margin={{ bottom: "56px", top: "20px" }}
          style={{ overflow: "auto" }}
          pad="0"
        >
          <table style={{ borderCollapse: "collapse" }}>
            <CustomTbody>
              <tr>
                <th>№</th>
                <th>Регион</th>
                <th>Название</th>
                <th>Телефон</th>
                <th>E-mail</th>
                <th>Сайт</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Москва</td>
                <td>ДЮСШ "Акватикс"</td>
                <td>+7926-527-26-14</td>
                <td>info@aquatics-sport.com</td>
                <td>
                  <Anchor
                    href={"https://aquatics-sport.com"}
                    label={"aquatics-sport.com"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Москва</td>
                <td>ДЮСШ "ТРИАТЛОНиЯ"</td>
                <td>+7925-316-07-23</td>
                <td>voynova18@yandex.ru Triatloniy@yandex.ru</td>
                <td>
                  <Anchor
                    href={"http://триатлония.рф/"}
                    label={"триатлония.рф"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>53</td>
                <td>Москва</td>
                <td>Детская школа триатлона - Лидер</td>
                <td>+7-916-796-60-04</td>
                <td>Scholtriathlonleader@bk.ru</td>
                <td>
                  <Anchor
                    href={"https://www.tri-leader.ru/"}
                    label={"https://www.tri-leader.ru/"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>54</td>
                <td>Москва</td>
                <td>Детская школа по плаванию и триатлону «Шторм»</td>
                <td>+7916-980-89-42</td>
                <td>welcome@triathlonschool.ru</td>
                <td>
                  <Anchor
                    href={"https://школатриатлона.рф"}
                    label={"https://школатриатлона.рф"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>52</td>
                <td>Санкт-Петербург</td>
                <td>Школа по триатлону и плаванию SBR88</td>
                <td>+7 911 126-06-69</td>
                <td>mao990@bk.ru</td>
                <td>
                  <Anchor
                    href={"https://vk.com/club16620041"}
                    label={"vk.com/club16620041"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>

              <tr>
                <td>27</td>
                <td>Волгоградская область</td>
                <td>МБУ "СШОР №16 г. Волгограда"</td>
                <td>+7844-242-19-07</td>
                <td>msd16@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://msd16.1c-umi.ru/"}
                    label={"msd16.1c-umi.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>Забайкальский край (г. Хабаровск)</td>
                <td>ФАУ МО РФ ЦСКА</td>
                <td>+7914-445-36-01</td>
                <td>zab-triathlon@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>28</td>
                <td>Калининградская область</td>
                <td>Спортивный клуб "AMBERMAN"</td>
                <td>+74012-37-62-34</td>
                <td>ceo@amberman.net</td>
                <td>
                  <Anchor
                    href={"http://amberman.net/"}
                    label={"amberman.net"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>29</td>
                <td>Кемеровская область</td>
                <td>МБФСУ "СШОР №3"</td>
                <td />
                <td />
                <td>
                  <Anchor
                    href={"http://kemerovo-ski.ru/"}
                    label={"kemerovo-ski.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>30</td>
                <td>Кемероская область, г. Кемерово</td>
                <td>НКО СК "Триатлон Сибири"</td>
                <td>+7923-464-87-17</td>
                <td>triathlonsiberia@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://triathlonsiberia.ru/"}
                    label={"triathlonsiberia.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>31</td>
                <td>Кемероская область, г. Белово</td>
                <td>НКО СК "Триатлон Сибири"</td>
                <td>+7923-464-87-17</td>
                <td>triathlonsiberia@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://triathlonsiberia.ru/"}
                    label={"triathlonsiberia.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>32</td>
                <td>Кемеровская область, г. Белово</td>
                <td>СШ им. Макарова</td>
                <td>+7923-464-87-47</td>
                <td>dush2_42@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://sport-shkola2makarova.org.ru/"}
                    label={"sport-shkola2makarova.org.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>33</td>
                <td>Кировская область</td>
                <td>КОГАУ "СШ "Вересники"</td>
                <td>+7833-225-43-33</td>
                <td>info@veresniki.com</td>
                <td>
                  <Anchor
                    href={"https://veresniki.com/"}
                    label={"veresniki.com"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>34</td>
                <td>Кировская область</td>
                <td>МАУ "СШ пгт.Верхошижемье"</td>
                <td>+7833-352-13-99</td>
                <td>verkhoshizhemedussh@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://verkhoshizhemesport.ru/"}
                    label={"verkhoshizhemesport.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>13</td>
                <td>Краснодарский край (г. Сочи)</td>
                <td>МБУ ДО "ДЮСШ №1"</td>
                <td>+7862-264-35-17</td>
                <td>sportschool1@edu.sochi.ru</td>
                <td>
                  <Anchor
                    href={"http://sport1.sochi-schools.ru/"}
                    label={"sport1.sochi-schools.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>14</td>
                <td>Краснодарский край (г. Белореченск)</td>
                <td>СШОР "Волна"</td>
                <td>+7861-553-70-04</td>
                <td>belora-volna@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://mau-volna.ufksbr.ru/"}
                    label={"mau-volna.ufksbr.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>Краснодарский край</td>
                <td>МАУ СШ "Победа"</td>
                <td>+78617-260-261</td>
                <td>pobeda_school@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>16</td>
                <td>Краснодарский край</td>
                <td>Школа по триатлону при СК Studio 8</td>
                <td>+7938-452-26-36</td>
                <td>s8sochi@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://www.sochitriathlon.ru/"}
                    label={"sochitriathlon.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>17</td>
                <td>Краснодарский край</td>
                <td>МБУ МОГК СШОР 3</td>
                <td>+7861-261-15-46</td>
                <td>poliatlon-triatlon@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://centrsport3.ru/"}
                    label={"centrsport3.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>18</td>
                <td>Краснодарский край</td>
                <td>МБУ ДО ДЮСШ №1 г. Сочи</td>
                <td>+7862-264-35-11</td>
                <td>sochi_td_p2002@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>19</td>
                <td>Красноярский край</td>
                <td>МАУ СШОР «Здоровый мир»</td>
                <td>+7391-20-55-662</td>
                <td>zm-krassport@yandex.ru</td>
                <td>
                  <Anchor
                    href={"http://zm-krassport.ru/3"}
                    label={"zm-krassport.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>20</td>
                <td>Красноярский край</td>
                <td>МБОУ ДО ДЮСШ г.Дивногорска</td>
                <td>+7391-443-542</td>
                <td>sport.school-div@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://divnogorsk-adm.ru/"}
                    label={"divnogorsk-adm.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>35</td>
                <td>Ленинградская область</td>
                <td>МКОУ ДО "Волосовская ДЮСШ"</td>
                <td>+7964-612-15-16</td>
                <td>iu.07@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://duss.volosovo-edu.ru/news.php"}
                    label={"duss.volosovo-edu.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>36</td>
                <td>Липецкая область</td>
                <td>ЛГТУ</td>
                <td>+7950-807-59-91</td>
                <td>kozlov_petr1963@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://www.stu.lipetsk.ru/"}
                    label={"stu.lipetsk.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>37</td>
                <td>Московская область, ГО Солнечногорск</td>
                <td>Спортшкола №1</td>
                <td>+7903-961-79-54</td>
                <td>a.p.shahmatov@gmail.com</td>
                <td />
              </tr>
              <tr>
                <td>51</td>
                <td>Московская область, Фрязино</td>
                <td>МУ СШОР-4</td>
                <td>+7 903 106-51-83</td>
                <td> </td>
                <td> </td>
              </tr>
              <tr>
                <td>38</td>
                <td>Омская область</td>
                <td>СШОР "Центр лыжного спорта"</td>
                <td>+7 381 222-33-80</td>
                <td>sdusshor-cls@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>21</td>
                <td>Пермский край</td>
                <td>Клуб триатлона Южный</td>
                <td>+7950-468-97-95</td>
                <td>obuhoff.vova2012@yandex.ru</td>
                <td>
                  <Anchor
                    href={"https://vk.com/triathlonperm"}
                    label={"vk.com/triathlonperm"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>22</td>
                <td>Пермский край</td>
                <td>ДЮСШ г. Чайковский</td>
                <td>+7991-809-81-44</td>
                <td>legostaew2008@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://vk.com/club162267818"}
                    label={"vk.com/club"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>23</td>
                <td>Пермский край</td>
                <td>СШ "Летающий лыжник"</td>
                <td>+7992-227-35-54</td>
                <td>tramplin2015@bk.ru</td>
                <td />
              </tr>
              <tr>
                <td>24</td>
                <td>Пермский край</td>
                <td>МБУ "Центр развития ФК и С"</td>
                <td>+7342-793-12-96</td>
                <td>novikov031177@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://ohansk-crfkis.ucoz.net/"}
                    label={"ohansk-crfkis.ucoz.net"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>25</td>
                <td>Приморский край (г. Находка)</td>
                <td>МБУ СШ "Приморец"</td>
                <td>+7924-253-03-03</td>
                <td>primmax@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>26</td>
                <td>Приморский край</td>
                <td>МБУ СШ "Старт"</td>
                <td>+7924-253-03-03‬</td>
                <td>primmax@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>3</td>
                <td>Республика Башкортостан (г. Салават)</td>
                <td>МБУ СШ "Триумф"</td>
                <td>+7 3476-357-70-30</td>
                <td>olimp_salavat@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://dussh-olimp.bash.sportsng.ru/"}
                    label={"dussh-olimp.bash.sportsng.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Республика Башкортостан</td>
                <td>ДЮСШ Мишкинского р-на</td>
                <td>+7 34749 2-15-66</td>
                <td>dyussh00@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://dyussh00.ucoz.ru/"}
                    label={"dyussh00.ucoz"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Республика Башкортостан (г. Благовещенск)</td>
                <td>МАОУ ДО ДЮСШ</td>
                <td>+7 987-102-86-84</td>
                <td>sultanova.89@bk.ru</td>
                <td />
              </tr>
              <tr>
                <td>6</td>
                <td>Республика Башкортостан</td>
                <td>МАУ СШ №1 Ишимбайского р-на</td>
                <td>+7347-947-79-61</td>
                <td>dussh-1shkola@yandex.ru</td>
                <td>
                  <Anchor
                    href={"https://vk.com/sport_sh1_ishimbay"}
                    label={"vk.com/sport_sh1"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>Республика Башкортостан</td>
                <td>МАУ СШ Уфимского р-на</td>
                <td>+7347 246-58-74</td>
                <td>sdusshor@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://ufimsportschool.ru/"}
                    label={"ufimsportschool"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Республика Крым</td>
                <td>"ГПМЦ" (г. Ялта)</td>
                <td>+7978-042-61-39</td>
                <td>lisovenko-tri@mail.ru</td>
                <td />
              </tr>
              <tr>
                <td>9</td>
                <td>Республика Татарстан</td>
                <td>МБУ СШ "Олимп"</td>
                <td>+7843-202-01-56</td>
                <td>dush-olimp@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://edu.tatar.ru/sovetcki/org5701"}
                    label={"edu.tatar.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>

              <tr>
                <td>39</td>
                <td>Саратовская область</td>
                <td>ГБУ «Саратовский областной ЦСП»</td>
                <td>+78452-65-96-38</td>
                <td>sshvsm@bk.ru</td>
                <td>
                  <Anchor
                    href={"https://saratov-olimp.ru/"}
                    label={"saratov-olimp.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>40</td>
                <td>Саратовская область</td>
                <td>МУ "Центральная СШОР"</td>
                <td>+78452-20-39-11, 20-38-09</td>
                <td>cdush@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://csh.nubex.ru/"}
                    label={"csh.nubex.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>41</td>
                <td>Саратовская область</td>
                <td>ГБУ СО "СШОР "НАДЕЖДА ГУБЕРНИИ"</td>
                <td>+78452-75-33-03</td>
                <td>sodushng@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://www.sodush.ru/"}
                    label={"sodush.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>42</td>
                <td>Свердловская область</td>
                <td>ДЮСШ "Виктория" г. Екатеринбург</td>
                <td>+7343-375-79-72</td>
                <td>tolik1107@bk.ru</td>
                <td>
                  <Anchor
                    href={"http://www.mouviktoria.ru/"}
                    label={"mouviktoria.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>43</td>
                <td>Свердловская область</td>
                <td>"ДЮСШ г.Богданович"</td>
                <td>+7343-765-69-85</td>
                <td>DUSH-09@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://bdussh.uralschool.ru/"}
                    label={"bdussh.uralschool.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>44</td>
                <td>Тверская область</td>
                <td>ФТ Тверской области</td>
                <td>+7960-717-10-01</td>
                <td>mail@triathlon-tver.ru</td>
                <td />
              </tr>
              <tr>
                <td>45</td>
                <td>Томская область</td>
                <td>ОГАУ "СШОР Натальи Барановой"</td>
                <td>+7382-242-22-33</td>
                <td>cop-n-baranovoy@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://centrbaranovoy70.ru/"}
                    label={"centrbaranovoy70.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>46</td>
                <td>Томская область</td>
                <td>НКО СК "Триатлон Сибири"</td>
                <td>+7913-874-39-40</td>
                <td>triathlonsiberia@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://triathlonsiberia.ru/"}
                    label={"triathlonsiberia.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>47</td>
                <td>Тульская область</td>
                <td>Школа плавания и триатлона "Рекорд"</td>
                <td>+7910-589-15-14</td>
                <td>swimrecord13@gmail.com</td>
                <td>
                  <Anchor
                    href={"https://recordswim.com/"}
                    label={"recordswim.com"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>48</td>
                <td>Тюменская область</td>
                <td>МАУ ДО ДЮСШ №4</td>
                <td>+7345-221-51-61</td>
                <td>sales@skibike.ru</td>
                <td>
                  <Anchor
                    href={"http://dsimp.ru/sdyusshor-4"}
                    label={"dsimp.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Чувашская Республика</td>
                <td>АУ "СШОР 3" Минспорта Чувашии</td>
                <td>+7352-73-39-00</td>
                <td>direktor.sport-cap@yandex.ru</td>
                <td>
                  <Anchor
                    href={"http://sportkomplex.cap.ru/"}
                    label={"sportkomplex.cap.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>Чувашская Республика</td>
                <td>МБУ "СШ им. олимпийского чемпиона А.И.Тихонова"</td>
                <td>+7352-50-61-36</td>
                <td>sportshkola@list.ru</td>
                <td>
                  <Anchor
                    href={"http://www.dyussh4.cap.ru//"}
                    label={"dyussh4.cap.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>49</td>
                <td>Ярославская область</td>
                <td>МУ "СШОР № 19"</td>
                <td>+7485-259-33-03</td>
                <td>sport-semja@mail.ru</td>
                <td>
                  <Anchor
                    href={"http://www.yarsport19.ru/tt"}
                    label={"yarsport19.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
              <tr>
                <td>50</td>
                <td>Ярославская область</td>
                <td>МУ СШОР-4</td>
                <td>+7485-223-17-36</td>
                <td>swim4yar@mail.ru</td>
                <td>
                  <Anchor
                    href={"https://sdiusshor4.narod.ru/"}
                    label={"sdiusshor4.narod.ru"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  />
                </td>
              </tr>
            </CustomTbody>
          </table>
        </Box>
      </Box>
    </Grommet>
  );
};
