import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SquareCheckBox } from "../Checkboxes/SquareCheckBox";
import { DefaultButton } from "../DefaultButton";
import { TextInput } from "../Inputs/TextInput";
import { makeValidationSchema } from "./makeValidationSchema";
import { StyledErrorTitle, StyledForm, StyledLabel } from "./styles";

export const TransferTicketForm = ({
  ticket,
  email,
  transferTicketMessage,
  sendTicket,
}) => {
  const [isValidForm, setValidForm] = useState(false);
  const [isCheckPolitics, setCheckPolitics] = useState(false);
  const [isFromVisible, setFormVisible] = useState(false);

  const handleLabelClick = () => {
    setFormVisible((prev) => !prev);
  };

  const validateForm = makeValidationSchema();

  useEffect(() => {
    setValidForm(isCheckPolitics);
  }, [isCheckPolitics]);

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
  });

  const onSubmit = ({ email }) => {
    sendTicket({ public_id: ticket.public_id, email });
  };

  return (
    <StyledForm isFromVisible={isFromVisible} onSubmit={handleSubmit(onSubmit)}>
      {transferTicketMessage && (
        <StyledErrorTitle>{transferTicketMessage}</StyledErrorTitle>
      )}

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextInput
            error={errors.email?.message}
            handleFocus={() => clearErrors("email")}
            handleLabelClick={handleLabelClick}
            isFromVisible={isFromVisible}
            name="email"
            iconSrc="email"
            iconWidth="20px"
            iconTop="16px"
            iconLeft="14px"
            id="transfer-email"
            label="Передать билет другому пользователю по Email"
            {...field}
          />
        )}
      />
      {isFromVisible && (
        <>
          <DefaultButton
            style={{ padding: "10px", maxWidth: "400px" }}
            type="submit"
            disabled={!isValidForm}
            label="Передать"
          />
          <SquareCheckBox
            onChange={(e) => setCheckPolitics(e.target.checked)}
            label={
              <StyledLabel>Подтверждаю что email введен верно</StyledLabel>
            }
            checked={isCheckPolitics}
          />{" "}
        </>
      )}
    </StyledForm>
  );
};
