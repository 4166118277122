import { useState } from "react";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Container } from "../../components/Container";
import { PopupInfoBasket } from "../../components/Popups/PopupInfoBasket";
import { Sale } from "../../components/Sale";

export const Shop = () => {
  /*   const [isInfoPopup, setIsInfoPopup] = useState(true); */
  return (
    <Container>
      <Breadcrumbs />
      {/* {isInfoPopup && (
        <PopupInfoBasket closePopup={() => setIsInfoPopup(false)} />
      )} */}
      <Sale />
    </Container>
  );
};
