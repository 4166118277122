import * as yup from "yup";
import { REGEX_MAIL } from "../../const/regex";

export function makeValidationSchema(
  isSms,
  sendCode,
  setUserPhoneStatus,
  setUserCodeStatus
) {
  return yup.object().shape({
    email:
      !isSms &&
      yup
        .string()
        .required()
        .test("emailIslValid", "Введите валидный email", (value) => {
          const testValue = REGEX_MAIL.test(value);
          if (!testValue) return false;
          if (testValue) return true;
        }),
    password: !isSms && yup.string().required(),
    code:
      sendCode &&
      yup
        .string()
        .required()
        .test(
          "validNewPassword",
          "Длина кода должна состоять из 4 символов",
          (value) => {
            setUserCodeStatus("");
            if (value && value.length < 4) return false;
            if (value && value.length > 4) return false;
            if (value && value.length === 4) return true;
          }
        ),
    phone:
      isSms &&
      yup
        .string()
        .test("isValidPhone", "Введите корректный номер телефона", (value) => {
          setUserPhoneStatus("");
          if (!value || value.length !== 12) return false;
          if (value && value.length === 12) return true;
        }),
  });
}
