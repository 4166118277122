import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Message } from "./Message";
import Loader from "react-js-loader";
import { Box } from "grommet";
import { useUserStore } from "../../hooks/useUserStore";
import { observer } from "mobx-react";

export const VerifyPage = observer(({ variants }) => {
  const { verify, redirectStatus } = useUserStore();
  const navigate = useNavigate();
  const codeObj = useParams();

  useEffect(() => {
    verify(variants, codeObj, navigate);
    //eslint-disable-next-line
  }, [variants, codeObj]);

  return (
    <>
      {redirectStatus.length ? (
        <Message message={redirectStatus} />
      ) : (
        <Box margin={{ top: "100px", bottom: "100px" }}>
          {" "}
          <Loader type="spinner-default" bgColor="#DB4C4C" />
        </Box>
      )}
    </>
  );
});
