import React from "react";
import { CheckBox, Grommet } from "grommet";

export const SquareCheckBox = ({
  disabled,
  checked,
  onChange,
  label,
  icon,
}) => {
  const theme = {
    global: {
      colors: {
        brand: `#315FB6`,
        "site-colors-1": `#F8F8F8`,
        "site-colors-2": `#E8EAEC`,
        "site-colors-3": `#EDEFF2`,
        "site-colors-4": `#D0DAE8`,
        "dark-site-color": `#1A1A1A`,
        grey: `#D1D4D8`,
        "dark-blue": `#333F51`,
        black: `#000000`,
        white: `#ffffff`,
        text: {
          light: `#333333`,
          dark: `#ffffff`,
        },
        focus: `transparent`,
      },
    },
    checkBox: {
      border: {
        width: "1px",
        color: "black",
        radius: "4px",
      },
      gap: "xsmall",
      size: "24px",
      icon: {
        size: "20px",
        extend: "stroke: black;",
      },
      icons: {
        checked: icon,
      },

      extend: `
        color: 'black';
      `,
    },
  };

  return (
    <Grommet theme={theme}>
      <CheckBox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        label={label}
      />
    </Grommet>
  );
};
