import { Container } from "../Container";
import { Box, Grommet, ResponsiveContext, Tab, Tabs, Text } from "grommet";
import { useContext, useState } from "react";
import { DescriptionTab } from "./DescriptionTab";
import { RegionContentTab } from "./RegionContentTab";
import { HowToBeRegion } from "./HowToBeRegion";
import { isMobileTabletResponsive } from "../../utils/utils";

const theme = {
  global: {
    colors: {
      brand: "#DB4C4C",
      focus: "transparent",
    },
  },
  tab: {
    margin: "0 16px",
    pad: {
      bottom: "16px",
      horizontal: "small",
    },
    background: "transparent",
    border: {
      side: "bottom",
      size: "small",
      color: {
        dark: "",
        light: "#D8D8D8",
      },
      active: {
        color: {
          light: "#DB4C4C",
        },
      },
      hover: {
        color: {
          light: "#DB4C4C",
        },
      },
    },
    color: "#D8D8D8",
    hover: {
      color: "#DB4C4C",
    },
    active: {
      background: "transparent",
      color: "#DB4C4C",
    },
  },
};

export function RegionsTriathlonTab() {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const labelTabs = [
    {
      id: 0,
      label: isMobileWidth ? (
        <span>
          Список <br />
          региональных
          <br /> федераций
        </span>
      ) : (
        <span>Список региональных федераций</span>
      ),
      content: <RegionContentTab />,
    },
    {
      id: 1,
      label: isMobileWidth ? (
        <span>
          Как создать
          <br /> федерацию
          <br /> с нуля
        </span>
      ) : (
        <span>Как создать федерацию с нуля</span>
      ),
      content: <DescriptionTab />,
    },
    {
      id: 2,
      label: isMobileWidth ? (
        <span>
          Как открыть <br />
          отделение
          <br /> по триатлону
        </span>
      ) : (
        <span>Как открыть отделение по триатлону</span>
      ),
      content: <HowToBeRegion />,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const activeID = labelTabs[activeTab].id;

  const activeContent = labelTabs.find((tab) => tab.id === activeID);
  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box>
            <Box>
              <Tabs activeIndex={activeTab} onActive={setActiveTab}>
                {labelTabs.map((tab) => (
                  <Tab
                    margin={{
                      bottom: size !== "small" ? "large" : "medium",
                    }}
                    title={
                      <Text size="medium" weight="bold">
                        {tab.label}
                      </Text>
                    }
                    key={tab.id}
                  ></Tab>
                ))}
              </Tabs>
            </Box>

            <Container>
              <Box
                style={{
                  marginBottom: "50px",
                }}
              >
                <Box>{activeContent.content}</Box>
              </Box>
            </Container>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}
