import styles from "./RecoveryPopup.module.scss";
import { Layer } from "grommet";
import { FormRecovery } from "../../Forms/FormRecovery";
import { IconSvg } from "../../IconSvg";

export const RecoveryPopup = ({
  statePopupHandler,
  isRecoveryMailPopup,
  setRecoveryMailPopup,
}) => {
  const closePopup = () => {
    statePopupHandler(false);
    setRecoveryMailPopup(false);
  };

  return (
    <Layer
      onClickOutside={closePopup}
      onEsc={closePopup}
      background="transparent"
      animation="fadeIn"
      responsive={false}
      position="center"
    >
      <div className={styles.wrapper}>
        <div className={styles.titleWrap}>
          <IconSvg src="passwordKey" color="#DB4C4C" />
          <h3 className={styles.title}>Восстановление пароля</h3>
        </div>

        {!isRecoveryMailPopup ? (
          <>
            <p className={styles.text}>
              Для восстановление пароля введите ваш электронный адрес, на вашу
              почту прийдет инструкция по восстановлению пароля
            </p>

            <FormRecovery />
          </>
        ) : (
          <>
            <p>
              Проверьте почту, на ваш email отправлено письмо для сброса пароля
            </p>
          </>
        )}
        <IconSvg src="close" onClick={closePopup} className={styles.closeBtn} />
      </div>
    </Layer>
  );
};
