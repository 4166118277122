import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createIOC } from "./bindings";
import { iocContext } from "./hooks/useInject";
import { Grommet } from "grommet";
import { theme } from "./utils/theme";
import App from "./App";
import "./index.css";
import { initYupMessages } from "./const/initYupMessages";
import { YMInitializer } from "react-yandex-metrika";

const ioc = createIOC();
initYupMessages();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback="loading">
    <Grommet theme={theme}>
      <BrowserRouter>
        <iocContext.Provider value={ioc}>
          <YMInitializer accounts={[95272423]} options={{ webvisor: true }} />
          <App />
        </iocContext.Provider>
      </BrowserRouter>
    </Grommet>
  </Suspense>
);
