import React from "react";
import { Box } from "grommet";
import {
  StyledBox,
  StyledFormField,
  StyledIconSvg,
  StyledPhoneInput,
} from "./styles";
import { ErrorInfo } from "../../ErrorInfo";

export const InputPhone = ({
  error = "",
  id = Date.now(),
  label = "Введите номер телефона",
  maxWidth = "406px",
  disabled = false,
  iconSrc = "",
  iconColor = "",
  marginErrorBottom = "",
  iconWidth = "",
  iconHeight = "",
  iconLeft = "10px",
  iconTop = "14px",
  ...rest
}) => {
  return (
    <Box flex width={{ max: maxWidth }}>
      <StyledFormField disabled={disabled} error={error} htmlFor={String(id)}>
        {label}
      </StyledFormField>
      <StyledBox>
        <StyledIconSvg
          error={error}
          iconLeft={iconLeft}
          iconTop={iconTop}
          src={iconSrc}
          width={iconWidth}
          height={iconHeight}
          color={iconColor}
          disabled={disabled}
        />
        <StyledPhoneInput error={error} disabled={disabled} id={id} {...rest} />
        <div style={{ marginTop: "10px", marginBottom: marginErrorBottom }}>
          <span style={{ position: "relative" }}>
            {error && <ErrorInfo error={error} />}
          </span>
        </div>
      </StyledBox>
    </Box>
  );
};
