import { observer } from "mobx-react";
import { useEffect } from "react";
import { useBasketStore } from "../../hooks/useBasketStore";
import { Link } from "react-router-dom";
import { routes } from "../../const/routes";

import styles from "./index.module.scss";
import { OrderCard } from "../../components/OrderCard";

export const MyGoods = observer(() => {
  const { orders, loadOrders } = useBasketStore();

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {orders.length === 0 ? (
        <div className={styles.notExistBlock}>
          <h3 className={styles.notExistTitle}>
            Вы пока не совершили ни одной покупки
          </h3>
          <Link to={routes.shop.path} className={styles.notExistLink}>
            Перейти в магазин
          </Link>
        </div>
      ) : (
        <section className={styles.unregEvents}>
          <h3 className={styles.label}>Ваши покупки</h3>
          <ul className={styles.list}>
            {orders.map((item) => (
              <OrderCard item={item} key={item.public_id} styles={styles} />
            ))}
          </ul>
        </section>
      )}
    </>
  );
});
