import React from "react";
import { Box, Heading } from "grommet";
import { Gender } from "../../Forms/Gender";
import { Data } from "../../Forms/Data";
import { EmailForm } from "../../Forms/EmailForm";
import { PasswordForm } from "../../Forms/PasswordForm";
import { PhoneForm } from "../../Forms/PhoneForm";
import { EmergencyContact } from "../../Forms/EmergencyContact";
import { AdditionalForm } from "../../Forms/AdditionalForm";

export const UserSection = ({
  getChangeEmailStatus,
  setNewMail,
  getPasswordMessage,
  setNewPassword,
  getPasswordError,
  getViewCodeParam,
  changePhoneStatus,
  setViewCodeField,
  setChangePhoneStatus,
  setNewPhone,
  sendNewPhoneCode,
  getChangeCodeStatus,
  setChangeCodeStatus,
  setNewData,
  getDataMessage,
  setStatus,
  setMessage,
  contactStatus,
  loadDeliveryRegions,
  regions,
  setAdditionalNewData,
  getUserInfo,
  setMessageInfo,
  messageInfo,
}) => {
  return (
    <>
      <Heading level={3} margin={{ bottom: "2rem" }} style={{ color: "black" }}>
        Личные данные
      </Heading>

      <Box flex direction="column" gap="3rem" margin={{ bottom: "120px" }}>
        <Data
          getDataMessage={getDataMessage}
          setStatus={setStatus}
          setMessage={setMessage}
          setNewData={setNewData}
        />
        <Gender setNewData={setNewData} />

        <EmailForm
          getChangeEmailStatus={getChangeEmailStatus}
          setNewMail={setNewMail}
        />

        <PhoneForm
          getViewCodeParam={getViewCodeParam}
          setChangeCodeStatus={setChangeCodeStatus}
          getChangeCodeStatus={getChangeCodeStatus}
          sendNewPhoneCode={sendNewPhoneCode}
          setNewPhone={setNewPhone}
          changePhoneStatus={changePhoneStatus}
          setViewCodeField={setViewCodeField}
          setChangePhoneStatus={setChangePhoneStatus}
        />

        <PasswordForm
          getPasswordMessage={getPasswordMessage}
          setNewPassword={setNewPassword}
          getPasswordError={getPasswordError}
        />
        <EmergencyContact
          setNewData={setNewData}
          contactStatus={contactStatus}
        />
        <AdditionalForm
          loadDeliveryRegions={loadDeliveryRegions}
          regions={regions}
          setAdditionalNewData={setAdditionalNewData}
          setMessage={setMessageInfo}
          getDataMessage={messageInfo}
          getUserInfo={getUserInfo}
        />
      </Box>
    </>
  );
};
