import { CollapsibleCard } from "../CollapsibleCard";
import { theme } from "../../utils/theme";
import { Box, Grid, Grommet, Heading, Paragraph, Text } from "grommet";
import React from "react";
import styled from "styled-components";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export function FAQTab() {
  return (
    <Box gap="medium">
      <Box>
        <Box flex direction="row" justify="between">
          <Heading
            level={"3"}
            margin={{ top: "18px", bottom: "18px" }}
            style={{ textTransform: "uppercase" }}
          >
            Как получить разряд?
          </Heading>
        </Box>
        <CustomParagraph>
          <b>
            Если вы спортсмен-любитель, и у вас нет разряда, как его получить:
          </b>
          <ul>
            <li>
              • 2 и 3 разряд: на соревнованиях любого статуса, но не выше
              статуса всероссийских соревнований
            </li>
            <li>
              • 1 разряд: на соревнованиях уровня Первенство субъекта и ниже,
              Чемпионаты федеральных округов
            </li>
            <li>
              • КМС: на соревнованиях уровня не ниже статуса Первенства
              субъекта.
            </li>
            <li>
              <b>Дополнительные условия:</b>
            </li>
            <ul>
              <li>
                • Соревнование, на котором присваиваются разряды, должно быть
                внесено в ЕКП (единый календарный план Минспорта или региона).
              </li>
              <li>
                • На коммерческих стартах звания и разряды не присваиваются.
                Потому что эти старты внесены в физкультурный календарь.
              </li>
            </ul>
          </ul>
        </CustomParagraph>
        <CustomParagraph>
          После выполнения разряда вам нужно обратиться в региональную федерацию
          по виду спорта «триатлон» (если такая есть в вашем субъекте) для
          получения документа, подтверждающего ваш разряд.
        </CustomParagraph>
        <CustomParagraph>
          <b>Пакет документов для оформления разрядов:</b>
          <ul>
            <li>• заверенный протокол итогов старта;</li>
            <li>• копия регламента или положения спортивного мероприятия;</li>
            <li>• список судей и список субъектов;</li>
            <li>• 2 фотографии и копия паспорта;</li>
            <li>
              • копия документа, удостоверяющего принадлежность к
              физкультурно-спортивной организации (к спортивной школе или
              клубу).
            </li>
          </ul>
        </CustomParagraph>
        <CustomParagraph>
          Отметим, если вы хотите принимать участие в триатлоне на средней или
          длинной дистанции, вам необходимо иметь разряд, выполненный
          соответственно на длинной, средней или стандартной (олимпийской)
          дистанции.
        </CustomParagraph>
        <CustomParagraph>
          ФТР может допускать спортсменов с разрядом, выполненным на дистанции
          спринт, но с обязательным условием подтверждения результата протоколом
          на длинной или средней дистанции с любого старта по времени, не ниже
          заявленного уровня, прописанного в условиях допуска.
        </CustomParagraph>
      </Box>
    </Box>
  );
}
