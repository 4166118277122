import styled from "styled-components";

export const StyledForm = styled.form`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: ${(props) => (props.isFromVisible ? "3rem" : "0")};
`;
export const StyledErrorTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
export const StyledLabel = styled.span`
  @media (max-width: 768px) {
    font-size: 13px;
    margin-left: 3px;
  }
`;
