import React from "react";
import { IconSvg } from "../IconSvg";

export const ErrorInfo = ({ error, custom, mt = "", mb = "10px" }) => {
  return (
    <span
      style={{
        position: `${custom ? "relative" : "unset"}`,
        marginTop: mt,
        marginBottom: `${custom ? mb : "unset"}`,
      }}
    >
      {error ? (
        <>
          <span
            style={{
              position: "absolute",
              bottom: `${custom ? "unset" : "0"}`,
              top: `${custom ? "-1.5px" : "unset"}`,
            }}
          >
            <IconSvg src="errorIcon" />
          </span>
          <span style={{ marginLeft: "25px" }}>{error}</span>
        </>
      ) : null}
    </span>
  );
};
