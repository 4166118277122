import { Container } from "../../components/Container";
import React, { useEffect } from "react";
import coverImg from "../../images/cover4.jpg";
import { AmateurTriathlonTab } from "../../components/AmateurTriathlonTab";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";
import { observer } from "mobx-react";
import { useDataStore } from "../../hooks/useDataStore";

export const AmateurTriathlon = observer(() => {
  const { amateurTriathlon, loadATriathlonData } = useDataStore();

  useEffect(() => {
    loadATriathlonData();
  }, []);
  return (
    <div>
      <Banner cover={amateurTriathlon?.banners?.main} />

      <Container>
        <Breadcrumbs label={amateurTriathlon?.headline} />
        <AmateurTriathlonTab />
      </Container>
    </div>
  );
});
