import React from "react";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import styled from "styled-components";

import { routes } from "../../const/routes";

const StyledLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 18px;

  &:hover {
    opacity: 0.7;
  }
`;

export const ImageWithLink = ({
  image,
  paragraph,
  heading,
  link,
  extraInfo = false,
  as,
}) => {
  return (
    <>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            width={{ max: "684px", min: "auto" }}
            height={{ min: size === "small" ? "401px" : "464px" }}
            round="20px"
            background={{
              image: `linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8)), url(${image})`,
              size: `cover`,
            }}
          >
            <Box flex justify="end" pad="large" gap="small">
              <Heading level={3} color="white">
                {heading}
              </Heading>
              <Box gap="large">
                <Paragraph size="1rem" color="white" fill>
                  {paragraph}
                </Paragraph>
                {extraInfo ? (
                  <StyledLink as={as} to={link}>
                    Читать далее
                  </StyledLink>
                ) : (
                  <StyledLink as={as} to={`${routes.news.path}/${link}`}>
                    Читать далее
                  </StyledLink>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </>
  );
};
