import { Box, Button, Layer, Paragraph, Text } from "grommet";

export const FormPopup = ({ statePopupHandler }) => {
  const handleClosePopup = () => {
    statePopupHandler(false);
  };

  return (
    <Layer
      position="center"
      onClickOutside={handleClosePopup}
      onEsc={handleClosePopup}
    >
      <Box pad="large" width="medium">
        <Text style={{ fontWeight: "normal", textAlign: "center" }}>
          <Paragraph>Ваша заявка отправлена. </Paragraph>
          <Paragraph>Спасибо</Paragraph>
        </Text>

        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="center"
          pad={{ top: "medium" }}
        >
          <Button
            label={
              <Text color="white" size="medium">
                Закрыть
              </Text>
            }
            onClick={handleClosePopup}
            primary
            color="#DB4C4C"
          />
        </Box>
      </Box>
    </Layer>
  );
};
