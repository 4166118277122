import { makeAutoObservable, observable } from "mobx";

export class RegionsStore {
  regionsData = [];
  document = [];
  documentsData = [];
  partnersData = "";
  partnersError = "";
  locationInfo = {};
  deliveryCitiesData = [];

  setRegions = (regions) => {
    this.regionsData = regions;
  };
  setError = (error) => {
    this.partnersError = error;
  };
  setDocument = (document) => {
    this.document = document;
  };
  setDocuments = (documents) => {
    this.documentsData = documents;
  };
  setData = (data) => {
    this.partnersData = data;
  };
  setLocation = (info) => {
    this.locationInfo = info;
  };
  setDeliveryCities = (cities) => {
    this.deliveryCitiesData = cities;
  };

  getAllRegions = async () => {
    try {
      const { data } = await this.api.get("/api/branches/list");
      this.setRegions(data);
    } catch (error) {
      console.log(error);
    }
  };

  regionsDocuments = async () => {
    try {
      const { data } = await this.api.get("/api/files/section/regions");
      this.setDocument(data.values);
    } catch (error) {
      console.log(error);
    }
  };
  internationalDocuments = async () => {
    try {
      const { data } = await this.api.get("/api/files/section/world");
      this.setDocument(data.values);
    } catch (error) {
      console.log(error);
    }
  };
  childDocuments = async () => {
    try {
      const { data } = await this.api.get("/api/files/section/child");
      this.setDocument(data.values);
    } catch (error) {
      console.log(error);
    }
  };
  amateurDocuments = async () => {
    try {
      const { data } = await this.api.get("/api/files/section/amateur");
      this.setDocument(data.values);
    } catch (error) {
      console.log(error);
    }
  };
  antiDopingDocuments = async () => {
    try {
      const { data } = await this.api.get("/api/files/section/antidoping");
      this.setDocument(data.values);
    } catch (error) {
      console.log(error);
    }
  };

  loadData = async () => {
    try {
      const response = await this.api.get("/server/document/list");
      if (response.status === 200) this.setDocuments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  loadDocuments = async (type) => {
    try {
      const response = await this.api.get(`/api/files/section/${type}`);
      if (response.status === 200) this.setDocuments(response.data.values);
    } catch (error) {
      console.error(error);
    }
  };

  sendPartnersData = async (partnersData) => {
    try {
      const response = await this.api.post("/api/support", partnersData);
      this.setData(response.data);
    } catch (error) {
      this.setError(error);
      console.log(error);
    }
  };

  getLocation = async () => {
    const response = await this.api.get(`api/location/detailed`);

    if (response.status === 200) {
      this.setLocation(response.data.info);
    }
  };

  loadDeliveryCities = async (city_id) => {
    const response = await this.api.get(`/api/location/city/region/${city_id}`);
    if (response.status === 200) {
      this.setDeliveryCities(response.data.values);
    }
  };

  get regions() {
    return this.regionsData;
  }
  get items() {
    return this.document;
  }
  get documents() {
    return this.documentsData;
  }
  get data() {
    return this.partnersData;
  }
  get isError() {
    return this.partnersError;
  }

  get location() {
    return this.locationInfo;
  }

  get deliveryCities() {
    return this.deliveryCitiesData;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref });
  }
}
