import styled from "styled-components";
import { TextInput } from "grommet";
import { IconSvg } from "../../IconSvg";

export const StyledTextInput = styled(TextInput)`
  height: 56px;
  width: 100%;
  font-style: italic;
  color: black;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 14px 10px 14px 45px;
  background-color: transparent;
  font-size: 16px;
  border-color: ${(props) => props.error && "#CA3C3C"};
`;

export const StyledFormField = styled.label`
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
  margin-bottom: 15px;
  opacity: ${(props) => props.disabled && "0.5"};
  color: ${(props) => props.error && "#CA3C3C"};
  cursor: pointer;
`;

export const StyledIconSvg = styled(IconSvg)`
  position: absolute;
  left: ${(props) => props.iconLeft && props.iconLeft};
  top: ${(props) => props.iconTop && props.iconTop};
  color: ${(props) => props.error && "#CA3C3C"};
  opacity: ${(props) => props.disabled && "0.5"};
`;

export const StyledBox = styled.div`
  position: relative;
`;
