import React from "react";
import { Box } from "grommet";
import {
  StyledTextInput,
  StyledIconSvg,
  StyledFormField,
  StyledBox,
} from "./styles";
import { ErrorInfo } from "../../ErrorInfo";

export const PasswordInput = ({
  error = "",
  name,
  label = "Введите пароль",
  id,
  defaultValue,
  disabled = false,
  maxWidth = "406px",
  iconSrc = "",
  iconColor = "",
  iconWidth = "",
  iconHeight = "",
  iconLeft = "10px",
  iconTop = "14px",
  ...rest
}) => {
  return (
    <Box flex width={{ max: maxWidth }}>
      <StyledFormField error={error} disabled={disabled} htmlFor={id}>
        {label}
      </StyledFormField>
      <StyledBox>
        <StyledIconSvg
          error={error}
          iconLeft={iconLeft}
          iconTop={iconTop}
          src={iconSrc}
          width={iconWidth}
          height={iconHeight}
          color={iconColor}
          disabled={disabled}
        />

        <StyledTextInput
          focusIndicator={true}
          plain
          type={"password"}
          error={error}
          disabled={disabled}
          defaultValue={defaultValue}
          name={name}
          id={id}
          {...rest}
        />
        <div style={{ marginTop: "10px" }}>
          <span style={{ position: "relative" }}>
            {error && <ErrorInfo error={error} />}
          </span>
        </div>
      </StyledBox>
    </Box>
  );
};
