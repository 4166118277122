import { Heading, Paragraph } from "grommet";
import styled from "styled-components";
import { Article } from "..";
import articleImg1 from "../../../images/webp/disciplines.webp";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

export const Disciplines = () => {
  return (
    <Article
      title={"Дисциплины"}
      desc={"В каких форматах можно участвовать"}
      coverBack={articleImg1}
    >
      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        ДУАТЛОН
      </Heading>

      <CustomParagraph>
        Традиционные соревнования по дуатлону включают в себя три этапа: бег,
        велогонка, бег.
      </CustomParagraph>
      <CustomParagraph>
        Дистанция каждого из них определяется регламентом стартов и возрастным
        составом участников, от 1:5:1 до 20:80:10. Самый массовый забег по
        дуалону прошел в 2005 году в Лондоне, на нем было более 4000 участников.
      </CustomParagraph>
      <CustomParagraph>
        В последнее время появляется новое направление в любительском дуатлоне,
        этапы проходят по пересеченной местности, с использованием горных
        велосипедов. Так же проводятся командные соревнования по типу эстафеты
        или совместного прохождения дистанции.
      </CustomParagraph>
      <CustomParagraph>
        На мировом уровне организатором дуатлонистов выступает Международный
        союз триатлона (ITU), а в РФ ответственность за координацию возложена на
        Федерацию триатлона России (ФТР).
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        ЗИМНИЙ ТРИАТЛОН
      </Heading>

      <CustomParagraph>
        Всплеск интереса к зимнему триатлону произошел в конце XX века.
      </CustomParagraph>
      <CustomParagraph>
        Практически одновременно, в 1995 году, состоялись старты в итальянском
        Тироле и Санкт-Петербурге. Но еще до этого энтузиасты проводили
        соревнования в США и Европе, куда съезжались любители зимнего бега,
        велосипеда и лыж. На некоторых из них был и четвертый, конькобежный
        этап.
      </CustomParagraph>
      <CustomParagraph>
        Сегодня Международный союз триатлона (ITU) регламентировал проведение
        официальных стартов бегом на дистанцию от 2 км, велозаездом минимум на 4
        км, и лыжным этапом от 3 км. На данный момент идет речь о включении
        триатлона в программу Зимних олимпийских игр.
      </CustomParagraph>
      <CustomParagraph>
        Благодаря доступности трасс и снаряжения зимний триатлон набирает
        популярность в странах с суровым климатом – Норвегии, Финляндии, и
        конечно же России.
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        ПАРАТРИАТЛОН
      </Heading>

      <CustomParagraph>
        Спортсмены с ограниченными возможностями могут заниматься паратриатлоном
        в различных категориях, которые разделяются по степени доступности
        прохождения этапов.
      </CustomParagraph>
      <CustomParagraph>
        Применяются специальные велосипеды с ручным приводом или инвалидные
        коляски. Слабовидящие могут пользоваться помощью проводника, а велоэтап
        проходит на тандемах.
      </CustomParagraph>
      <CustomParagraph>
        Первый чемпионат мира прошел в 2007 году в Гамбурге, а уже в 2012 году
        прошел первый Чемпионат России по паратриатлону, где встретились 20
        спортсменов. На Паралимпиаде в РИО-2016 впервые люди с ограниченными
        возможностями смогли помериться силами на трех дистанциях.
      </CustomParagraph>
      <CustomParagraph>
        Особое внимание в регламентах соревнований уделяется работе ассистентов,
        которые оказывают техническую помощь на дистанции.
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        АКВАТЛОН
      </Heading>

      <CustomParagraph>
        Бег, плавание и бег – именно так строятся классические соревнования по
        акватлону, которые проводятся под эгидой ITU и ФТР.
      </CustomParagraph>
      <CustomParagraph>
        Они проходят как на открытой местности, так и в бассейне, что позволяет
        круглогодично проводить старты и тренировки.
      </CustomParagraph>
      <CustomParagraph>
        Отсутствие спортивных снарядов делает его самым общедоступным видом
        спорта, который оказывает комплексное влияние на развитие организма
        человека. На чемпионате мира стандартной дистанцией является бег на 2,5
        км, плавание 1 км и снова бег на 2,5 км. В 2013 году чемпионкой мира
        стала россиянка Ирина Абысова, а в 2015 году этот титул завоевала
        Анастасия Абросимова.
      </CustomParagraph>
      <CustomParagraph>
        Универсальность этого вида спорта делает его весьма популярным в России.
        Любительские и официальные соревнования проводятся в Москве, Нижнем
        Новгороде, Сочи и Казани.
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        КРОСС-ТРИАТЛОН
      </Heading>

      <CustomParagraph>
        Особенностью кросс-триатлона стало проведение соревнований на
        пересеченной местности и трассам без дорожного покрытия.
      </CustomParagraph>
      <CustomParagraph>
        Плавание на открытой воде, велоэтап на горных велосипедах и кросс по
        неровным дорогам, песку и лужам. Этот вид спорта придуман для любителей
        экстрима, поэтому здесь нет четко определенной длины этапа. На
        Чемпионате России были пройдены следующие расстояния: плавание – 1 км,
        велоэтап – 24 км, бег – 8,5 км.
      </CustomParagraph>
      <CustomParagraph>
        Популярность кросс-триатлона обусловлена его зрелищностью и
        непредсказуемостью. Даже новички при должной подготовке показывают
        неплохие результаты на Внедорожном триатлоне, а прекрасные пейзажи
        только увеличивают удовольствие от гонки на грунте.
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        ДЛИННАЯ ДИСТАНЦИЯ
      </Heading>

      <CustomParagraph>
        Впервые соревнования по триатлону на длинную дистанцию, которая сейчас
        называется классической, прошли 18 февраля 1978 года. Бегуны,
        велосипедисты и пловцы решили выяснить, кто же из них окажется самым
        выносливым.
      </CustomParagraph>
      <CustomParagraph>
        На старт вышли 15 человек, а победителем стал специалист по
        коммуникациям, который не был профессиональным спортсменом.
      </CustomParagraph>
      <CustomParagraph>
        В 1984 году в Советском Союзе прошли первые любительские соревнования на
        длинной дистанции. В 1989 году появилась Федерация триатлона СССР, и уже
        1994 ознаменовался проведением первого чемпионата России.
      </CustomParagraph>
      <CustomParagraph>
        В последние годы этот вид спорта набирает популярность, проводятся
        старты в подмосковных Бронницах и Казани, Сочи и Выборге, других
        городах. Многие россияне принимают участие в мировой серии Ironman, где
        показывают хорошие результаты.
      </CustomParagraph>
    </Article>
  );
};
