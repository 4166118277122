import { Box, List, Text } from "grommet";
import coverImg from "../../images/webp/cover4.webp";
import { Container } from "../../components/Container";
import { Partners } from "../../components/Partners";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";

export const Contacts = () => {
  const contactsArr = [
    { label: "Общероссийская общественная организация" },
    { label: "«Федерация триатлона России»" },
    { label: "Сокращенное наименование: ООО «ФТР»" },
    { label: "Email: office@rustriathlon.ru" },
    { label: "Партнёрство: partners@rustriathlon.ru" },
    {
      label: "Юридический адрес: 119991, г. Москва, Лужнецкая набережная, д.8 ",
    },
  ];

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box style={{ maxWidth: "1190px" }}>
          <List data={contactsArr} pad="medium" border={false}>
            {(contactsArr) => (
              <Box direction="row-responsive" gap="medium" align="center">
                <Text>{contactsArr.label}</Text>
              </Box>
            )}
          </List>
        </Box>

        {/*     <Partners /> */}
      </Container>
    </>
  );
};
