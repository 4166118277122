import { Anchor, Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import { Attachment } from "grommet-icons";
import styled from "styled-components";

const CustomAnchor = styled(Anchor)`
  text-decoration: underline;
  color: black;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;

const CustomHeading = styled(Heading)`
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
`;

export const DocumentItem = ({ title, date, link }) => {
  return (
    <Box>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            flex
            direction="row"
            align={size === "small" ? "start" : "center"}
            gap="1rem"
          >
            <CustomAnchor href={link}>
              <Box
                width="80px"
                height="80px"
                style={{ borderRadius: "20px" }}
                background="#C4C4C4"
                justify="center"
                align="center"
              >
                <Attachment />
              </Box>
            </CustomAnchor>
            <Box flex direction="column" gap="8px">
              <CustomAnchor href={link}>
                {<CustomHeading>{title}</CustomHeading>}
              </CustomAnchor>
              <Paragraph size="0.675rem">{date}</Paragraph>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Box>
  );
};
