import { Box, Heading, Paragraph } from "grommet";
import React from "react";
import styled from "styled-components";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export const ChildrensLeague = () => {
  return (
    <Box gap="medium">
      <Box>
        <Box flex direction="row" justify="between">
          <Heading
            level={"3"}
            margin={{ top: "18px", bottom: "18px" }}
            style={{ textTransform: "uppercase" }}
          >
            Лига Триатлона. Дети{" "}
          </Heading>
        </Box>
        <CustomParagraph>
          Развитие детско-юношеского спорта является одной из приоритетных задач
          Федерации триатлона России. В планах ФТР — способствовать открытию
          новых отделений, разрабатывать научно-методические материалы,
          привлекать квалифицированных специалистов, проводить детские
          любительские соревнования на федеральном и региональном уровнях.
        </CustomParagraph>

        <CustomParagraph>
          В 2022 году Федерация внедряет уникальный проект «Детская
          Лига»,который создаст базу для будущих достижений и выведет триатлон в
          России на новый уровень. Так, в рамках региональных и Всероссийский
          первенств в течение года будут организованы 7 этапов соревнований в
          форматах триатлона, акватлона и зимнего дуатлона. География стартов
          впечатляет: Москва, Ярославль,Казань, Волгоград и Альметьевск.
          Участники будут разделены на три возрастные категории: 7-8, 9-10 и
          11-12 лет. На каждом этапе(кроме категории 7-8 лет) юным атлетам будут
          начисляться очки. Победитель определится по сумме набранных очков за 4
          лучших старта в году.
          <P>
            Календарь этапов Детской Лиги на 2022 году же опубликован на сайте
            ФТР в разделе «Дети».
          </P>
        </CustomParagraph>
        <CustomParagraph>
          В этом году у ФТР и детского триатлона также появился новый символ -
          снежный барс. Он олицетворяет все три дисциплины, входящие в триатлон:
          плавание, бег и велоспорт. Символ был выбран путем голосования в
          социальных сетях.
        </CustomParagraph>
      </Box>
    </Box>
  );
};
