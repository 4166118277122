import React from "react";
import { StyledInput, StyledLabel, StyledRadioButton } from "./styles";

export const RoundCheckBox = ({ label, id, name, type = "radio", ...rest }) => {
  return (
    <>
      <StyledInput type={type} name={name} id={id} {...rest} />
      <StyledLabel htmlFor={id}>
        <StyledRadioButton></StyledRadioButton> {label}
      </StyledLabel>
    </>
  );
};
