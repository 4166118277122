import React from "react";
import { Box } from "grommet";
import {
  StyledTextInput,
  StyledFormField,
  StyledIconSvg,
  StyledBox,
} from "./styles";
import { ErrorInfo } from "../../ErrorInfo";

export const TextInput = ({
  error = "",
  handleChange = () => {},
  handleFocus = () => {},
  name = "",
  label = "",
  id = Date.now(),
  disabled = false,
  maxWidth = "406px",
  marginErrorBottom = "",
  type = "text",
  iconSrc = "",
  iconColor = "",
  iconWidth = "",
  iconHeight = "",
  iconLeft = "10px",
  iconTop = "14px",
  isFromVisible = true,
  handleLabelClick = () => {},
  ...rest
}) => {
  return (
    <Box flex width={{ max: maxWidth }}>
      <StyledFormField
        onClick={handleLabelClick}
        error={error}
        disabled={disabled}
        htmlFor={id}
      >
        {label}
      </StyledFormField>
      {isFromVisible && (
        <StyledBox>
          {iconSrc && (
            <StyledIconSvg
              error={error}
              iconLeft={iconLeft}
              iconTop={iconTop}
              src={iconSrc}
              width={iconWidth}
              height={iconHeight}
              color={iconColor}
              disabled={disabled}
            />
          )}
          <StyledTextInput
            id={id}
            error={error}
            type={type}
            onFocus={handleFocus}
            onChange={handleChange}
            disabled={disabled}
            autoComplete="off"
            name={name}
            plain
            textAlign="start"
            focusIndicator={true}
            {...rest}
          />
          <div style={{ marginTop: "10px", marginBottom: marginErrorBottom }}>
            <span style={{ position: "relative" }}>
              {error && <ErrorInfo error={error} />}
            </span>
          </div>
        </StyledBox>
      )}
    </Box>
  );
};
