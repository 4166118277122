import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";
import { NameSpace } from "../const/namespace";

export const theme = deepMerge(grommet, {
  global: {
    breakpoints: {
      [NameSpace.mobile]: {
        value: 576,
      },
      [NameSpace.tablet]: {
        value: 991,
      },
      [NameSpace.desktop1200]: {
        value: 1200,
      },
      [NameSpace.desktop1900]: {
        value: 3000,
      },
    },

    font: {
      family: "Gotham Pro",
      size: "0.875rem",
      height: "1.2",
      fontStyle: "normal",
    },

    colors: {
      brand: "#DB4C4C",
      focus: "#DB4C4C",
    },

    input: {
      font: {
        weight: 400,
      },
    },
  },

  formField: {
    button: {
      color: "#000000",
    },

    label: {
      size: "small",
    },
    border: "none",
  },

  heading: {
    weight: 700,

    level: {
      2: {
        medium: {
          size: "38px",
          height: "32px",
        },
      },
    },
  },

  button: {
    border: {
      width: 0,
      radius: "100px",
    },

    extend: (props) => `
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.5px;
    `,
  },

  input: {
    fontSize: "14px",
    padding: "0.75rem 1rem",
    borderRadius: "0.625rem",
    outline: "none",
    boxShadow: "none",
    border: "1px solid #3d3d3d",
  },
  tabs: {
    extend: (props) => `
      padding: 6px 22px;
      margin: 0 20px;
      
    `,
  },

  fileInput: {
    icons: {
      remove: "none",
    },
  },

  anchor: {
    textDecoration: "none",
    hover: {
      textDecoration: "none",
    },
  },
});
