export const event_type_filter = [
  { value: "all", name: "Все" },
  { value: "ekp", name: "Единый календарный план" },
  { value: "ru", name: "Любительские старты" },
  { value: "child", name: "Лига Триатлона. Дети" },
  { value: "childstart", name: "Детские старты" },
  { value: "series4", name: "Кубок 4 колец" },
  // { value: "sups", name: "Суперсерия" },
  { value: "regions", name: "Региональные соревнования" },
];

export const event_subtype = [
  { value: "all", name: "Все" },
  { value: "triathlon", name: "Триатлон" },
  { value: "crosstria", name: "Триатлон-Кросс" },
  { value: "zimtria", name: "Триатлон Зимний" },
  { value: "triaesta", name: "Триатлон эстафета" },
  { value: "duathlon", name: "Дуатлон" },
  { value: "crossduathlon", name: "Дуатлон-Кросс" },
  { value: "zimduathlon", name: "Дуатлон Зимний" },
  { value: "duathlonesta", name: "Дуатлон эстафета" },
  { value: "aquathlon", name: "Акватлон" },
];

export const distance = [
  { value: "all", name: "Все" },
  { value: "sprint", name: "Спринт" },
  { value: "supersprint", name: "Суперспринт" },
  { value: "olympic", name: "Стандартная" },
  { value: "middle", name: "Средняя" },
  { value: "long", name: "Длинная" },
];

export const category = [
  { value: "all", name: "Все" },
  { value: "m", name: "М" },
  { value: "f", name: "Ж" },
  { value: "5-6", name: "5-6" },
  { value: "7-8", name: "7-8" },
  { value: "9-10", name: "9-10" },
  { value: "11-12", name: "11-12" },
  { value: "13-14", name: "13-14" },
  { value: "15-17", name: "15-17" },
  { value: "16-19", name: "16-19" },
  { value: "18-23", name: "18-23" },
];

export const status = [
  { value: "all", name: "Все" },
  { value: "pervensvo", name: "Первенство" },
  { value: "championship", name: "Чемпионат" },
  { value: "allrussia", name: "Всероссийские соревнования" },
  { value: "etaprussiacup", name: "Этап Кубка России" },
  { value: "russiacup", name: "Кубок России" },
  // { value: "etapftrcup", name: "Этап Кубка ФТР" },
  // { value: "cupftr", name: "Кубок ФТР" },
];
