import { Box, Heading, Paragraph } from "grommet";
import { observer } from "mobx-react";
import { useDataStore } from "../../hooks/useDataStore";
import React, { useEffect } from "react";
import styled from "styled-components";
import renderHTML from "react-render-html";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

export const CupTab = observer(() => {
  const { corpcup, loadCorpCup } = useDataStore();

  useEffect(() => {
    loadCorpCup();
  }, []);
  return (
    <Box gap="medium">
      <Box>
        <Box flex direction="row" justify="between">
          {corpcup?.subtitle && (
            <Heading
              level={"3"}
              margin={{ top: "18px", bottom: "18px" }}
              style={{ textTransform: "uppercase" }}
            >
              {corpcup?.subtitle}
            </Heading>
          )}
        </Box>
        <Paragraph
          fill
          style={{
            lineHeight: "1.5",
            whiteSpace: "pre-line",
          }}
        >
          {corpcup?.text && renderHTML(corpcup?.text)}
        </Paragraph>
      </Box>
    </Box>
  );
});
