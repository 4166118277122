import styled from "styled-components";
import { Box, Paragraph, ResponsiveContext } from "grommet";
import { Link } from "react-router-dom";
import { routes } from "../../../const/routes";
import { useContext } from "react";

export const StyledLink = styled(Link)`
  color: rgb(219 76 76);
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledParagraph = styled(Paragraph)`
  position: relative;
  margin-top: 10px;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: ${(props) => (props.isMobileWidth ? "none" : "block")};
    top: 50%;
    right: 0;
    width: 2px;
    height: 80%;
    transform: translateY(-50%);
    background-color: currentColor;
    opacity: 0.6;
  }
`;

export const documentsLinks = [
  { link: `main`, title: `Основные документы` },
  { link: `criterias`, title: `Критерии отбора` },
  // { link: `protocol`, title: `Протоколы соревнований` },
  { link: `archive`, title: `Архив документов` },
  { link: `ratings`, title: `Рейтинги спортсменов` },
  { link: `triathlonteka`, title: `Триатлонотека` },
  { link: `erai`, title: `ППК ЕРАИ` },
];

export const Navigation = () => {
  const size = useContext(ResponsiveContext);

  return (
    <Box direction={"row"}>
      <Box
        direction={size === "tablet" || size === "small" ? "column" : "row"}
        width={"max-content"}
        margin={{ left: size === "small" ? "5%" : "auto", right: "auto" }}
      >
        {documentsLinks.map((link) => (
          <StyledParagraph
            isMobileWidth={size === "tablet" || size === "small"}
            key={link.title}
            style={{ paddingRight: "10px", marginRight: "10px" }}
          >
            <StyledLink to={`${routes.documents.path}/${link.link}`}>
              {link.title}
            </StyledLink>
          </StyledParagraph>
        ))}
      </Box>
    </Box>
  );
};
