import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { times } from "../../utils/date";
import { routes } from "../../const/routes";
import { getOrderStatus, getOrderStatusIcon } from "../../utils/orders";
import { Button } from "grommet";

export const OrderCard = ({ item }) => {
  return (
    <li className={styles.item}>
      <div className={styles.itemWrapper}>
        <div className={styles.collumnLeft}>
          <p className={styles.info}>
            <span className={styles.itemLabel}>Заказ от:</span>
            {times.getNumberDate(item.created)}
          </p>
          <div className={styles.test}>
            <img
              className={styles.icon}
              src={getOrderStatusIcon(item.status)}
              alt="Статус заказа"
            />
            {item.status ? (
              <p className={styles.info}>
                <span className={styles.itemLabel}>Статус заказа:</span>
                {getOrderStatus(item.status)}
              </p>
            ) : null}
          </div>
          <div className={`${styles.test} ${styles.sumWrap}`}>
            <p className={styles.info}>
              <span className={styles.itemLabel}>Количество товаров:</span>
              {item.items.shop.length}
            </p>
            <p className={styles.info}>
              <span className={styles.itemLabel}>Сумма заказа:</span>
              {item.amount} Руб.
            </p>
          </div>
        </div>
        <Button
          primary
          style={{ textAlign: "center", paddingTop: "5px" }}
          as={Link}
          className={styles.btn}
          to={`${routes.me.path}/shop/${item.public_id}`}
        >
          Подробнее о заказе
        </Button>
      </div>
      <div className={styles.cover}>
        <img
          className={styles.coverImg}
          src="https://herodev.ru/files/order_picture1.png"
          alt="Order Logo"
        />
      </div>
    </li>
  );
};
