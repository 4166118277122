import styled from "styled-components";

export const StyledSelect = styled.select`
  border: 1px solid black;
  background-color: transparent;
  font-size: 16px;
  border-radius: 8px;

  height: 56px;
  width: 100%;
  padding: 14px 10px 14px 45px;

  opacity: ${(props) => props.disabled && "0.5"};
`;

export const StyledOption = styled.option`
  option:hover {
    color: yellow;
  }
`;

export const StyledBox = styled.div`
  max-width: 406px;
`;
