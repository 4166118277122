import React, { useContext } from "react";
import { Box, Heading, ResponsiveContext, Select } from "grommet";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeValidationSchema } from "./makeValidationSchema";
import { TextInput } from "../../Inputs/TextInput";
import { InputPhone } from "../../Inputs/PhoneInput";
import { optionsPerson } from "../../../mocks/calendar";
import styled, { createGlobalStyle } from "styled-components";
import { IconSvg } from "../../IconSvg";
import { ErrorInfo } from "../../ErrorInfo";
import { DefaultButton } from "../../DefaultButton";

export const StyledSelect = styled(Select)`
  padding: 21px 50px;
  color: black;
`;

const GlobalStyle = createGlobalStyle`
.cKXNfT{
    border: 1px solid #000000;
    border-radius: 8px;
    border-color: ${(props) => (props.error ? "#CA3C3C" : "#000000")}
  }
`;

export const EmergencyContact = ({ setNewData, contactStatus }) => {
  const validateForm = makeValidationSchema();

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
  });

  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  const onSubmit = ({ extra_contact, contact_phone, type_relate }) =>
    setNewData({
      extra_contact,
      contact_phone: contact_phone.slice(1),
      type_relate,
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GlobalStyle error={errors.type_relate?.message} />
      <Box>
        <Heading
          level={3}
          margin={{ top: "24px", bottom: "40px" }}
          style={{ color: "black" }}
        >
          Экстренный контакт
        </Heading>
        <Box flex gap={isMobileWidth ? "1rem" : "2rem"}>
          <Box flex direction={isMobileWidth ? "column" : "row"} gap="50px">
            <Controller
              name="extra_contact"
              control={control}
              render={({ field }) => (
                <TextInput
                  error={errors.extra_contact?.message}
                  handleFocus={() => clearErrors("extra_contact")}
                  handleChange={(e) => setValue(e.target.value)}
                  name="extra_contact"
                  iconSrc="rect"
                  iconHeight="40px"
                  iconLeft="20px"
                  iconTop="8px"
                  id="extra_contact"
                  label="Имя контакта"
                  {...field}
                />
              )}
            />
            <Controller
              name="contact_phone"
              control={control}
              render={({ field }) => (
                <InputPhone
                  country="RU"
                  international={true}
                  withCountryCallingCode={true}
                  error={errors.contact_phone?.message}
                  id="contact_phone"
                  label="Номер телефона контакта"
                  name="contact_phone"
                  onFocus={() => clearErrors("contact_phone")}
                  iconSrc="rect"
                  iconHeight="40px"
                  iconLeft="20px"
                  iconTop="8px"
                  {...field}
                />
              )}
            />
          </Box>
          <Box flex direction={isMobileWidth ? "column" : "row"}>
            <Box style={{ width: "410px", minWidth: "300px" }}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  position: "relative",
                }}
              >
                <span
                  style={{ position: "absolute", top: "40px", left: "21px" }}
                >
                  <IconSvg
                    color={errors.type_relate?.message ? "#CA3C3C" : "black"}
                    src="rect"
                    height="40px"
                    width={false}
                  />
                </span>
                <span
                  style={{ position: "absolute", top: "33.5px", right: "45px" }}
                >
                  <IconSvg
                    color={errors.type_relate?.message ? "#CA3C3C" : "black"}
                    src="rect"
                    height="55.5px"
                    width={false}
                  />
                </span>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: `${
                      errors.type_relate?.message ? "#CA3C3C" : "black"
                    }`,
                  }}
                >
                  Отношения
                </span>
                <Controller
                  control={control}
                  name="type_relate"
                  render={({ field: { onChange } }) => (
                    <StyledSelect
                      icon={
                        <span style={{ marginRight: "10px" }}>
                          <IconSvg src="dropButton" />
                        </span>
                      }
                      onFocus={() => clearErrors("type_relate")}
                      onChange={({ value }) => onChange(value)}
                      valueKey={{ key: "value", reduce: true }}
                      labelKey="label"
                      options={optionsPerson}
                      placeholder="Отношения"
                    />
                  )}
                />
              </label>
              <span style={{ marginTop: "10px", position: "relative" }}>
                <ErrorInfo error={errors.type_relate?.message} />
              </span>
            </Box>
            <span
              style={{
                marginTop: `${isMobileWidth ? "20px" : "50px"}`,
                marginLeft: "55px",
              }}
            >
              {contactStatus && contactStatus}
            </span>
          </Box>
          <Box
            width={{ max: "406px" }}
            margin={{ top: isMobileWidth && "20px" }}
          >
            <DefaultButton
              type="submit"
              style={{
                padding: "10px",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                height: "56px",
              }}
            >
              Сохранить
            </DefaultButton>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
