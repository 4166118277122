import moment from "moment-timezone";

export const getDate = (data, multiplier = 1000) => {
  const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];
  const monthsInit = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  const days = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];

  let time = {};
  let unixTime = new Date(data * multiplier);

  time.year = unixTime.getFullYear();
  time.month = months[unixTime.getMonth()];
  time.initMonth = monthsInit[unixTime.getMonth()];
  time.numMonth = unixTime.getMonth();
  time.date = unixTime.getDate();
  time.day = days[unixTime.getDay()];
  time.hour = unixTime.getHours();
  time.minute = unixTime.getMinutes();

  return time;
};

export const times = {
  getTime(date = 1) {
    let obj = getDate(date);
    const minute =
      obj.minute.toString().length === 1 ? "0" + obj.minute : obj.minute;
    let str = obj.hour + ":" + minute;
    return str;
  },

  getDay(date = 1) {
    let obj = getDate(date);
    let str = obj.day;
    return str;
  },
  getNumberFullDate(date = 1) {
    let obj = getDate(date);
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}` : obj.numMonth + 1;
    let year = obj.year.toString();

    return `${day}.${month}.${year}`;
  },
  getDayOfWeek(date = 1) {
    let obj = getDate(date);
    let str = `${obj.date} ${obj.month}`;
    return str;
  },

  getFullDate(date = 1) {
    let obj = getDate(date);
    let str = `${obj.date} ${obj.month} ${obj.year}`;
    return str;
  },

  getNumberDate(date = 1) {
    let obj = getDate(date);
    let day = obj.date < 10 ? `0${obj.date}` : obj.date;
    let month = obj.numMonth < 9 ? `0${obj.numMonth + 1}` : obj.numMonth + 1;
    let year = obj.year.toString().slice(2, 4);

    return `${day}.${month}.${year}`;
  },

  getYear(date = 1, m) {
    let obj = getDate(date, m);
    let year = obj.year;

    return year;
  },
};

export const getCurrentPrice = (prices) => {
  const currentTime = Date.now() / 1000;
  return prices.find((p) => currentTime < p.start_date);
};

export const checkRegDate = (date, multiplier = 1000) => {
  return new Date() > new Date(date * multiplier);
};
export const getFormatTime = (ticket) => {
  const time = moment.tz(
    ticket.event_format.start_time * 1000,
    ticket.city.timezone
  );

  return `${times.getDayOfWeek(ticket.event_format.start_time)} / ${time.format(
    `HH:mm`
  )}`;
};
export const getEventTime = (event, format = `D MMMM`) => {
  const time = moment.tz(event?.start_time * 1000, event?.timezone);

  return `${time.format(format)}`;
};
