import { Anchor, Box, Image } from "grommet";
import iconVk from "../../../images/triathlon_vk.svg";
import iconTelega from "../../../images/triathlon_telegram.svg";

const SocialMedia = () => {
  return (
    <Box direction="row" align="center">
      <Anchor
        href="https://vk.com/rustriathlon"
        icon={
          <Image src={iconVk} style={{ maxWidth: "24px" }} alt="Вконтакте" />
        }
        target={"_blank"}
        rel={"noopener noreferrer"}
      />
      <Anchor
        href="https://t.me/rustriathlon"
        icon={
          <Image src={iconTelega} style={{ maxWidth: "24px" }} alt="Телеграм" />
        }
        target={"_blank"}
        rel={"noopener noreferrer"}
      />
    </Box>
  );
};

export default SocialMedia;
