import {
  Box,
  ResponsiveContext,
  Anchor,
  Button,
  Layer,
  Accordion,
  AccordionPanel,
  Menu,
  Text,
} from "grommet";
import { Menu as IconMenu, FormClose } from "grommet-icons";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { routes } from "../../const/routes";
import { MainLogo } from "./MainLogo";
import { IconSvg } from "../IconSvg";
import { observer } from "mobx-react";
import { useMembersStore } from "../../hooks/useMembersStore";
import { useBasketStore } from "../../hooks/useBasketStore";

const CustomLink = styled(NavLink)`
  color: #444;
  padding: 14px;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.33);

  &:hover {
    color: #444444b0;
  }
`;

const ModalMenuItem = styled(AccordionPanel)``;

const ModalMenuSubItem = styled(Anchor)`
  padding: 8px 20px;
  font-size: 16px;
  color: #444444;
`;

const MenuNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: 12px;
  color: #444444;

  &:hover {
    text-decoration: underline;
  }
`;

const BasketNavLink = styled(NavLink)`
  text-decoration: none;
`;

const CustomExternalLink = styled("a")`
  font-weight: 600;
  color: #db4c4c;
  text-decoration: none;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const AnchorMobile = styled("a")`
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  color: #db4c4c;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

const BasketCounter = styled(Text)`
  padding: 2px 6px;
  font-size: 12px;
  color: #ffffff;
  background-color: #db4c4c;
  font-weight: 400;
  border-radius: 10px;
`;

const MenuItems = [
  {
    name: "Главная",
    array: [
      {
        path: routes.news.path,
        name: "Новости",
      },
      {
        path: routes.whereToStart.path,
        name: "Как начать?",
      },
      {
        path: routes.historyTria.path,
        name: "История",
      },
      // {
      //   path: routes.coachingStaff.path,
      //   name: "Тренерский состав",
      // },
      {
        path: routes.disciplines.path,
        name: "Дисциплины",
      },
      {
        path: `${routes.pages.path}/education`,
        name: "Образование",
      },
      {
        path: routes.olympicReserve.path,
        name: "Олимпийский резерв",
      },
      {
        path: routes.judgment.path,
        name: "Судейский корпус",
      },
      {
        path: routes.amateurTriathlon.path,
        name: "Любительский триатлон",
      },
    ],
  },
  {
    name: "Сборные",
    array: [],
  },
  {
    name: "Календарь",
    array: [
      {
        path: `${routes.calendar.path}/all`,
        name: "Все",
      },
      {
        path: `${routes.calendar.path}/ekp`,
        name: "Единый календарный план",
      },
      {
        path: `${routes.calendar.path}/ru`,
        name: "Любительские старты",
      },
      {
        path: `${routes.calendar.path}/child`,
        name: "Лига триатлона. Дети",
      },
      /*  {
         path: `${routes.calendar.path}/childstart`,
         name: "Детские старты",
       },
       {
         path: `${routes.calendar.path}/series4`,
         name: "Кубок 4 колец",
       }, */
      // {
      //   path: `${routes.calendar.path}/sups`,
      //   name: "Суперсерия",
      // },
      {
        path: `${routes.calendar.path}/regions`,
        name: "Региональные соревнования",
      },
    ],
  },
  {
    name: "Федерация",
    array: [
      {
        path: routes.management.path,
        name: "Руководство",
      },
      {
        path: routes.regions.path,
        name: "Региональные федерации",
      },
      {
        path: `${routes.documents.path}/main`,
        name: "Документы",
      },
      {
        path: routes.contacts.path,
        name: "Контакты",
      },
      {
        path: routes.partners.path,
        name: "Партнеры ФТР",
      },
      {
        path: routes.childrensTriathlon.path,
        name: "Дети",
      },
      {
        path: routes.internationalTriathlon.path,
        name: "Международный",
      },
    ],
  },
];

export const Header = observer(() => {
  const size = useContext(ResponsiveContext);

  const [isShowMobileMenu, setMobileMenu] = useState(false);
  const [itemsMenu, setItemsMenu] = useState([...MenuItems]);

  const { goods } = useBasketStore();
  const { membersGroup, loadMembersGroup } = useMembersStore();

  React.useEffect(() => {
    if (membersGroup.length > 0) {
      const generateArray = membersGroup.map((item) => {
        return { name: item.name, path: `/members/group/${item.public_id}` };
      });

      const generateMemberItems = MenuItems.map((item) => {
        if (item.name === "Сборные") {
          return { name: "Сборные", array: generateArray };
        } else {
          return item;
        }
      });

      setItemsMenu([...generateMemberItems]);
    }
  }, [membersGroup]);

  const handleClickOpenMenu = (menu) => {
    if (menu === "Сборные" && membersGroup?.length === 0) {
      loadMembersGroup();
    }
  };

  return (
    <Box
      flex
      direction="row"
      style={{ fontSize: "12px" }}
      pad={{ horizontal: "medium", vertical: "xsmall" }}
      margin={{ horizontal: "auto" }}
      width={{ max: "1240px" }}
    >
      <Box flex direction="row" gap="small">
        <Box alignSelf="center">
          <MainLogo />
        </Box>
        {size === "desktop1900" ? (
          <Box flex direction="row" style={{ fontSize: "12px" }}>
            {itemsMenu.map((menu) => (
              <Menu
                key={menu.name}
                label={menu.name}
                size="12px"
                dropProps={{ align: { top: "bottom", left: "left" } }}
                onClick={() => handleClickOpenMenu(menu.name)}
                items={menu.array.map((el) => {
                  return {
                    label: <MenuNavLink to={el.path}>{el.name}</MenuNavLink>,
                    onClick: () => {},
                  };
                })}
              />
            ))}
            <Box flex direction="row" align="center" gap="1%">
              <MenuNavLink to={routes.antidoping.path}>Антидопинг</MenuNavLink>
              <MenuNavLink to={routes.results.path}>Результаты</MenuNavLink>
              <MenuNavLink to={routes.license.path}>Лицензия</MenuNavLink>
              <MenuNavLink to={routes.shop.path}>Магазин</MenuNavLink>
              <CustomExternalLink
                href={
                  "https://www.rusada.ru/doping-control/investigations/report-about-doping/"
                }
                target="_blank"
                rel="noopener"
              >
                Сообщить о допинге
              </CustomExternalLink>
              <NavLink to={routes.me.path}>
                <IconSvg src="user" color="#444444" />
              </NavLink>
              <BasketNavLink to={routes.basket.path}>
                <IconSvg src="basket" color="#444444" />{" "}
                {goods?.length > 0 && (
                  <BasketCounter>{goods?.length}</BasketCounter>
                )}
              </BasketNavLink>
            </Box>
          </Box>
        ) : (
          <Box
            margin={{ left: "auto", right: "16px" }}
            justify="center"
            pad={{ top: "12px" }}
          >
            <Button
              icon={<IconMenu />}
              onClick={() => {
                setMobileMenu(true);
              }}
            />
          </Box>
        )}
      </Box>

      {isShowMobileMenu && (
        <Layer full={true}>
          <Box pad={{ horizontal: "16px" }} direction="row" justify="end">
            <Button
              icon={<FormClose />}
              onClick={() => {
                setMobileMenu(false);
              }}
            />
          </Box>

          <Accordion pad="16px">
            <NavLink to={routes.me.path}>
              <span style={{ marginLeft: "12px" }}>
                <IconSvg
                  src="user"
                  color="#444444"
                  onClick={() => {
                    setMobileMenu(false);
                  }}
                />
              </span>
            </NavLink>
            {itemsMenu.map((el) => (
              <ModalMenuItem
                key={el.name}
                pad="10px"
                label={el.name}
                onClick={() => handleClickOpenMenu(el.name)}
              >
                {el.array.map((arr) => (
                  <ModalMenuSubItem
                    key={arr.name}
                    href={arr.path}
                    label={arr.name}
                    onClick={() => {
                      setMobileMenu(false);
                    }}
                  />
                ))}
              </ModalMenuItem>
            ))}

            {/*  <CustomLink
              to={routes.amateurs.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Любители
            </CustomLink> */}
            <CustomLink
              to={routes.antidoping.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Антидопинг
            </CustomLink>
            <CustomLink
              to={routes.results.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Результаты
            </CustomLink>
            <CustomLink
              to={routes.license.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Лицензия
            </CustomLink>
            <CustomLink
              to={routes.shop.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Магазин
            </CustomLink>
            <CustomLink
              to={routes.basket.path}
              onClick={() => {
                setMobileMenu(false);
              }}
            >
              Корзина{" "}
              <IconSvg
                src="basket"
                color="#444444"
                onClick={() => {
                  setMobileMenu(false);
                }}
              />{" "}
              {goods?.length > 0 && (
                <BasketCounter>{goods?.length}</BasketCounter>
              )}
            </CustomLink>

            <AnchorMobile
              target="_blank"
              rel="noopener"
              href={
                "https://www.rusada.ru/doping-control/investigations/report-about-doping/"
              }
            >
              Сообщить о допинге
            </AnchorMobile>
          </Accordion>
        </Layer>
      )}
    </Box>
  );
});
