import { Banner } from "../../components/Banner";
import coverImg from "../../images/cover4.jpg";
import { Container } from "../../components/Container";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { InternationalTriaDesc } from "../../components/InternationalTriathlonContent/InternationalTriaDesc";
import { InternationalTriaTabs } from "../../components/InternationalTriathlonContent/InternationalTriaTabs";
import { useDataStore } from "../../hooks/useDataStore";
import { Heading, Paragraph } from "grommet";
import renderHTML from "react-render-html";
import { observer } from "mobx-react";
import { useEffect } from "react";

export const InternationalTriathlon = observer(() => {
  const { internationalTriathlon, loadITriathlonData } = useDataStore();

  useEffect(() => {
    loadITriathlonData();
  }, []);

  return (
    <>
      <Banner cover={internationalTriathlon?.banners?.main} />
      <Container>
        <Breadcrumbs label={internationalTriathlon?.headline} />
        <div style={{ marginBottom: "40px" }}>
          <Heading level={2} margin={{ bottom: "medium" }}>
            {internationalTriathlon?.subtitle}
          </Heading>

          <Paragraph
            fill
            style={{
              lineHeight: "1.5",
              whiteSpace: "pre-line",
            }}
          >
            {internationalTriathlon?.text &&
              renderHTML(internationalTriathlon?.text)}
          </Paragraph>
        </div>
        <InternationalTriaTabs />
      </Container>
    </>
  );
});
