import React from "react";
import { Box, Heading, Paragraph } from "grommet";

export const NewsBanner = ({ image, paragraph, heading, publication_date }) => {
  return (
    <div>
      <Box
        margin={{ bottom: "medium" }}
        height={{ max: "740px", min: "180px" }}
        background={{
          image: `linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8)), url(${image})`,
          size: "cover",
        }}
      >
        <Box
          flex={{ grow: 1 }}
          width={{ max: "1200px" }}
          pad={{ horizontal: "medium" }}
          margin={{ horizontal: "auto", vertical: "xlarge" }}
          gap="medium"
          justify={"end"}
          fill
        >
          <Box pad={{ bottom: "large", top: "small" }}>
            <Paragraph size="0.75rem" color="white">
              {publication_date}
            </Paragraph>
          </Box>
          <Heading level={3} color="white" fill style={{ lineHeight: "1.5" }}>
            {heading}
          </Heading>

          <Paragraph size="1rem" color="white" fill>
            {paragraph}
          </Paragraph>
        </Box>
      </Box>
    </div>
  );
};
