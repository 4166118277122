import { CheckBox, ResponsiveContext } from "grommet";
import React, { useEffect } from "react";
import Rectangle from "../../../images/partners/Rectangle412.jpg";
import Run from "../../../images/partners/Run.svg";
import Swimming from "../../../images/partners/Swimming.svg";
import Bike from "../../../images/partners/Bike.svg";
import Triathlon from "../../../images/partners/Triathlon.svg";
import Finish from "../../../images/partners/Finish.jpg";
import MobileTriathlon from "../../../images/partners/MobileTriathlon.jpg";
import Triathlete from "../../../images/partners/Triathlete.jpg";
import { useState } from "react";
import { phoneMask } from "../../Input";
import { FormPopup } from "../../Popups/OlympicReservePopup/FormPopup";
import * as Styled from "./styles";
import { useSubmit } from "./useSubmit";
import { ErrorPopup } from "../../Popups/OlympicReservePopup/ErrorPopup";
import { useContext } from "react";
import { observer } from "mobx-react";
import { useRegionsStore } from "../../../hooks/useRegionsStore";

export const PartnersContentTab = observer(() => {
  const [isChecked, setIsChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);

  const size = useContext(ResponsiveContext);

  const { data, isError, setError, setData, sendPartnersData } =
    useRegionsStore();

  const { onSubmit } = useSubmit({ sendPartnersData, setIsChecked });

  useEffect(() => {
    if (data.message) {
      setShowPopup(true);
    }
    if (isError) {
      setShowPopupError(true);
    }
    return () => {
      setData("");
      setError("");
    };
    // eslint-disable-next-line
  }, [data, isError]);

  return (
    <Styled.Section>
      <Styled.WrapperFlex>
        <Styled.WrapperTitle>
          <Styled.BorderTop />
          <Styled.Title maxWidth="452px">
            Партнерство с ФТР делает Ваш бизнес сильнее
          </Styled.Title>
        </Styled.WrapperTitle>
        <Styled.WrapperFirstItems>
          <Styled.Number>1</Styled.Number>
          <div>
            <Styled.TitleItems>Для лояльности к бренду</Styled.TitleItems>
            <Styled.TextItems>
              Укрепление имиджа бренда <br />
              <br />
              Рост доверия к бренду или компании
            </Styled.TextItems>
          </div>
        </Styled.WrapperFirstItems>
        <Styled.Info>
          <Styled.InfoText>
            УЖЕ <Styled.InfoCount>250+</Styled.InfoCount> КОМПАНИЙ В ТРИАТЛОНЕ
          </Styled.InfoText>
        </Styled.Info>
        <Styled.WrapperSecondItems>
          <Styled.Number>2</Styled.Number>
          <div>
            <Styled.TitleItems>ДЛЯ НОВОЙ АУДИТОРИИ</Styled.TitleItems>
            <Styled.TextItems>
              Расширение географии бренда
              <br />
              <br />
              Рост узнаваемости
              <br />
              <br />
              Выход на международные рынки
            </Styled.TextItems>
          </div>
        </Styled.WrapperSecondItems>
        <Styled.Img src={Rectangle} />
        <Styled.WrapperItems>
          <Styled.Number>3</Styled.Number>
          <div>
            <Styled.TitleItems>Для B2B компаний</Styled.TitleItems>
            <Styled.TextItems>
              Укрепление деловых связей
              <br />
              <br /> Причастность к спортивному «Бизнес-клубу», в котором
              состоят крупнейшие компании России
            </Styled.TextItems>
          </div>
        </Styled.WrapperItems>
      </Styled.WrapperFlex>
      <Styled.WrapperTitle>
        <Styled.BorderMiddle />
        <Styled.Title>
          СТАТИСТИКА ПО ВИДАМ СПОРТА <br />В СОСТАВЕ ДИСЦИПЛИНЫ “ТРИАТЛОН”
        </Styled.Title>
      </Styled.WrapperTitle>
      <Styled.CardMiddleWrapper>
        <Styled.Cards>
          <Styled.CardWrapper>
            <Styled.Card>
              <Styled.CardImg src={Run} />
              <Styled.CardTitle>бег, лёгкая атлетика, ходьба</Styled.CardTitle>
              <Styled.CardFooter>
                <Styled.CardText>{">"}1 000 забегов в год </Styled.CardText>
                <Styled.CardText>
                  {">"}10 000 000 профессионалов и любителей
                </Styled.CardText>
              </Styled.CardFooter>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardImg src={Swimming} />
              <Styled.CardTitle>Плавание</Styled.CardTitle>
              <Styled.CardFooter marginTop="120px">
                <Styled.CardText>{">"}300 заплывов в год </Styled.CardText>
                <Styled.CardText>
                  {">"}4 000 000 профессионалов и любителей
                </Styled.CardText>
              </Styled.CardFooter>
            </Styled.Card>
            <Styled.Card>
              <Styled.CardImg src={Bike} />
              <Styled.CardTitle>велоспорт</Styled.CardTitle>
              <Styled.CardFooter marginTop="120px">
                <Styled.CardText>{">"}100 соревнований в год </Styled.CardText>
                <Styled.CardText>
                  {">"}2 000 000 профессионалов и любителей
                </Styled.CardText>
              </Styled.CardFooter>
            </Styled.Card>
          </Styled.CardWrapper>

          <Styled.CardBottomWrapper>
            <Styled.CardBigBorder
              width="388px"
              height="690px"
              top="0"
              left="20px"
            />

            <Styled.BigCard>
              <Styled.BigCardFinish
                src={size === "small" ? MobileTriathlon : Finish}
              />
              <Styled.BigCardWrapper>
                <Styled.BigCardImg src={Triathlon} />

                <Styled.BottomCardTitle>Триатлон</Styled.BottomCardTitle>
                <Styled.CardFooter marginTop="0">
                  <Styled.CardText>{">"}200 соревнований в год</Styled.CardText>
                  <Styled.CardText>{">"}20 0000 триатлетов</Styled.CardText>
                </Styled.CardFooter>
              </Styled.BigCardWrapper>
            </Styled.BigCard>
          </Styled.CardBottomWrapper>
        </Styled.Cards>
        <Styled.CardMiddle>
          <Styled.CardBottomWrapper>
            <Styled.CardBorder />
            <Styled.BottomCard>
              <Styled.BottomCardTitle>Потенциал Роста</Styled.BottomCardTitle>
              <Styled.BottomCardText>
                Общая база занимающихся циклическими видами спорта -
                <Styled.InfoCount> более 16 млн.</Styled.InfoCount>
              </Styled.BottomCardText>
            </Styled.BottomCard>
          </Styled.CardBottomWrapper>
        </Styled.CardMiddle>
      </Styled.CardMiddleWrapper>
      <Styled.WrapperTitle marginTop="80px" marginBottom="40px">
        <Styled.BorderBottom />
        <Styled.Title>СТАТЬ ПАРТНЕРОМ</Styled.Title>
      </Styled.WrapperTitle>
      <Styled.Wrapper>
        <div>
          <Styled.TitleItemsPar whiteSpace="normal" lineHeight="52px">
            ПРОЧНОЕ ПАРТНЕРСТВО - ЭТО ОСНОВА ВЗАИМНОГО УСПЕХА.
          </Styled.TitleItemsPar>
          <Styled.BigCardImg
            src={Triathlete}
            marginLeft={size === "small" ? "-16px" : "-30px"}
            width="490px"
          />
          <Styled.PartnersText>
            Формула успеха Федерации Триатлона России — это масштаб, люди и
            возможности. Все вместе это приносит позитивный результат.
          </Styled.PartnersText>
          <Styled.PartnersText marginTop="40px">
            Широкий набор инструментов для органичной интеграции вашего бренда в
            проекты Лиги Героев позволяет гарантировать позитивный отклик
            аудитории и достижение ваших целей.
          </Styled.PartnersText>
        </div>
        <Styled.FormCard onSubmit={onSubmit}>
          <Styled.FormTitle>
            Для проведения партнерской презентации и консультации оставьте
            заявку. Наша команда свяжется с вами.
          </Styled.FormTitle>
          <Styled.Label>
            Ваше имя
            <Styled.Input name="name" />
          </Styled.Label>
          <Styled.Label>
            Ваш номер телефона
            <Styled.PhoneInput mask={phoneMask} name="phone" />
          </Styled.Label>
          <Styled.Label>
            Ваша почта
            <Styled.Input name="email" />
          </Styled.Label>
          <Styled.Label>
            Комментарий
            <Styled.TextArea name="text" />
          </Styled.Label>
          <Styled.CheckboxWrapper>
            <CheckBox
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              name="checkbox"
              label={
                <Styled.CheckboxText>
                  Я согласен на обработку{" "}
                  <Styled.Span>персональных данных</Styled.Span>
                </Styled.CheckboxText>
              }
            />
          </Styled.CheckboxWrapper>
          <Styled.Button type="submit" isChecked={isChecked}>
            Оставить заявку
          </Styled.Button>
        </Styled.FormCard>
      </Styled.Wrapper>
      {showPopup && <FormPopup statePopupHandler={setShowPopup} />}
      {showPopupError && (
        <ErrorPopup statePopupHandler={setShowPopupError}></ErrorPopup>
      )}
    </Styled.Section>
  );
});
