import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useUserStore } from "../../../hooks/useUserStore";
import { DefaultButton } from "../../DefaultButton";
import { TextInput } from "../../Inputs/TextInput";
import { makeValidationSchema } from "./makeValidationSchema";

export const FormRecovery = () => {
  const validateForm = makeValidationSchema();

  const { resetPassword, resetPasswordError } = useUserStore();

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
  });

  const onSubmit = ({ email }) => resetPassword({ email });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextInput
            error={errors.email?.message || resetPasswordError}
            handleFocus={() => clearErrors("email")}
            name="email"
            iconSrc="email"
            iconHeight="40px"
            iconLeft="10px"
            iconTop="8px"
            id="profile-email-recovery"
            label="Ваш email"
            {...field}
          />
        )}
      />
      <DefaultButton
        style={{
          padding: "10px",
          width: "100%",
          textAlign: "center",
          borderRadius: "8px",
          height: "44px",
          marginTop: "20px",
          maxWidth: "411px",
          marginBottom: "20px",
        }}
        type="submit"
      >
        Отправить
      </DefaultButton>
    </form>
  );
};
