import React from "react";
import { Box } from "grommet";
import {
  StyledBox,
  StyledFormField,
  StyledIconSvg,
  StyledInputMask,
} from "./styles";
import { ErrorInfo } from "../../ErrorInfo";

export const DatePicker = ({
  error = "",
  label = "Дата рождения",
  id = Date.now(),
  placeholder = "30.12.2000",
  disabled = false,
  maxWidth = "406px",
  iconSrc = "",
  iconColor = "",
  iconWidth = "",
  iconHeight = "",
  iconLeft = "10px",
  iconTop = "14px",
  ...rest
}) => {
  return (
    <Box flex width={{ max: maxWidth }}>
      <StyledFormField error={error} disabled={disabled} htmlFor={String(id)}>
        {label}
      </StyledFormField>
      <StyledBox>
        <StyledIconSvg
          disabled={disabled}
          error={error}
          iconLeft={iconLeft}
          iconTop={iconTop}
          src={iconSrc}
          width={iconWidth}
          height={iconHeight}
          color={iconColor}
        />
        <StyledInputMask
          error={error}
          disabled={disabled}
          id={id}
          mask={"99.99.9999"}
          maskChar={null}
          placeholder={placeholder}
          {...rest}
        />
        <div style={{ marginTop: "10px" }}>
          <span style={{ position: "relative" }}>
            {error && <ErrorInfo error={error} />}
          </span>
        </div>
      </StyledBox>
    </Box>
  );
};
