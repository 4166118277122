import { Button } from "grommet";
import React from "react";

export const DefaultButton = ({ children, ...props }) => {
  return (
    <Button primary {...props}>
      {children}
    </Button>
  );
};
