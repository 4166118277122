import React from "react";
import { Box, Heading } from "grommet";
import styled, { createGlobalStyle } from "styled-components";
import { IconSvg } from "../IconSvg";
import { Clock } from "grommet-icons";
import { DefaultButton } from "../DefaultButton";
import { useNavigate } from "react-router-dom";
import { routes } from "../../const/routes";

const StyledHeading = styled(Heading)`
  &:hover {
    color: #444444b0;
  }
`;

const GlobalStyle = createGlobalStyle`
  .ZxsjP{
    box-shadow: none;
  }
  .StyledBox-sc-13pk1d4-0{
    box-shadow: none;
  }
  .esvrRV{
    box-shadow: none;
  }
`;

export const Section = ({ setSection, isMobileWidth, section, logout }) => {
  const navigation = useNavigate();

  const handleOut = () => {
    logout();
    navigation(routes.signIn.path);
  };
  return (
    <>
      <div style={{ paddingBottom: "40px" }}>
        <GlobalStyle />
        <Box
          style={{
            borderRadius: "10px",
            backgroundColor: "#F8F8F8",
            height: "221px",
            marginBottom: "20px",
          }}
          gap="0.8rem"
          width={isMobileWidth ? "100%" : "243px"}
          pad={{ vertical: "0.8rem", horizontal: "1rem" }}
        >
          <div
            onClick={() => setSection("license")}
            style={{
              cursor: "pointer",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              paddingTop: "0.5rem",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{ marginLeft: "3px", position: "absolute", top: "5px" }}
              >
                <IconSvg
                  opacity={section === "license" ? "inherit" : "0.7"}
                  src="userLicense"
                  color="white"
                />
              </span>
              <span style={{ marginLeft: "35px" }}>
                <h4 level={6}>Лицензия</h4>{" "}
              </span>
            </div>
          </div>

          <div
            onClick={() => setSection("events")}
            flex
            direction="row"
            pad={{ vertical: "0.5rem" }}
            style={{ cursor: "pointer" }}
          >
            <div>
              <h4 level={6}>
                <span style={{ marginLeft: "6px" }}>
                  <IconSvg
                    src="calendar"
                    color={section === "events" ? "black" : ""}
                  />
                </span>
                <span style={{ marginLeft: "13px" }}>Мои мероприятия</span>
              </h4>
            </div>
          </div>

          <div
            onClick={() => setSection("shop")}
            flex
            direction="row"
            pad={{ vertical: "0.5rem" }}
            style={{ cursor: "pointer" }}
          >
            <div>
              <h4 level={6}>
                <span style={{ marginLeft: "6px" }}>
                  <IconSvg src="basket" color="#444444" />
                </span>
                <span style={{ marginLeft: "13px" }}>Мои товары</span>
              </h4>
            </div>
          </div>

          <div
            onClick={() => setSection("settings")}
            style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}
          >
            <div>
              <h4>
                <span style={{ marginLeft: "3px" }}>
                  <IconSvg
                    src="userCircle"
                    onClick={() => setSection("settings")}
                    color={section === "settings" ? "black" : ""}
                  />
                </span>
                <span style={{ marginLeft: "9px" }}>Настройки</span>
              </h4>
            </div>
          </div>

          <div
            onClick={() => setSection("activity")}
            style={{
              cursor: "pointer",
              position: "relative",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ position: "relative" }}>
              <Clock
                color={section === "activity" ? "black" : ""}
                style={{ position: "absolute", top: "-2px", left: "3px" }}
              />
            </div>
            <div style={{ marginLeft: "2.3rem" }}>
              <h4 style={{ whiteSpace: "nowrap" }}>Активность профиля</h4>
            </div>
            <div
              style={{
                position: "absolute",
                width: `${isMobileWidth ? "98%" : "211px"} `,
                height: "1px",
                left: "0px",
                top: `${isMobileWidth ? "40px" : "50px"} `,

                background: "#D9D9D9",
              }}
            ></div>
          </div>

          {isMobileWidth ? (
            <Box
              flex
              align="center"
              justify="center"
              height={{ max: "2.5rem" }}
              margin={{ bottom: "2rem", top: "2rem" }}
              pad={{ vertical: "0.5rem" }}
              width={"100%"}
              onClick={() => handleOut()}
            >
              <DefaultButton
                style={{
                  padding: "9px",
                  width: "80%",
                  textAlign: "center",
                  height: "40px",
                  borderRadius: "8px",
                  rel: "noopener noreferrer",
                }}
              >
                Выйти из профиля
              </DefaultButton>
            </Box>
          ) : (
            <Box
              flex
              align="center"
              height={{ max: "2.5rem" }}
              margin={{ bottom: "2rem", top: "1rem" }}
              pad={{ vertical: "0.5rem" }}
              onClick={() => handleOut()}
            >
              <DefaultButton
                style={{
                  marginTop: "5px",
                  padding: "9px",
                  width: "211px",
                  textAlign: "center",
                  height: "40px",
                  borderRadius: "8px",
                  rel: "noopener noreferrer",
                }}
              >
                Выйти из профиля
              </DefaultButton>
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};
