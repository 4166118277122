import { Anchor, Box, Grid, Image, ResponsiveContext, Text } from "grommet";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useDataStore } from "../../hooks/useDataStore";
import { REACT_APP_API } from "../../api";
import { ReklamaPopup } from "../Popups/ReklamaPopup";

import styles from "./partners.module.scss";

export const Partners = observer(() => {
  const size = useContext(ResponsiveContext);
  const { getPartnersBanners, getBanners } = useDataStore();
  const mainPartnerArray = getBanners("partners_main");
  const partPartnersArray = getBanners("partners");

  const [isClickText, setIsClickText] = useState(false);
  const [partItem, setPartItem] = useState(null);

  useEffect(() => {
    getPartnersBanners();
    //eslint-disable-next-line
  }, []);

  let count = 5;
  let width;

  if (size === "medium") {
    count = 4;
    width = "140";
  }
  if (size === "tablet") {
    count = 2;
    width = "130";
  }
  if (size === "small") {
    count = 2;
    width = "120";
  }

  const handleClickText = (e, item) => {
    e.preventDefault();
    setIsClickText(true);
    setPartItem(item);
  };

  return (
    <Box pad={{ vertical: "66px" }} gap="66px">
      <Grid columns={{ count: "fit", size: "200px" }} gap={{ row: "90px" }}>
        <Box gap="100px">
          <Grid
            columns={{ count: "fit", size: "200px" }}
            gap={{ row: "80px", column: "24px" }}
          >
            {mainPartnerArray.map((item) => (
              <Box
                justify={"center"}
                key={item.id}
                align={"center"}
                style={{ position: "relative" }}
              >
                <Anchor
                  color={"black"}
                  href={item.path}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <a href={item.link}>
                    {item?.reklama && !isClickText && (
                      <span
                        onClick={(e) => handleClickText(e, item)}
                        className={styles.textMain}
                      >
                        Реклама
                      </span>
                    )}
                    <Image
                      src={`${REACT_APP_API}${item.picture_main}`}
                      width={"180"}
                    />
                  </a>
                </Anchor>
                <Box pad="small" align="center">
                  <Text
                    textAlign="center"
                    size="small"
                    weight="bold"
                    color="black"
                  >
                    {item.title}
                  </Text>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid
            columns={{
              count: count,
              size: "100px",
            }}
            gap="small"
          >
            {partPartnersArray.map((item) => (
              <Box
                justify={"center"}
                align={"center"}
                key={item.id}
                elevation={"none"}
              >
                <a href={item.link} style={{ position: "relative" }}>
                  {item?.reklama && !isClickText && (
                    <span
                      onClick={(e) => handleClickText(e, item)}
                      className={styles.text}
                    >
                      Реклама
                    </span>
                  )}
                  <Image
                    width={size !== "desktop1900" ? width : "180"}
                    src={`${REACT_APP_API}${item.picture_main}`}
                  />
                </a>
              </Box>
            ))}
          </Grid>
        </Box>
        {isClickText && partItem && (
          <ReklamaPopup
            closeHandler={() => setIsClickText(false)}
            item={partItem}
            styles={styles}
          />
        )}
      </Grid>
    </Box>
  );
});
