import { useState } from "react";
import { getEventTime } from "../../utils/date";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as IconQRCode } from "../../images/svg/icon-qr-code.svg";
import { QRCodeSVG } from "qrcode.react";

import styles from "../TicketEventsCard/TicketCard.module.scss";

export const TicketCard = ({ ticket }) => {
  const [isQrClick, setIsQrClick] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTable = useMediaQuery({ query: "(max-width: 1153px)" });
  const isDeck = useMediaQuery({ query: "(max-width: 3000px)" });

  if (!ticket) return <div>Загрузка...</div>;

  const { event, event_format } = ticket;

  return (
    <section className={styles.ticketCard}>
      <div className={styles.wrapper}>
        {ticket?.event_format?.qr && !isQrClick && (
          <IconQRCode
            className={styles.qrTicketReg}
            onClick={() => setIsQrClick(true)}
          />
        )}
        <div className={`${styles.main} ${isQrClick && styles.isQrClick}`}>
          {isQrClick ? (
            <div
              style={{
                padding: "20px",
                background: "#fff",
                borderRadius: "30px",
                marginBottom: !isMobile && "20px",
              }}
            >
              <QRCodeSVG
                value={ticket.public_id}
                size={250}
                onClick={() => setIsQrClick(false)}
                bgColor={"#fff"}
                fgColor={"#000"}
                level={"L"}
                includeMargin={false}
              />
            </div>
          ) : (
            <>
              <img
                className={styles.logo}
                src={event?.event_type?.logo}
                width="69"
                height="34"
                alt="Логотип билета"
              />
              <p className={styles.description}>{event_format?.description}</p>
            </>
          )}
        </div>
        <div className={styles.terms}>
          <h3 className={styles.terms_title}>Условия участия</h3>
          <div className={styles.informationTwo}>
            <ul className={styles.terms_list}>
              {event_format?.requirements?.map((req) => (
                <li key={req} className={styles.terms_item}>
                  {req}
                </li>
              ))}
            </ul>
            {isTable && (
              <div
                style={{
                  gridTemplateColumns: ticket?.team
                    ? "repeat(5, 1fr)"
                    : "repeat(4, 1fr)",
                }}
                className={styles.informationTwo}
              >
                {ticket?.team && (
                  <p>
                    <span className={styles.information_label}>Команда:</span>
                    №&nbsp;
                    {ticket?.team?.number}
                    {`${
                      ticket?.info?.inside_number
                        ? "-" + ticket?.info?.inside_number
                        : ""
                    }`}
                  </p>
                )}
                <p>
                  <span className={styles.information_label}>
                    Номер&nbsp;билета:
                  </span>
                  №&nbsp;
                  {ticket?.info?.registered ? ticket?.info?.number : `****`}
                </p>
                <p>
                  {ticket?.info?.sector && (
                    <p>
                      {" "}
                      <span className={styles.information_label}>Сектор:</span>
                      <span className={styles.item_number}>
                        №&nbsp;{ticket.info.sector}
                      </span>
                    </p>
                  )}
                </p>
                <p>
                  {ticket?.info?.row && (
                    <p>
                      {" "}
                      <span className={styles.information_label}>Ряд:</span>
                      <span className={styles.item_number}>
                        №&nbsp;{ticket.info.row}
                      </span>
                    </p>
                  )}
                </p>
                <p>
                  {ticket?.info?.place && (
                    <p>
                      {" "}
                      <span className={styles.information_label}>Место:</span>
                      <span className={styles.item_number}>
                        №&nbsp;{ticket.info.place}
                      </span>
                    </p>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        {isDeck && !isTable && (
          <div className={styles.information}>
            {ticket?.team && (
              <p className={`${styles.information_item} `}>
                <span className={styles.information_label}>Команда:</span>
                №&nbsp;
                {ticket?.team?.number}
                {`${
                  ticket?.info?.inside_number
                    ? "-" + ticket?.info?.inside_number
                    : ""
                }`}
              </p>
            )}
            <p className={`${styles.information_item} `}>
              <span className={styles.information_label}>Номер билета:</span>
              №&nbsp;{ticket?.info?.registered ? ticket?.info?.number : `****`}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                marginBottom: "15px",
              }}
            >
              <p>
                {ticket?.info?.sector && (
                  <p>
                    {" "}
                    <span className={styles.information_label}>Сектор:</span>
                    <span className={styles.item_number}>
                      №&nbsp;{ticket.info.sector}
                    </span>
                  </p>
                )}
              </p>
              <p>
                {ticket?.info?.row && (
                  <p>
                    {" "}
                    <span className={styles.information_label}>Ряд:</span>
                    <span className={styles.item_number}>
                      №&nbsp;{ticket.info.row}
                    </span>
                  </p>
                )}
              </p>
              <p>
                {ticket?.info?.place && (
                  <p>
                    {" "}
                    <span className={styles.information_label}>Место:</span>
                    <span className={styles.item_number}>
                      №&nbsp;{ticket.info.place}
                    </span>
                  </p>
                )}
              </p>
            </div>
          </div>
        )}
        <div className={styles.information}>
          <p className={styles.information_item}>
            <span className={styles.information_label}>Формат:</span>
            {ticket?.event_format?.title}
          </p>
          <p className={styles.information_item}>
            <span className={styles.information_label}>
              Начало мероприятия:
            </span>{" "}
            {getEventTime(ticket?.event_format, `D MMMM / HH:mm`)}
          </p>
          <p className={styles.information_item}>
            <span className={styles.information_label}>Город:</span>{" "}
            {ticket?.city?.name_ru}
          </p>
          <p className={styles.information_item}>
            <span className={styles.information_label}>Номер заказа:</span>
            {ticket?.order_public_id}
          </p>
          {/*    <p className={`${styles.information_item} ${styles.infInsur}`}>
            <span className={styles.information_label}>Страховка:</span>
            {ticket?.info?.insurance ? `Есть` : `Нет`}
          </p> */}
        </div>
      </div>
      <div className={styles.cover}>
        <img
          className={styles.coverImg}
          src={event?.banners?.landing_page?.desktop_picture}
          alt="Фото участников"
        />
      </div>
    </section>
  );
};
