import { Card } from "grommet";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBasketStore } from "../../hooks/useBasketStore";
import { Book } from "./Book";

export const Sale = observer(() => {
  const navigation = useNavigate();

  const handlePayment = (public_id) => navigation(`/add/shop/${public_id}`);

  const { shopItems, getAllShopItems } = useBasketStore();

  useEffect(() => {
    getAllShopItems();
    // eslint-disable-next-line
  }, []);

  return (
    <Card
      margin={{ bottom: "large" }}
      pad="medium"
      background="light-1"
      elevation="none"
      gap="large"
    >
      {shopItems &&
        shopItems.length > 0 &&
        shopItems.map((el) => (
          <Book
            handlePayment={handlePayment}
            key={el.public_id}
            title={el.title}
            description={el.description}
            price={el.price}
            picture={el.picture}
            public_id={el.public_id}
          />
        ))}
    </Card>
  );
});
