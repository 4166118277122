import svg from "../images/svg/gridicons_pending.svg";
import svg3 from "../images/svg/gridicons_delivered.svg";

export const getOrderStatus = (status) => {
  switch (status) {
    case "paid":
      return "Оплачен";
    case "authorized":
      return "Оплачен";
    case "delivered":
      return "Заказ доставлен";
    default:
      return "Заказ обрабатывается";
  }
};

export const getOrderStatusIcon = (status) => {
  switch (status) {
    case "paid":
      return svg3;
    case "authorized":
      return svg3;
    case "delivered":
      return svg3;
    default:
      return svg;
  }
};
