import React from "react";
import { Anchor, Box, Grid, Image, Text } from "grommet";
import logoMl from "../../images/logo-ml.svg";
import { MainLogo } from "../Header/MainLogo";
import { Container } from "../Container";
import SocialMedia from "./SocialMedia/SocialMedia";

export const Footer = () => {
  return (
    <Container>
      <Grid
        margin={{ bottom: "20px" }}
        columns={{ count: "fit", size: "280px" }}
        justify="stretch"
        gap={"medium"}
      >
        <Box pad={{ bottom: "22px" }}>
          <Box
            direction="row"
            pad={{ top: "32px", bottom: "30px" }}
            align="center"
            wrap={true}
            border={{ side: "top", color: "rgba(51,51,51,0.5)" }}
          >
            <Box margin={{ right: "auto", bottom: "15px" }}>
              <MainLogo />
            </Box>
            <SocialMedia />
          </Box>
          <Box direction="row" flex="grow" justify="between" wrap>
            <Box pad={{ bottom: "medium" }}>
              <Box pad={{ bottom: "small" }}>
                <Text size="12px" color="#333333" pad={{ bottom: "small" }}>
                  Все права защищены 2022 Федерация Триатлона России (с)
                </Text>
              </Box>
              <Box pad={{ bottom: "medium" }}>
                <Text size="12px" color="#333333" pad={{ bottom: "small" }}>
                  ИНН 7704123065, ОГРН 1037746009113, ООО «ФТР», 119992, г.
                  Москва, Лужнецкая набережная, д.8
                </Text>
              </Box>
              <Box pad={{ bottom: "medium" }}>
                <Text size="12px" color="#333333" pad={{ bottom: "small" }}>
                  По вопросам лицензии пишите на{" "}
                  <Anchor href="mailto:license@rustriathlon.ru">
                    license@rustriathlon.ru
                  </Anchor>
                </Text>
              </Box>
              <Box pad={{ bottom: "medium" }}>
                <Text size="12px" color="#333333" pad={{ bottom: "small" }}>
                  По техническим вопросам пишите на{" "}
                  <Anchor href="mailto:support@rustriathlon.ru">
                    support@rustriathlon.ru
                  </Anchor>
                </Text>
              </Box>
              <Box>
                <Text size="12px" color="#333333" pad={{ bottom: "small" }}>
                  По общим вопросам пишите на{" "}
                  <Anchor href="mailto:office@rustriathlon.ru">
                    office@rustriathlon.ru
                  </Anchor>
                </Text>
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "end",
                alignItems: "flex-end",
              }}
            >
              <Anchor
                style={{ marginBottom: "20px" }}
                href={
                  "https://rustriathlon.ru/uploads/050529aef3bde820c16d4fbae16f16e835125a4c.pdf"
                }
              >
                Политика обработки персональных данных
              </Anchor>
              <Box pad={{ bottom: "xsmall" }}>
                <Anchor href={"https://disk.yandex.ru/i/vR_JyGNrtWDhvg"}>
                  Публичная оферта
                </Anchor>
              </Box>
              <Box margin={{ top: "small" }}>
                <Image src={logoMl} width="131" height="26" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};
