import {
  CloseButton,
  DescWrap,
  PopupBody,
  PopupFooter,
  PopupTop,
} from "./styles";
import {
  Avatar,
  Box,
  Layer,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet";
import { FormClose } from "grommet-icons";
import React, { useContext } from "react";
import { countries } from "../../../mocks/countries";
import renderHTML from "react-render-html";
import { formatEventDate, getEventCategoryName } from "../../../utils/utils";
import {
  category,
  distance,
  event_subtype,
  event_type_filter,
  status,
} from "../../../utils/const";
import { DefaultButton } from "../../DefaultButton";

export const CalendarItemMore = ({ isShow, onClosePopup, event }) => {
  const size = useContext(ResponsiveContext);

  const mobileSizes = ["small"];

  const handleClosePopup = () => {
    onClosePopup(false);
  };

  if (!isShow || !event) return null;

  return (
    <Layer
      onClickOutside={handleClosePopup}
      onEsc={handleClosePopup}
      full={true}
      plain={false}
      background={"transparent"}
      animation={"fadeIn"}
      position={"center"}
    >
      <Box
        overflow={"scroll"}
        height={{ min: "100%" }}
        background={"rgba(0, 0, 0, 0.3)"}
        align={"center"}
        onClick={handleClosePopup}
      >
        <Box
          height={{ min: "auto" }}
          background={"#FFFFFF"}
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "100%",
            maxWidth: "776px",
            borderRadius: "5px",
            position: "relative",
            cursor: "default",
          }}
        >
          <PopupTop>
            <Box>{formatEventDate(event)}</Box>
            <Box direction={"row"} justify={"end"} style={{ zIndex: "999" }}>
              <CloseButton
                icon={<FormClose color={"#DB4C4C"} />}
                onClick={handleClosePopup}
              />
            </Box>
          </PopupTop>
          <Box height={{ min: "auto" }}>
            <PopupBody>
              <Box
                direction={!mobileSizes.includes(size) ? "row" : "column"}
                gap="small"
                pad={{ bottom: "24px" }}
                style={{ borderBottom: "1px solid #e7e8f0" }}
                margin={{ bottom: "23px" }}
              >
                <Box
                  width="100%"
                  style={{ order: !mobileSizes.includes(size) ? "0" : "1" }}
                >
                  <Paragraph
                    style={{
                      fontSize: "24px",
                      fontWeight: "bolder",
                      maxWidth: "100%",
                      marginBottom: "42px",
                    }}
                  >
                    {event.title}
                  </Paragraph>
                  <Paragraph
                    size="small"
                    style={{ maxWidth: "100%", marginBottom: "19px" }}
                  >
                    {event.place}
                  </Paragraph>
                  <Paragraph style={{ maxWidth: "100%" }}>
                    <Text size="small" style={{ fontWeight: "bolder" }}>
                      Категории:
                    </Text>
                    <Text size="small">
                      {getEventCategoryName(event.status, status)}{" "}
                      {getEventCategoryName(event.event_subtype, event_subtype)}{" "}
                      {getEventCategoryName(event.distance, distance)}{" "}
                      {getEventCategoryName(event.category, category)}{" "}
                      {getEventCategoryName(
                        event.event_type,
                        event_type_filter
                      )}
                    </Text>
                  </Paragraph>
                </Box>
                <Box width={{ min: "50px" }} margin={{ bottom: "large" }}>
                  <div
                    style={{
                      padding: "2px",
                      width: "49px",
                      height: "49px",
                      border: "1px solid #dadada",
                      borderRadius: "50%",
                    }}
                  >
                    <Avatar src={countries[event.country]} size="43px" />
                  </div>
                </Box>
              </Box>
              {event?.html && (
                <Box style={{ borderBottom: "1px solid #e7e8f0" }}>
                  <DescWrap>{renderHTML(event.html)}</DescWrap>
                </Box>
              )}
            </PopupBody>

            {(event?.limits || event?.link) && (
              <PopupFooter
                direction={!mobileSizes.includes(size) ? "row" : "column"}
                gap="small"
                styles={{ alignItems: "center" }}
              >
                <Box fill>
                  {event.limits && (
                    <>
                      <Paragraph>
                        <Text size="small" style={{ fontWeight: "bolder" }}>
                          Обязательное условие участия:
                        </Text>
                      </Paragraph>
                      <Paragraph size="small">{event.limits}</Paragraph>
                    </>
                  )}
                </Box>
                {event?.link && (
                  <Box width={{ min: "max-content" }}>
                    <DefaultButton
                      href={event.link}
                      size={"medium"}
                      label="Подробнее"
                    />
                  </Box>
                )}
              </PopupFooter>
            )}
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
