import "reflect-metadata";
import { Container } from "inversify";
import { DataStore } from "./stores/DataStore";
import { iocNames } from "./iocNames";
import { OlympicStore } from "./stores/OlympicStore";
import { RegionsStore } from "./stores/RegionsStore";
import { additionalApi, api } from "./api";
import { PaymentStore } from "./stores/PaymentStore";
import { UserStore } from "./stores/UserStore";
import { ProfileStore } from "./stores/ProfileStore";
import { MembersStore } from "./stores/MembersStore";
import { BasketStore } from "./stores/BasketStore";
import { TicketsStore } from "./stores/TicketsStore";
import { SubscribeStore } from "./stores/SubscribeStore";

export function createIOC() {
  const ioc = new Container({ skipBaseClassChecks: true });

  ioc.bind(iocNames.api).toConstantValue(api);
  ioc.bind(iocNames.additionalApi).toConstantValue(additionalApi);

  ioc
    .bind(iocNames.dataStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      const additionalApi = container.get(iocNames.additionalApi);
      return new DataStore(api, additionalApi);
    })
    .inSingletonScope();

  ioc
    .bind(iocNames.regionsData)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new RegionsStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.subscribeStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new SubscribeStore(api);
    })
    .inSingletonScope();

  ioc
    .bind(iocNames.olympicStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new OlympicStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.paymentStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new PaymentStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.userStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new UserStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.profileStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new ProfileStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.membersStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new MembersStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.basketStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      const PaymentStore = container.get(iocNames.paymentStore);
      return new BasketStore(api, PaymentStore);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.ticketsStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new TicketsStore(api);
    })
    .inSingletonScope();

  return ioc;
}
