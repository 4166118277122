import { Select } from "grommet";
import styled from "styled-components";
import { IconSvg } from "../../components/IconSvg";

export const StyledForm = styled.form`
  margin-top: 40px;
`;

export const StyledRadioButtons = styled.div`
  display: flex;
  column-gap: 20px;
  opacity: ${(props) => props.disabled && "0.5"};
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
`;
export const StyledSelect = styled(Select)`
  width: 345px;
  padding: 21px 40px;
`;
export const StyledBasketSelect = styled(Select)`
  width: 360px;
  padding: 21px 40px;

  @media (max-width: 470px) {
    width: 100%;
  }
`;
export const StyledDiv = styled.div`
  & > .jOVKdY {
    border-color: black;
    border-color: ${(props) => props.error && "#CA3C3C"};
  }
`;
export const StyledButton = styled.button`
  background: #db4c4c;
  border: unset;
  outline: unset;
  border-radius: 100px;
  cursor: pointer;
  max-width: 400px;
  min-width: 300px;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 15px;

  &:hover {
    opacity: 0.5;
  }
`;
export const StyledButtonCancelReg = styled.button`
  border: unset;
  border-radius: 6rem;
  padding: 0.8rem 2rem;
`;
export const StyledPopupButton = styled.button`
  border: unset;
  border-radius: 4px;
  padding: 0.8rem;
`;
export const StyledPopupContent = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledPopupButtons = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  column-gap: 2rem;
`;
export const StyledParagraph = styled.p`
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
  color: ${(props) => props.error && "#CA3C3C"};
  opacity: ${(props) => props.disabled && "0.5"};
`;
export const StyledTitle = styled.h2`
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
`;

export const StyledFormWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(23.75rem, 1fr));
  gap: 2.5rem 1.5rem;

  & + * {
    margin-top: 2.125rem;
  }
  @media (max-width: 508px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const StyledFooter = styled.div`
  display: flex;
  column-gap: 30px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 30px;
  }
`;
export const StyledText = styled.p`
  line-height: 1.2;
  font-size: 0.7rem;
`;
export const StyledA = styled.a`
  color: black;
`;
export const StyledTransferA = styled(StyledButton)`
  text-decoration: none;
  margin-top: 3rem;
  color: white;
`;
export const StyledErrorMessage = styled.div`
  font-size: 0.8rem;
  color: #ca3c3c;
  line-height: 1.2;
  margin-bottom: 10px;
`;
export const StyledCancelMessage = styled.h2`
  margin-bottom: 2rem;
`;
export const StyledBox = styled.div`
  position: relative;
  & > .jOVKdY {
    border-color: black;
    border-color: ${(props) => props.error && "#CA3C3C"};
  }
`;
export const StyledIconSvg = styled(IconSvg)`
  position: absolute;
  left: 14px;
  top: 20px;
  color: ${(props) => props.error && "#CA3C3C"};
  opacity: ${(props) => props.disabled && "0.5"};
`;
export const StyledErrorRegMessage = styled.h2`
  margin-bottom: 2rem;
  color: #ca3c3c;
`;
