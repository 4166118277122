import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_API } from "../../api";
import { AddInfo } from "../../components/AddInfo";
import { Calendar } from "../../components/Calendar";
import { Container } from "../../components/Container";
import { Leaders } from "../../components/Leaders/Leaders";
import { MainSlider } from "../../components/MainSlider";
import { NewsMainSection } from "../../components/NewsMainSection";
//import { Sale } from "../../components/Sale";
import { useDataStore } from "../../hooks/useDataStore";
import { PopupInfoMain } from "../../components/Popups/PopupInfoMain";

export const Main = observer(() => {
  const { newsList, loadNewsPreview } = useDataStore();

  /*  const [isInfoPopup, setIsInfoPopup] = useState(true); */

  useEffect(() => {
    loadNewsPreview(5);
    //eslint-disable-next-line
  }, []);

  return (
    <section>
      <MainSlider />
      <Container>
        <NewsMainSection
          app_api={REACT_APP_API}
          as={Link}
          newsList={newsList}
        />
        {/*  <Sale /> */}
        <div style={{ marginTop: "2rem" }}>
          <AddInfo />
        </div>
      </Container>
      <Leaders />
      <Calendar event_type="all" />
      {/* {isInfoPopup && (
        <PopupInfoMain
          closePopup={() => setIsInfoPopup(false)}
          button="Подробнее"
          href="https://rustriathlon.ru/news/4719ffa26ab63bd38123729c0fd39919d4765b6d"
          heading="Единый Всероссийский день «Урок Триатлона»"
          subtext="С 11 сентября по 16 октября 2023 года Федерация триатлона России при поддержке Министерства спорта РФ и Министерства просвещения РФ проводит Единый Всероссийский день «Урок триатлона»"
        />
      )} */}
    </section>
  );
});
