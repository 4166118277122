import { Box, Heading, Paragraph } from "grommet";
import styled from "styled-components";
import articleImg0 from "../../../images/webp/howto1080p.48c5c330.webp";
import { Article } from "..";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const CustomUl = styled.ul`
  padding-left: 40px;
  list-style: outside;
`;

const CustomTbody = styled.tbody`
  color: #313131;
  font-size: 18px;

  & th {
    padding: 20px;
    width: 100%;
    text-align: left;
    border-left: 2px solid #dadde0;
    background-color: #e8eaec;
  }

  & th:first-child {
    max-width: 56px;
    color: #777777;
    font-weight: 400;
    text-align: center;
    border-left: none;
  }

  & th:nth-child(2) {
    max-width: 400px;
  }

  & tr {
    display: flex;
    border-top: 2px solid #e8eaec;
  }

  & tr:first-child {
    border-top: none;
  }

  & td {
    width: 100%;
    padding: 20px;

    background-color: #f8f8f8;
    border-left: 2px solid #e8eaec;
  }

  & td:first-child {
    max-width: 56px;
    color: #777777;
    text-align: center;
    border-left: none;
  }

  & td:nth-child(2) {
    max-width: 400px;
  }

  & tr:first-child th:first-child {
    border-top-left-radius: 24px;
  }

  & tr:first-child th:last-child {
    border-top-right-radius: 24px;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 24px;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 24px;
  }
`;

export const WhereToStart = () => {
  return (
    <Article
      title={"С чего начать"}
      desc={
        "Хотите пройти свой первый триатлон, но не знаете, с чего начать? Мы поделимся всем, что вам нужно знать о тренировках и соревнованиях для начинающих спортсменов по триатлону: от базового снаряжения и основ питания до психологической подготовки и советов по найму тренера — чтобы помочь вам добиться успеха в первом триатлоне."
      }
      coverBack={articleImg0}
    >
      <CustomParagraph>
        В рамках своего знакомства с этим видом спорта подумайте, почему вы с
        самого начала занимаетесь триатлоном и что вы надеетесь получить от
        тренировок по триатлону. Вас мотивирует исключительно желание
        попробовать свои силы и расширить круг общения? Вы ищете способ привести
        свое тело в форму? Вас вдохновило освещение в СМИ, казалось бы,
        невыполнимой цели? Вы уже спортсмен и хотите стать соревновательного
        прогресса? Какими бы ни были ваши причины выбора участия в триатлоне,
        формулировка целей и приоритетов поможет вам спланировать гоночный
        сезон, наиболее соответствующий вашим желаниям и сильным сторонам.
      </CustomParagraph>
      <CustomParagraph>
        Просматривая календари гонок в поисках ключевых событий, учитывайте ряд
        переменных и вариантов: любительские соревнования или профессиональные
        старты в вашем городе, мероприятия дома или в других городах и странах.
      </CustomParagraph>
      <CustomParagraph>
        Примите во внимание такие факторы, как высота (можете ли вы терпеть
        кислородное голодание или вам лучше находится на уровне моря?), погода
        (вы будете лучше себя чувствовать при жарких или прохладных
        температурах, а также в сухом или влажном климате?), дистанции (вы
        предпочитаете плавание в океане или пресной воде, а также на холмистой
        местности или равнине?). Подсчитайте, сколько времени вы можете
        посвятить тренировкам и гонкам.
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        СНАРЯЖЕНИЕ
      </Heading>

      <Box
        style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
      ></Box>

      <CustomParagraph>
        Хотя триатлонные гонки и тренировки по триатлону действительно требуют
        большого количества экипировки, это не означает, что вы должны иметь
        большое финансовое состояние, особенно когда вы только начинаете. Скорее
        всего, пока у вас не будет сезонного опыта соревнований и стабильных
        еженедельных тренировок по триатлону, вы, в любом случае не будете
        понимать, какое снаряжение лучше всего соответствует вашим желаниям и
        потребностям.
      </CustomParagraph>
      <CustomParagraph>
        <strong>
          Попробуйте начать с аренды оборудования и экипировки, а затем
          улучшайте их по мере того, как будут увеличиваться ваши физические и
          материальные ресурсы.
        </strong>
      </CustomParagraph>
      <CustomParagraph>
        Используйте возможность публикации того, что вы ищете в социальных сетях
        и, возможно, именно вы вдохновите другого спортсмена на обновление
        своего любимого велосипеда или гидрокостюма, что даст вам, в свою
        очередь, прекрасную возможность получить хорошо ухоженное оборудование и
        экипировку. Если вы собираетесь покупать новые товары, обратите внимание
        на продажи оборудования в конце года.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Для плавания:</strong> <br />
        Ваш гоночный комплект (верхний и нижний или цельный) — это одежда,
        которую вы будете носить во время плавания, езды на велосипеде и бега.
        Он специально разработан для триатлона, потому что сделан из
        быстросохнущего материала и имеет встроенную замшу, которая сделает
        поездку на велосипеде более комфортной.
      </CustomParagraph>
      <Box>
        <CustomUl>
          <li>
            Выбирайте плотно прилегающую экипировку (при намокании она
            растягивается) с просторными задними карманами для хранения
            энергетических гелей и любых других необходимых для гонки предметов
            (например, спасательного ингалятора, если вы астматик).
          </li>
          <li>
            Остерегайтесь любых раздражающих швов, которые могут привести к
            болезненному натиранию во время гонки.
          </li>
          <li>
            Если вы планируете соревноваться где-нибудь, кроме тропиков,
            гидрокостюм для триатлона, разработанный для обеспечения мобильности
            и плавучести во время плавания, является фундаментальным.
          </li>
          <li>
            Пара очков, которые идеально прилегают к лицу (проверьте
            герметичность в магазине перед покупкой), — единственное, что нужно
            обязательно иметь для плавания.
          </li>
        </CustomUl>
      </Box>
      <CustomParagraph>
        В идеале совершите пробное плавание на демонстрационном мероприятии,
        чтобы по-настоящему почувствовать, как костюм сидит в воде — он
        гарантированно будет слишком тесным на суше.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Для велосипеда:</strong> <br />
        Велосипед будет являться самой крупной покупкой для триатлона.
        Сосредоточьтесь на комфорте и посадке, а не на аэродинамике, чтобы
        гарантировать вам удовольствие от поездки.
      </CustomParagraph>
      <Box>
        <CustomUl>
          <li>
            Никогда не садитесь на велосипед без шлема — защитного снаряжения
            для любой гонки.
          </li>
          <li>
            Солнцезащитные очки также необходимы для защиты глаз от летающих
            насекомых и дорожного пыли.
          </li>
          <li>
            Если в будущем вы захотите сделать небольшие обновления, то
            следующими шагами для вас станут новое велосипедное седло, которое
            может точно настроить уровень комфорта, а также велосипедная обувь,
            которая соединят вас с вашим велосипедом и даст вам преимущество
            движения педалей.
          </li>
        </CustomUl>
      </Box>
      <CustomParagraph>
        В рамках тренировки по триатлону для новичков обязательно овладейте
        базовыми навыками обслуживания велосипеда, например, заменой спущенной
        шины и незначительная регулировка переключателя. Многие велосипедные
        магазины или общественные образовательные программы предлагают занятия
        по обслуживанию велосипедов, которые помогут вам не оказаться в трудной
        ситуации во время тренировки или соревнований.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Для бега:</strong> <br />
        Беговые кроссовки — единственная дополнительная потребность в финальном
        этапе триатлона.
      </CustomParagraph>
      <Box>
        <CustomUl>
          <li>
            Обувь необходимо подбирать индивидуально под ваш стиль бега,
            физиологические параметры, а также под покрытие, на котором вы
            собираетесь бежать.
          </li>
          <li>
            Не стоит надевать абсолютно новые кроссовки на соревнования.
            Рекомендуется тестировать новую пару обуви на тренировках и беговых
            пробежках за определенное время до старта, чтобы предотвратить
            неудобство и натирание во время основных стартов.
          </li>
          <li>
            Простым и недорогим обновлением, которое избавит вашу экипировку от
            отверстий для английских булавок, — это гоночный пояс для хранения
            вашего номера.
          </li>
        </CustomUl>
      </Box>
      <CustomParagraph>
        Перед выездом на открытую дорогу установите велосипед на стационарный
        тренажер (велостанок). Это позволит вам научиться комфортно сидеть в
        аэродинамическом положении, вставать, а также вставлять и вытаскивать
        велотуфли из педалей.
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        ВАЖНО ЗНАТЬ
      </Heading>
      <Box
        style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
      ></Box>

      <CustomParagraph>
        <strong>Правила восстановления после тренировок:</strong> <br />
        Восстановление является таким же важным компонентом тренировок по
        триатлону, как плавание, езда на велосипеде и бег. Восстановление должно
        быть определенным катализатором, который поможет вам максимизировать
        отдачу от тяжелой работы, которую вы вкладываете в тренировки.
        Восстановление можно разделить на три основные области:
      </CustomParagraph>
      <Box>
        <CustomUl>
          <li>
            Тренировочный план, рассчитанный на частые тренировок с низкой
            интенсивностью, чтобы подготовить тело к ключевым нагрузкам и
            улучшат физическую форму.
          </li>
          <li>
            Выбор образа жизни спортсмена с упором на полноценный сон и
            качественное питание.
          </li>
          <li>Компрессия, массаж и растяжка.</li>
        </CustomUl>
      </Box>
      <CustomParagraph>
        Придерживаясь этих трех пунктов, вы обнаружите преобладающую прогрессию
        в тренировках, будете меньше накапливать усталость и значительно снизите
        риск травм.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Ментальная игра:</strong> <br />
        Психологический аспект спорта на выносливость играет не менее важную
        роль, чем подводящие тренировки.
      </CustomParagraph>
      <Box>
        <CustomUl>
          <li>
            С самого начала думайте о позитивном настрое, независимо от
            конечного результата.
          </li>
          <li>
            Вовлечение самых близких вам людей в ваш новый вид спорта и
            честность в отношении ожидаемых затрат времени во многом поможет
            избежать проблем со стрессом.
          </li>
        </CustomUl>
      </Box>
      <CustomParagraph>
        Соревнования, даже у профессиональных спортсменов редко проходят по
        плану на все 100 процентов, поэтому важно помнить, что каждая гонка и
        соревнование — это новая ступень вашего опыта в спорте, которая в
        дальнейшем поможет вам измениться в лучшую сторону.
      </CustomParagraph>
      <CustomParagraph>
        <strong>Питание:</strong> <br />
        Для спортсменов на выносливость питание одинаково важно как на
        дистанции, так и вне ее. Прочитайте источники или проконсультируйтесь со
        специалистом заранее по поводу вашего питания. Оно должно зависеть от
        вашего физиологического строения организма, интенсивности тренировок и
        выполнения конечных целей. Подберите себе правильное и подходящее для
        вас спортивное питание, разрешенные биологические добавки и витамины,
        чтобы способствовать повышению энергии вашего организма.
      </CustomParagraph>
      <Box>
        <CustomUl>
          <li>
            Добавление аминокислот с разветвленной цепью может помочь снизить
            умственную усталость и уменьшить повреждение мышц.
          </li>
          <li>
            Небольшое количество кофеина может улучшить работоспособность за
            счет увеличения концентрации внимания и уменьшения воспринимаемых
            усилий во время тренировки.
          </li>
          <li>
            Не рекомендуется перед соревнованиями и во время них
            экспериментировать с новым для вас спортивным питанием, так как оно
            может вызвать у вас аллергию или другие неприятные ощущения.
          </li>
          <li>
            Также не рекомендуется тренироваться и участвовать в соревнованиях
            потребляя только воду. Правильное питание и гидратация необходимы
            для поддержания качественных тренировок и обеспечения запаса
            энергии.
          </li>
        </CustomUl>
      </Box>
      <CustomParagraph>
        В течение 30 минут после завершения тренировки или соревнований вам
        необходимо сосредоточиться на восстановлении. Употребляйте пищу или
        восстанавливающие напитки с качественным источником белка, которые
        помогут предотвратить разрушение мышечной ткани и простимулируют
        наращивание мышечной ткани, а также углеводы для пополнения запасов
        гликогена.
      </CustomParagraph>
      <CustomParagraph>
        <strong>
          Эти советы важно, но мы рекомендуем провести индивидуальную
          консультацию со специалистом.
        </strong>
      </CustomParagraph>
      <CustomParagraph>
        В день соревнований у вас будет возможность использовать продукты
        питания, поставляемые с дистанции, или носить их с собой (в бутылках с
        водой или боксах на велосипеде, а также в поясе для воды, переносной
        бутылке или в карманах вашей экипировке на бегу).
      </CustomParagraph>

      <Heading level={"3"} margin={{ top: "18px", bottom: "18px" }}>
        ТРАНЗИТНЫЕ ЗОНЫ ДЛЯ НАЧИНАЮЩИХ
      </Heading>
      <Box
        style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
      ></Box>

      <CustomParagraph>
        Воспользуйтесь этими советами и обязательно используйте их в своих
        тренировках по триатлону для начинающих!
      </CustomParagraph>
      <Box>
        <ol>
          <li>
            Начните снимать гидрокостюм на выходе из плавания, натягивая его до
            пояса во время бега, затем снимите его полностью и оставьте в
            транзитной зоне перед велосипедным этапом (Т1). Снимите кепку и
            очки, но не роняйте их, так как вы рискуете получить штраф за отказ
            от снаряжения.
          </li>
          <li>
            Когда вы подойдете к своему велосипеду, наденьте шлем и очки, затем
            велосипедную обувь (в зависимости от вашего уровня комфорта с
            летающим креплением, ваши велосипедные туфли могут уже быть
            пристегнуты к вашим педалям). Не забудьте пояс с гоночным номером,
            если гонка требует, чтобы вы использовали его вместо велосипедного
            крепления (это правило можно найти в информационном пакете
            спортсмена каждый соревнований).
          </li>
          <li>
            Сядьте на свой велосипед на обозначенном месте или выполните посадку
            на велосипед в движении в обозначенной транзитной зоне.
          </li>
          <li>
            Войдя в транзитную зону Т2 перед беговым этапом, сойдите на
            обозначенной линии и продвигайтесь с велосипедом (катите его,
            находясь рядом с ним) к месту перехода (перед соревнованиями
            отрепетируйте свой маршрут в оба перехода и обратно). Оставьте свой
            велосипед и пристегните к нему шлем в обозначенном месте (ячейке для
            велосипеда с вашим номером). Поменяйте обувь на беговую, возьмите
            свой гоночный пояс и другие необходимые элементы для данного этапа;
            приступайте к беговому этапу.
          </li>
        </ol>
      </Box>
      <strong>Чек-лист для транзитных зон:</strong>
      <table>
        <CustomTbody>
          <tr>
            <th>№</th>
            <th>Этап</th>
            <th>Что необходимо?</th>
          </tr>
          <tr>
            <td>1</td>
            <td style={{ verticalAlign: "top" }}>Перед стартом / плавание</td>
            <td style={{ maxWidth: "1000px" }}>
              <ol>
                <li>Гоночный комплект (он же костюм для триатлона)</li>
                <li>Гидрокостюм</li>
                <li>
                  Шапочка для плавания (обычно предоставляется на соревнованиях)
                </li>
                <li>Очки для плавания</li>
                <li>
                  Чип хронометража (закрепите его на теле перед стартом так, как
                  обозначат организаторы соревнований)
                </li>
                <li>
                  Смазка для кожи (например, «Вазелин»; нанесите смазку на любую
                  часть тела, которое может быть раздражено швом или растиранием
                  кожи о кожу)
                </li>
                <li>Солнцезащитный крем</li>
                <li>Утепленная одежда</li>
                <li>Насос</li>
                <li>Предсоревновательное питание</li>
                <li>
                  Латексные или легкие хлопчатобумажные перчатки (предотвращают
                  образование микротрещин при натягивании гидрокостюма ногтями
                  или слишком сильном натяжении)
                </li>
                <li>
                  Полиэтиленовые пакеты или старые носки (наденьте на ноги,
                  чтобы натянуть гидрокостюм)
                </li>
                <li>Бальзам для губ</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td style={{ verticalAlign: "top" }}>
              Транзитная зона T1 / велосипед
            </td>
            <td>
              <ol>
                <li>Маленькое полотенце (помогает вытирать ноги)</li>
                <li>Велосипедная обувь</li>
                <li>Велосипедный шлем</li>
                <li>
                  Бутылка для воды со спортивным напитком или водой, а также
                  любое другое питание на велосипеде
                </li>
                <li>Солнцезащитные очки</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td style={{ verticalAlign: "top" }}>Транзитная зона Т2 / бег</td>
            <td>
              <ol>
                <li>
                  Номер участника (который крепится так, как обозначат
                  организаторы соревнований)
                </li>
                <li>Питание / вода</li>
                <li>Обувь для бега</li>
                <li>
                  Эластичные шнурки (чтобы сэкономить время, не завязывая обувь)
                </li>
                <li>Носки</li>
                <li>
                  Кепка или головной убор (чтобы избежать солнечного удара)
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td style={{ verticalAlign: "top" }}>После финиша</td>
            <td>
              <ol>
                <li>Белковые продукты</li>
                <li>Вода</li>
                <li>Сухая одежда и шлепанцы</li>
                <li>Комплект теплой одежды</li>
              </ol>
            </td>
          </tr>
        </CustomTbody>
      </table>
    </Article>
  );
};
