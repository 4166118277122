import { Box, Heading, ResponsiveContext, Image, Text } from "grommet";
import styled from "styled-components";
import { DefaultButton } from "../../DefaultButton";
import { additionalApi } from "../../../api";

const CustomHeading = styled(Heading)`
  text-transform: uppercase;
`;

const CustomBtn = styled(DefaultButton)`
  padding: 6px 24px;
  max-width: 96px;
`;

const CustomParagraph = styled(Text)`
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 14px;
`;

export const Book = ({
  title,
  price,
  description,
  picture,
  public_id,
  handlePayment,
}) => {
  // const [isOpenPopup, setOpenPopup] = useState(false);
  const sizes = ["small", "tablet"];

  return (
    <div>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            height="auto"
            pad="small"
            direction={!sizes.includes(size) ? "row" : "column"}
            gap="large"
          >
            <Box height="250px" margin="small">
              <Image src={`${additionalApi}/${picture}`} fit="contain" />
            </Box>
            <Box
              height="auto"
              margin="medium"
              width={{ max: !sizes.includes(size) ? "50%" : "100%" }}
            >
              <CustomHeading level={5} size="small">
                {title}
              </CustomHeading>
              <CustomParagraph>{description}</CustomParagraph>
              {/*  <CustomParagraph>
                Доставка книги (кроме доставок в пределах Москвы) осуществляется
                Почтой России.
              </CustomParagraph> */}
              <CustomParagraph>
                <b>Цена: {price}</b>
              </CustomParagraph>

              <CustomBtn
                type="submit"
                margin={{ top: "medium" }}
                onClick={() => handlePayment(public_id)}
              >
                Купить
              </CustomBtn>

              {/*  {isOpenPopup && <BookPopup statePopupHandler={setOpenPopup} />} */}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
};
