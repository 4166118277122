import { Box, Button, Layer } from "grommet";

export const VerifyPopup = ({ closePopupHandler }) => {
  return (
    <Layer
      onClickOutside={closePopupHandler}
      onEsc={closePopupHandler}
      animation="fadeIn"
      position="center"
    >
      <Box style={{ padding: "50px" }}>
        <h2>Email успешно подтвержден</h2>
        <Button
          style={{
            padding: "10px",
            maxWidth: "406px",
            marginTop: "20px",
            textAlign: "center",
            rel: "noopener noreferrer",
          }}
          primary
          onClick={closePopupHandler}
        >
          Закрыть
        </Button>
      </Box>
    </Layer>
  );
};
